import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import { colors, radius, COLOR_CONSTANTS, fontWeights, fontSizes, space } from 'theme'
import {
  FACEBOOK,
  IMAGE,
  VIDEO,
  FEATURE_POST_ENGAGEMENT,
  FEATURE_POST_TARGETING,
  PROFILE_TYPE_GROUP,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  PROFILE_TYPE_PROFILE,
} from 'consts'
import { formatToNumberWithCommas, linkTransformer } from 'helpers'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import DropDown from 'shared/DropDown'
import Input from 'components/atoms/Input'
import MediaCarousel from 'components/molecules/MediaCarousel'
import Counter from 'components/molecules/Counter'
import Select from 'components/atoms/Select'
import Switch from 'components/atoms/Switch'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import { getProfileFlavor } from 'routes/Settings/Profiles/helpers'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import ImagesGridVertical from './components/ImagesGridVertical'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import SocialProfileImage from '../SocialProfileImage'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'
import {
  NETWORK_LIMITS,
  POST_LINK,
  POST_VIDEO,
  POST_IMAGE,
  POST_MIXED_MEDIA,
  FACEBOOK_COLORS,
  PUBLISH_AS_IMAGE,
  PUBLISH_AS_VIDEO,
  PUBLISH_AS_REELS,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_SLIDESHOW,
  PUBLISH_AS_ARTICLE,
  PUBLISH_AS_STORY,
  PUBLISH_AS_USER_TYPE_PERSONAL,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  SAVED_SEARCH_TYPE_FACEBOOK_LOCATION,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import {
  getAccounts,
  getNetworkSavedSearches,
  getPublishAsDropDownValuesForFacebook,
  mentionsTransformer,
  setSavedSearches,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import { formatOptionLabel } from './InstagramPost'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
// eslint-disable-next-line import/no-cycle
import { generateFacebookMediaComponent } from './helper'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import BoostsDropdownComponent from './components/BoostsDropdownComponent'

const StyledFlex = styled(Flex)`
  border-radius: 0.5em;  
  font-size: ${({ isEditable }) => (isEditable ? '12px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')}; 
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); 
  width: fit-content;
  margin: 0 auto; 
  --font-family-apple: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
  --font-family-default: Helvetica, Arial, sans-serif;
  --font-family-segoe: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
}
`
const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const SourceWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${colors.primary};
  }
`

const StyledLinkText = styled(Text)`
  text-transform: uppercase;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledSelect = styled(Select)`
  font-size: ${fontSizes.xs};
  background-color: ${COLOR_CONSTANTS.WHITE};
  color: ${({ color }) => color || colors.primaryText};
  option {
    color: ${({ optionColor }) => optionColor || colors.primaryText};
  }
`

const StyledCallToActionButton = styled.a`
  font-weight: ${fontWeights.bold};
  height: 2.25em;
  padding: 0 1em;
  background-color: #e4e6eb;
  cursor: pointer;
  font-size: 0.875em;
  line-height: 2.25em;
  border-radius: 0.375em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const StyledCommentWrapper = styled(Flex)`
  padding: 0.5em;
  background-color: ${FACEBOOK_COLORS.LIGHT_GREY};
  border-radius: 1.125em;
  width: 100%;
  white-space: pre-wrap;
`

const QuestionMarkOutlined = styled(Icon.QuestionMarkOutlined)`
  fill: ${colors.secondaryText};
  cursor: pointer;
`

const StyledTextWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledLocationText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.primary};
  }
`

const StyledLocationIconWrapper = styled(Flex)`
  cursor: pointer;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const StyledStoryHeaderWrapper = styled(Flex)`
  align-items: stretch;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 52.5%, transparent);
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
  height: 12.5em;
`

const StyledStoryHeaderItem = styled(Flex)`
  align-items: stretch;
  background: rgba(255, 255, 255, ${({ isActive }) => (isActive ? 1 : 0.35)});
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: baseline;
  border-radius: 6.25em;
`

const StyledFeedStatisticsWrapper = styled(Flex)`
  ${({ hasBottomBorder }) => hasBottomBorder && `border-bottom: 1px solid #CED0D4;`}
  ${({ hasTopBorder }) => hasTopBorder && `border-top: 1px solid #CED0D4;`}
`

const StyledFeedFooterControlImage = styled.i`
  background-image: url('/assets/facebook_feed_icon_pack.png');
  width: 1.125em;
  height: 1.125em;
  background-size: 1.625em 39.25em;
  background-position: ${({ backgroundImagePosition }) => backgroundImagePosition};
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: -0.25em;
  filter: invert(39%) sepia(21%) saturate(200%) saturate(109.5%) hue-rotate(174deg) brightness(94%) contrast(86%);
`

const ReelsWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.BLACK};
  position: relative;
  box-sizing: border-box;
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
  border-radius: ${radius.xl};
  overflow: hidden;
  &:before {
    z-index: -1;
    content: '';
    width: 100%;
    height: 225px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const StyledReelsControlImage = styled.i`
  background-image: url('/assets/facebook_feed_icon_pack.png');
  width: 1.25em;
  height: 1.25em;
  background-size: 1.625em 39.25em;
  background-position: ${({ backgroundImagePosition }) => backgroundImagePosition};
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: -0.25em;
  filter: invert(100%);
`

const StyledReelGlobeImage = styled(Image)`
  filter: invert(100%);
`

const StyledReelFooterWrapper = styled(Flex)`
  align-items: stretch;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 12.5%, rgba(0, 0, 0, 0.4));
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
  height: 12.5em;
`

const StyledHtmlCollapseInnerWrapper = styled(Flex)`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: unset;
`

const StyledHtmlCollapseText = styled(Text)`
  font-size: 0.875em;
  white-space: pre-line;
  display: inline;
  span,
  a {
    color: ${COLOR_CONSTANTS.WHITE} !important;
  }
`

const StyledHtmlCollapseBodyWrapper = styled(Box)`
  width: 100%;
  text-overflow: ellipsis;
  line-clamp: 2;
  box-orient: vertical;
  max-height: 4.45em;
  overflow-y: unset;
  &:before {
    content: '';
    height: calc(100% - 1.125em);
    float: right;
    width: 0;
    margin-top: 0px;
  }
  ${({ $isOpen }) =>
    $isOpen &&
    `
    max-height: 20em;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 5.5%, rgba(0, 0, 0, 0.4));
    border-radius: ${radius.l};
    overflow-y: scroll;
    width: calc(100% + 17px);
    margin-right: -17px;
  `}
`

const CollapseWrapper = styled(Flex)`
  float: right;
  clear: both;
  cursor: pointer;
  align-items: center;
  line-height: 1.125em;
`

const CollapseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  color: ${({ textColor }) => textColor};
  font-size: 0.875em;
  line-height: 1.125em;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
`

const StyledReelAudioText = styled(Text)`
  height: 1.5em;
  overflow: hidden;
`

const HtmlCollapse = ({ html, textColor }) => {
  const maxLength = 80
  const [isOpen, setIsOpen] = useState(false)
  const messageEnd = html && html.length > maxLength ? html.substr(maxLength) : ''

  return (
    <StyledHtmlCollapseInnerWrapper>
      <StyledHtmlCollapseBodyWrapper
        $isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}
      >
        {messageEnd && (
          <CollapseWrapper>
            <CollapseButton
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
              textColor={textColor}
            >
              {isOpen ? '' : '...'}
            </CollapseButton>
          </CollapseWrapper>
        )}
        <StyledHtmlCollapseText
          color={textColor}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </StyledHtmlCollapseBodyWrapper>
    </StyledHtmlCollapseInnerWrapper>
  )
}

HtmlCollapse.propTypes = {
  html: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
}

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === FACEBOOK)

const { commentTextMaxLimit: MAX_COMMENT_LENGTH, postTextMaxLimit: MAX_TEXT_LENGTH } = networkLimits

const mapArrayToReactSelect = (arr) => arr.map(({ code, name }) => ({ value: code, label: name }))

const PROCESSED_VIDEO_ENDING_REEL = '_facebook_reel'

const FacebookPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices = [],
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  blockCommentsMediaAttachment,
  isSmallPreview,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [agencyMentions, setAgencyMentions] = useState([])
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_HEIGHT = smallPreview ? 333.33 : 680
  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    isFacebookGroupProfilePage = false,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const profileType = getProfileFlavor({
    profile: { code: network, profile_url: profileUrl, profile_id, type: profile_type },
  })

  useEffect(() => {
    setProfile({ ...props.profile })
    if (isEditable) {
      getNetworkSavedSearches({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_FACEBOOK_LOCATION,
      })
    }
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postDocuments = [],
    postMixedMedias = [],
    link,
    customizations = {},
    insights = {},
    result,
    zoneShort,
  } = post

  let updatedPostText = postText
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let comments = []
  let countries = []
  let age_min = ''
  let age_max = ''
  let gender = ''
  let relationship_status = ''
  let publish_as_image = false
  let call_to_action = ''
  let publish_as = null
  let slideshow_duration = 1
  let slideshow_transition = 1
  let location = null
  let publish_as_user = null
  let device_gid = null
  let publishing_option = null
  let boost_gid = null
  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText

    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      countries = [],
      age_min = '',
      age_max = '',
      gender,
      relationship_status,
      publish_as_image = false,
      comments = [],
      call_to_action = '',
      publish_as = null,
      slideshow_duration = 1,
      slideshow_transition = 1,
      location = null,
      publish_as_user = null,
      device_gid = null,
      publishing_option = null,
      boost_gid = null,
    } = profileCustomizations)
  }

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForFacebook({
    postComponent: customizedPostComponent,
    post,
  })

  const { url, title, picture_url, id: linkId } = link || {}

  const { published_link } = result || {}

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      commentTextMaxLimit,
      postImageAspectRatioLimit,
      postVideoDurationLimit,
      postVideoAspectRatioLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postImageSizeLimit,
      notificationDeviceLengthLimit,
      deviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const { countries: formDataCountries = [] } = dataForCustomizations

  const {
    gender: GENDERS = [],
    relationship_status: RELATIONSHIP_STATUSES = [],
    call_to_action: CALL_TO_ACTIONS = [],
  } = dataForCustomizations[network] || {}

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { groupsOptions = [], groupsOptionsError, typeData = {} } = networkDataForCustomizations || {}
  const { [SAVED_SEARCH_TYPE_FACEBOOK_LOCATION]: SAVED_LOCATION_OPTIONS = [] } = typeData || {}

  const handleUpdateCustomization = ({ type, value, remove_empty = false }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value

    if (remove_empty && !value) {
      delete post.customizations[network][profileId][type]
    }

    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { type: publishAsUserType } = publish_as_user || {}

  const [customizationsSchema, setCustomizationsSchema] = useState({ location })

  const { name, latitude, longitude } = location || {}

  let showLocation = false
  if (
    (customizedPostComponent === POST_IMAGE && customizedPostImages.length !== 0) ||
    (customizedPostComponent === POST_LINK && publish_as !== PUBLISH_AS_ARTICLE) ||
    (customizedPostComponent === POST_VIDEO && customizedPostVideos.length !== 0) ||
    (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length !== 0)
  ) {
    showLocation = true
  }

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_likes = 0
  let c_comments = 0
  let c_shares = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, comments: c_comments = 0, shares: c_shares = 0 } = profileInsights)
  }

  c_comments =
    c_comments ||
    comments.filter(({ message, postImages }) => message || (postImages && postImages.length !== 0)).length

  const {
    updatedPostTextWithMentions,
    mentionsFromPost,
    notFoundMentions,
    transformed_text_to_html_for_textarea,
  } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const {
      mentionsFromPost,
      updatedText,
      updatedTextTransformedMentions: updatedPostTextWithMentions = '',
      notFoundMentions,
    } = mentionsTransformer({
      body: variableTransformedText,
      color: FACEBOOK_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: isFacebookGroupProfilePage ? variableTransformedText : updatedText,
      color: FACEBOOK_COLORS.LAST_LIGHT_BLUE,
      fontWeight: fontWeights.semiLight,
      transformHashstag: true,
      hashtagColor: FACEBOOK_COLORS.LAST_LIGHT_BLUE,
      hashtagFontWeight: fontWeights.semiLight,
    })

    return {
      updatedPostTextWithMentions,
      mentionsFromPost,
      notFoundMentions,
      transformed_text_to_html_for_textarea,
    }
  }, [updatedPostText, savedVariables, agencyMentions])

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE
      ? isFacebookGroupProfilePage
        ? customizedPostImages.filter(({ tempId }) => !tempId).slice(0, 1)
        : customizedPostImages.filter(({ tempId }) => !tempId)
      : []

  const postVideosUpdated =
    customizedPostComponent === POST_VIDEO ? customizedPostVideos.filter(({ tempId }) => !tempId) : []

  let postMixedMediasUpdated = []

  if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_medias = customizedPostMixedMedias.filter(({ tempId }) => !tempId)

    if (filtered_medias.length !== 0) {
      if (filtered_medias[0].type === IMAGE || filtered_medias[0].type === IMAGE_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias.filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
        // if (isFacebookGroupProfilePage) {
        //   postMixedMediasUpdated = [filtered_medias[0]]
        // } else {
        //   postMixedMediasUpdated = filtered_medias.filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
        // }
      } else if (filtered_medias[0].type === VIDEO || filtered_medias[0].type === VIDEO_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias.filter(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
      }
    }
  }

  useEffect(() => {
    if (call_to_action && customizedPostComponent !== POST_LINK) {
      handleUpdateCustomization({
        type: 'call_to_action',
        value: '',
      })
    }
  }, [customizedPostComponent])

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 500),
    []
  )

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: FacebookPost.displayName,
        functionName: 'url',
        showAlert: false,
        sendError: false,
      })
      urlHost = url
    }
  }

  let slideToIndex

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const postImageError = postImageAspectRatioLimit || postImageSizeLimit

  if (postImageError || postVideoError) {
    if (postComponent === POST_MIXED_MEDIA) {
      slideToIndex = postMixedMediasUpdated.findIndex(({ id }) => id === (postImageError || postVideoError))
    } else if (postImageError) {
      slideToIndex = postImagesUpdated.findIndex(({ id }) => id === postImageError)
    } else if (postVideoError) {
      slideToIndex = postVideosUpdated.findIndex(({ id }) => id === postVideoError)
    }
  }

  if (slideToIndex === -1) {
    slideToIndex = 0
  }

  let storyHeaderWrapper = null

  if (publish_as === PUBLISH_AS_STORY) {
    let storyMedia = {}
    switch (customizedPostComponent) {
      case POST_IMAGE:
        if (postImagesUpdated.length !== 0) {
          storyMedia = {
            id: postImagesUpdated[0].id,
            url: postImagesUpdated[0].url,
            thumbnail_url: postImagesUpdated[0].thumbnail_url,
            media_type: postImagesUpdated[0].type,
            post,
            profiles: [profile],
          }
        }
        break
      case POST_VIDEO:
        if (postVideosUpdated.length !== 0) {
          storyMedia = {
            id: postVideosUpdated[0].id,
            url: postVideosUpdated[0].url,
            thumbnail_url: postVideosUpdated[0].thumbnail_url,
            media_type: VIDEO,
          }
        }
        break
      case POST_LINK:
        if (picture_url) {
          storyMedia = { id: link.id, url: link.picture_url, thumbnail_url: link.picture_url, media_type: IMAGE }
        }
        break
      case POST_MIXED_MEDIA:
        if (postMixedMediasUpdated.length !== 0) {
          if (postMixedMediasUpdated.type === IMAGE || postMixedMediasUpdated.type === IMAGE_DYNAMIC) {
            storyMedia = {
              id: postMixedMediasUpdated[0].id,
              url: postMixedMediasUpdated[0].url,
              thumbnail_url: postMixedMediasUpdated[0].thumbnail_url,
              media_type: postMixedMediasUpdated[0].type,
              post,
              profiles: [profile],
            }
          } else if (postMixedMediasUpdated.type === VIDEO || postMixedMediasUpdated.type === VIDEO_DYNAMIC) {
            storyMedia = {
              id: postMixedMediasUpdated[0].id,
              url: postMixedMediasUpdated[0].url,
              thumbnail_url: postMixedMediasUpdated[0].thumbnail_url,
              media_type: VIDEO,
            }
          }
        }
        break
      default:
        break
    }

    const stories = [storyMedia]

    storyHeaderWrapper = (
      <StyledStoryHeaderWrapper>
        <Flex justifyContent="space-between" p="0.75em">
          {stories.map(({ id }) => {
            return (
              <Flex key={id} flexGrow="1" height="4px" mr="0.125em" position="relative">
                <StyledStoryHeaderItem isActive />
              </Flex>
            )
          })}
        </Flex>
        <Flex flex="0 0 auto" justifyContent="space-between" alignItems="center" alignContent="stretch" px="0.75em">
          <Flex alignItems="center">
            {isEditable ? (
              <StyledImg
                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                fallbackSource={fallbackLogoImage}
                width="2.25em"
                height="2.25em"
              />
            ) : (
              <SocialProfileImage
                code={network}
                picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                width="2.25em"
                height="2.25em"
                imageBorderRadius={radius.pill}
              />
            )}
            <Flex
              ml="0.75em"
              flex="0 0 auto"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
            >
              <Text color="white" fontSize="0.9em">
                {profileUsername || profileName}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </StyledStoryHeaderWrapper>
    )
  }

  const feedHeaderWrapper =
    publish_as !== PUBLISH_AS_STORY ? (
      <Flex flexDirection="column">
        <Box px="0.5em" my="1em">
          <Flex>
            {isEditable ? (
              <StyledImg
                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                fallbackSource={fallbackLogoImage}
                width="2.5em"
                height="2.5em"
              />
            ) : (
              <Fragment>
                {!profilePictureUrl || isMultipleProfilesPreview ? (
                  <SocialProfileImage code={network} width="2.5em" height="2.5em" imageBorderRadius={radius.pill} />
                ) : (
                  <SocialProfileImage
                    code={network}
                    picture_url={profilePictureUrl}
                    width="2.5em"
                    height="2.5em"
                    imageBorderRadius={radius.pill}
                  />
                )}
              </Fragment>
            )}
            <Flex ml="0.5em" flexDirection="column" justifyContent="space-between">
              <Box
                onMouseEnter={(e) => {
                  if (!isMultipleProfilesPreview) {
                    tooltipRef.current.handleShowTooltip({
                      contentComp: (
                        <ProfileItemTooltip
                          profile={{
                            code: network,
                            picture_url: profilePictureUrl,
                            profile_url: profileUrl,
                            name: profileName,
                            username: profileUsername,
                            display,
                            profile_id,
                            type: profile_type,
                          }}
                          message=""
                        />
                      ),
                      wrapperComp: e.currentTarget,
                    })
                  }
                }}
                onMouseLeave={() => {
                  tooltipRef.current.handleHideTooltip()
                }}
              >
                <StyledLink
                  as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                  href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                  target="_blank"
                  color={FACEBOOK_COLORS.GLUON_GREY}
                  fontWeight="bold"
                  fontSize="0.9em"
                >
                  {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                </StyledLink>
                {location && showLocation && (
                  <Fragment>
                    <Text as="span" fontSize="0.9em" color={FACEBOOK_COLORS.TARNISHED_SILVER}>
                      &nbsp;is at&nbsp;
                    </Text>
                    <StyledLink
                      as={profileUrl ? 'a' : 'span'}
                      href={`http://www.google.com/maps/place/${location.latitude},${location.longitude}`}
                      target="_blank"
                      color={FACEBOOK_COLORS.GLUON_GREY}
                      fontWeight="bold"
                      fontSize="0.875em"
                    >
                      {location.name}
                    </StyledLink>
                  </Fragment>
                )}
              </Box>
              <Flex alignItems="center">
                <StyledLink
                  fontSize="0.9em"
                  color={FACEBOOK_COLORS.TARNISHED_SILVER}
                  as={published_link ? 'a' : 'p'}
                  href={published_link || ''}
                  target="_blank"
                >
                  {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''} · ` : 'Now · '}
                  <Text as="span" opacity="0.5" fontSize="0.9em">
                    <Image height="0.75em" src="/assets/facebook_globe.png" alt="Public" mt="-0.1875em" />
                  </Text>
                </StyledLink>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        {transformed_text_to_html_for_textarea && (
          <Box px="0.5em" mt="0.5em">
            <Text
              dangerouslySetInnerHTML={{
                __html: transformed_text_to_html_for_textarea,
              }}
              fontSize={
                postImagesUpdated.length !== 0 || postVideosUpdated.length !== 0 || picture_url ? '0.875em' : '1.375em'
              }
              fontWeight="semiLight"
              whiteSpace="pre-line"
              color={FACEBOOK_COLORS.GLUON_GREY}
            />
          </Box>
        )}
      </Flex>
    ) : null

  const feedFooterWrapper =
    publish_as !== PUBLISH_AS_STORY && publish_as !== PUBLISH_AS_REELS ? (
      <Flex flexDirection="column" px="0.5em">
        {(c_likes > 0 || c_comments > 0) && (
          <StyledFeedStatisticsWrapper
            hasBottomBorder
            color={FACEBOOK_COLORS.TARNISHED_SILVER}
            justifyContent="space-between"
            py="0.625em"
          >
            {c_likes > 0 ? (
              <Flex alignItems="center">
                <Image height="1.125em" width="1.125em" src="/assets/facebook_like.svg" alt="Likes" />
                <Text ml="0.25em" fontWeight="semiLight" fontSize="0.875em">
                  {formatToNumberWithCommas(c_likes)}
                </Text>
              </Flex>
            ) : (
              <Flex />
            )}
            <Flex alignItems="center">
              {c_comments > 0 && (
                <Flex alignItems="center">
                  <Text fontWeight="semiLight" fontSize="0.875em">
                    {formatToNumberWithCommas(c_comments)} {`${c_comments === 1 ? 'Comment' : 'Comments'}`}
                  </Text>
                </Flex>
              )}
              {c_shares > 0 && (
                <Flex alignItems="center" ml="0.375em">
                  <Text fontWeight="semiLight" fontSize="0.875em">
                    {formatToNumberWithCommas(c_shares)} {`${c_shares === 1 ? 'Share' : 'Shares'}`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </StyledFeedStatisticsWrapper>
        )}

        <Grid gridTemplateColumns="repeat(3, 1fr)" width="100%">
          <Flex alignItems="center" justifyContent="center" height="2.75em">
            <StyledFeedFooterControlImage backgroundImagePosition="0px -12.625em" />
            <Text px="0.25em" pt="0.25em" fontSize="0.875em" fontWeight="bold" color={FACEBOOK_COLORS.TARNISHED_SILVER}>
              Link
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center" height="2.75em">
            <StyledFeedFooterControlImage backgroundImagePosition="0px -10.125em" />
            <Text px="0.25em" pt="0.25em" fontSize="0.875em" fontWeight="bold" color={FACEBOOK_COLORS.TARNISHED_SILVER}>
              Comment
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="center" height="2.75em">
            <StyledFeedFooterControlImage backgroundImagePosition="0px -13.875em" />
            <Text px="0.25em" pt="0.25em" fontSize="0.875em" fontWeight="bold" color={FACEBOOK_COLORS.TARNISHED_SILVER}>
              Share
            </Text>
          </Flex>
        </Grid>

        {publishAsUserType !== PUBLISH_AS_USER_TYPE_PERSONAL && (
          <StyledFeedStatisticsWrapper flexDirection="column">
            {comments.map((comment) => {
              const { id, message, postComponent, postImages } = comment

              if (!message && (!postImages || postImages.length === 0)) {
                return null
              }

              const { variableTransformedText } = variableTransformer({
                str: message,
                savedVariables,
                post,
                profileId,
              })

              let sourceTypeTemplate = ''

              switch (postComponent) {
                case POST_IMAGE:
                  if (postImages && postImages.length !== 0) {
                    sourceTypeTemplate = (
                      <Flex
                        flexDirection="column"
                        position="relative"
                        borderRadius="1em"
                        maxWidth={smallPreview ? '128px' : '260px'}
                      >
                        <ImagesGridVertical
                          images={[postImages[0]]}
                          gridHeight="12.5em"
                          network={network}
                          socialPreviewProfiles={[profile]}
                          post={{ id, postText: message }}
                          dynamicMediaIsSmall={smallPreview}
                          firstImageBorderRadius="1.125em"
                        />
                      </Flex>
                    )
                  }
                  break
                default:
                  break
              }

              return (
                <Flex pb="0.5em" key={id}>
                  {isEditable ? (
                    <StyledImg
                      source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                      fallbackSource={fallbackLogoImage}
                      width="2em"
                      height="2em"
                    />
                  ) : (
                    <Fragment>
                      {!profilePictureUrl || isMultipleProfilesPreview ? (
                        <SocialProfileImage
                          code={network}
                          picture_url={fallbackLogoImage}
                          width="2em"
                          height="2em"
                          imageBorderRadius={radius.pill}
                        />
                      ) : (
                        <SocialProfileImage
                          code={network}
                          picture_url={profilePictureUrl}
                          width="2em"
                          height="2em"
                          imageBorderRadius={radius.pill}
                        />
                      )}
                    </Fragment>
                  )}
                  <Flex flexDirection="column" ml="0.25em">
                    <StyledCommentWrapper flexDirection="column">
                      <StyledLink
                        as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                        href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                        target="_blank"
                        color={FACEBOOK_COLORS.GLUON_GREY}
                        fontWeight="bold"
                        fontSize="0.9em"
                      >
                        {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                      </StyledLink>
                      <Text
                        fontSize="0.875em"
                        color={FACEBOOK_COLORS.GLUON_GREY}
                        dangerouslySetInnerHTML={{
                          __html: variableTransformedText,
                        }}
                      />
                    </StyledCommentWrapper>

                    {sourceTypeTemplate}
                  </Flex>
                </Flex>
              )
            })}
          </StyledFeedStatisticsWrapper>
        )}
      </Flex>
    ) : null

  const articleWrapper =
    publish_as === PUBLISH_AS_ARTICLE ? (
      <Flex px="0.5em" justifyContent="space-between" alignItems="center" bg={FACEBOOK_COLORS.LIGHT_GREY}>
        <Flex flexDirection="column" maxWidth={call_to_action ? '21.875em' : '100%'} width="100%">
          {urlHost && (
            <StyledLinkText fontSize="0.75em" mt="0.5em" color={FACEBOOK_COLORS.TARNISHED_SILVER}>
              {urlHost.toUpperCase()}
            </StyledLinkText>
          )}

          <Text my="0.5em" color={FACEBOOK_COLORS.GLUON_GREY} fontSize="1em" fontWeight="medium">
            {title}
          </Text>
        </Flex>
        {call_to_action && (
          <StyledCallToActionButton>
            {call_to_action
              .replace(/_/g, ' ')
              .split(' ')
              .map((word) => `${word.charAt(0)}${word.slice(1).toLowerCase()}`)
              .join(' ')}
          </StyledCallToActionButton>
        )}
      </Flex>
    ) : null

  let previewComponent = null

  switch (publish_as) {
    case PUBLISH_AS_CAROUSEL:
      // eslint-disable-next-line no-case-declarations
      let carouselMedias = []
      if (customizedPostComponent === POST_IMAGE) {
        carouselMedias = postImagesUpdated
      } else if (customizedPostComponent === POST_MIXED_MEDIA) {
        carouselMedias = postMixedMediasUpdated
      }

      previewComponent = (
        <Flex flexDirection="column">
          {feedHeaderWrapper}
          <MediaCarousel
            post={post}
            width={MAX_PREVIEW_WIDTH}
            height={MAX_PREVIEW_HEIGHT}
            medias={carouselMedias}
            slideToIndex={slideToIndex}
            sliderElements={carouselMedias.map((media, index) => {
              const { mediaComponent } = generateFacebookMediaComponent({
                media,
                network: FACEBOOK,
                post,
                profile,
                countMediaProportions: false,
                maxPreviewWidth: MAX_PREVIEW_WIDTH,
                maxPreviewHeight: MAX_PREVIEW_HEIGHT,
                dynamicMediaIsSmall: smallPreview,
                processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
              })
              return (
                <SourceWrapper
                  width={`${MAX_PREVIEW_WIDTH}px`}
                  height={`${MAX_PREVIEW_HEIGHT}px`}
                  key={media.id || index}
                >
                  {mediaComponent}
                </SourceWrapper>
              )
            })}
            activeDotsColor={FACEBOOK_COLORS.LAST_LIGHT_BLUE}
          />
          {feedFooterWrapper}
        </Flex>
      )
      break
    case PUBLISH_AS_SLIDESHOW:
      // eslint-disable-next-line no-case-declarations
      const media_image = postImagesUpdated[0]

      if (media_image) {
        const { mediaComponent } = generateFacebookMediaComponent({
          media: media_image,
          network: FACEBOOK,
          post,
          profile,
          countMediaProportions: false,
          maxPreviewWidth: MAX_PREVIEW_WIDTH,
          maxPreviewHeight: MAX_PREVIEW_HEIGHT,
          showImageAsVideoPreview: true,
          dynamicMediaIsSmall: smallPreview,
          processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
        })

        previewComponent = (
          <Flex flexDirection="column">
            {feedHeaderWrapper}
            <SourceWrapper
              justifyContent="center"
              alignItems="center"
              maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
              position="relative"
            >
              {mediaComponent}
            </SourceWrapper>
            {feedFooterWrapper}
          </Flex>
        )
      }

      break
    case PUBLISH_AS_IMAGE:
    case PUBLISH_AS_ARTICLE:
      // eslint-disable-next-line no-case-declarations
      let gridImages = []

      if (customizedPostComponent === POST_IMAGE) {
        gridImages = postImagesUpdated
      } else if (customizedPostComponent === POST_LINK && picture_url) {
        gridImages = [
          { id: linkId, url: picture_url || '/assets/landscape.svg', type: IMAGE, description: title || '' },
        ]
      } else if (
        customizedPostComponent === POST_MIXED_MEDIA &&
        postMixedMediasUpdated.length !== 0 &&
        (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC)
      ) {
        gridImages = postMixedMediasUpdated
      }

      previewComponent = (
        <Flex flexDirection="column" position="relative" borderRadius="1em">
          {storyHeaderWrapper}
          {feedHeaderWrapper}
          {gridImages.length > 0 && (
            <ImagesGridVertical
              images={gridImages}
              gridHeight="22.5em"
              network={network}
              socialPreviewProfiles={[profile]}
              post={post}
              dynamicMediaIsSmall={smallPreview}
            />
          )}
          {articleWrapper}
          {feedFooterWrapper}
        </Flex>
      )
      break
    case PUBLISH_AS_VIDEO:
      // eslint-disable-next-line no-case-declarations
      let media_video = null
      if (customizedPostComponent === POST_VIDEO) {
        // eslint-disable-next-line prefer-destructuring
        media_video = postVideosUpdated[0]
      } else if (
        customizedPostComponent === POST_MIXED_MEDIA &&
        postMixedMediasUpdated.length !== 0 &&
        (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC)
      ) {
        // eslint-disable-next-line prefer-destructuring
        media_video = postMixedMediasUpdated[0]
      }

      if (media_video) {
        const { mediaComponent } = generateFacebookMediaComponent({
          media: media_video,
          network: FACEBOOK,
          post,
          profile,
          countMediaProportions: false,
          maxPreviewWidth: MAX_PREVIEW_WIDTH,
          maxPreviewHeight: MAX_PREVIEW_HEIGHT,
          noVideoAttachment,
          dynamicMediaIsSmall: smallPreview,
          processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
        })

        previewComponent = (
          <Flex flexDirection="column" position="relative" borderRadius="1em">
            {storyHeaderWrapper}
            {feedHeaderWrapper}

            <SourceWrapper justifyContent="center" alignItems="center" maxHeight={`${MAX_PREVIEW_HEIGHT}px`}>
              {mediaComponent}
            </SourceWrapper>

            {feedFooterWrapper}
          </Flex>
        )
      }
      break
    case PUBLISH_AS_REELS:
      // eslint-disable-next-line no-case-declarations
      let media_reel = null
      if (customizedPostComponent === POST_VIDEO) {
        // eslint-disable-next-line prefer-destructuring
        media_reel = postVideosUpdated[0]
      } else if (
        customizedPostComponent === POST_MIXED_MEDIA &&
        postMixedMediasUpdated.length !== 0 &&
        (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC)
      ) {
        // eslint-disable-next-line prefer-destructuring
        media_reel = postMixedMediasUpdated[0]
      }

      if (media_reel) {
        const maxPreviewHeight = smallPreview ? 450 : 721
        const maxPreviewWidth = smallPreview ? 250 : 400

        const { mediaComponent, box_width, box_height } = generateFacebookMediaComponent({
          media: media_reel,
          network: FACEBOOK,
          post,
          profile,
          countMediaProportions: false,
          maxPreviewWidth,
          maxPreviewHeight,
          noVideoAttachment,
          dynamicMediaIsSmall: smallPreview,
          processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
        })

        previewComponent = (
          <ReelsWrapper flexDirection="column" width={`${box_width}px`} height={`${box_height}px`}>
            <Flex width="100%" alignItems="center">
              <SourceWrapper bg={COLOR_CONSTANTS.BLACK}>{mediaComponent}</SourceWrapper>
            </Flex>
            <StyledStoryHeaderWrapper>
              <Flex flexDirection="column" pl="1em" pt="1.25em">
                <Box>
                  <Flex alignItems="center">
                    {isEditable ? (
                      <StyledImg
                        source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                        fallbackSource={fallbackLogoImage}
                        width="2.5em"
                        height="2.5em"
                      />
                    ) : (
                      <Fragment>
                        {!profilePictureUrl || isMultipleProfilesPreview ? (
                          <SocialProfileImage
                            code={network}
                            width="2.5em"
                            height="2.5em"
                            imageBorderRadius={radius.pill}
                          />
                        ) : (
                          <SocialProfileImage
                            code={network}
                            picture_url={profilePictureUrl}
                            width="2.5em"
                            height="2.5em"
                            imageBorderRadius={radius.pill}
                          />
                        )}
                      </Fragment>
                    )}
                    <Flex ml="0.5em" flexDirection="column" justifyContent="space-between">
                      <StyledLink
                        as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                        href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                        target="_blank"
                        color={COLOR_CONSTANTS.WHITE}
                        fontWeight="bold"
                        fontSize="0.9em"
                      >
                        {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                      </StyledLink>

                      <Flex alignItems="center" height="1em">
                        <StyledLink
                          fontSize="0.9em"
                          color={COLOR_CONSTANTS.WHITE}
                          as={published_link ? 'a' : 'p'}
                          href={published_link || ''}
                          target="_blank"
                        >
                          <Text as="span">
                            <StyledReelGlobeImage
                              height="0.75em"
                              width="0.75em"
                              src="/assets/facebook_globe.png"
                              alt="Public"
                              mr="0.1875em"
                            />
                          </Text>
                          Public
                        </StyledLink>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </StyledStoryHeaderWrapper>
            <Flex
              position="absolute"
              bottom="1.25em"
              right="0.75em"
              zIndex="6"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Flex flexDirection="column" alignItems="center">
                <Flex mb="0.5em" flexDirection="column" alignItems="center">
                  <StyledReelsControlImage backgroundImagePosition="0.125em -32em" />
                  <Text mt="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                    Give
                  </Text>
                </Flex>
                <Flex my="0.5em" flexDirection="column" alignItems="center">
                  <StyledReelsControlImage backgroundImagePosition="0.125em -12.625em" />
                  {c_likes > 0 && (
                    <Text mt="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      {c_likes}
                    </Text>
                  )}
                </Flex>
                <Flex my="0.5em" flexDirection="column" alignItems="center">
                  <StyledReelsControlImage backgroundImagePosition="0.125em -10.15em" />
                  {c_comments > 0 && (
                    <Text mt="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      {c_comments}
                    </Text>
                  )}
                </Flex>
                <Flex mt="0.5em" flexDirection="column" alignItems="center">
                  <StyledReelsControlImage backgroundImagePosition="0.125em -29.75em" />
                  {c_shares > 0 && (
                    <Text mt="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      {c_shares}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <StyledReelFooterWrapper justifyContent="flex-end">
              <Flex flexDirection="column" width="calc(100% - 3.5em)" mr="0.5em" pb="1.25em" pl="1em">
                {transformed_text_to_html_for_textarea && (
                  <Flex mt="1em">
                    <HtmlCollapse html={transformed_text_to_html_for_textarea} textColor={COLOR_CONSTANTS.WHITE} />
                  </Flex>
                )}
                <Flex pt="0.625em" alignItems="center">
                  <Flex alignItems="center">
                    <Flex bg={COLOR_CONSTANTS.WHITE} height="0.25em" width="0.125em" borderRadius="6.25em" />
                    <Flex
                      bg={COLOR_CONSTANTS.WHITE}
                      height="0.625em"
                      width="0.125em"
                      borderRadius="6.25em"
                      mx="0.125em"
                    />
                    <Flex bg={COLOR_CONSTANTS.WHITE} height="0.25em" width="0.125em" borderRadius="6.25em" />
                  </Flex>
                  <StyledReelAudioText ml="0.75em" fontSize="0.9em" color={COLOR_CONSTANTS.WHITE}>
                    {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName} ·{' '}
                    {media_reel.audio_name || 'Original audio'}
                  </StyledReelAudioText>
                </Flex>
              </Flex>
            </StyledReelFooterWrapper>
          </ReelsWrapper>
        )
      }
      break
    case PUBLISH_AS_STORY:
      // eslint-disable-next-line no-case-declarations
      let story_media = null

      if (customizedPostComponent === POST_IMAGE) {
        // eslint-disable-next-line prefer-destructuring
        story_media = postImagesUpdated[0]
      } else if (customizedPostComponent === POST_VIDEO) {
        // eslint-disable-next-line prefer-destructuring
        story_media = postVideosUpdated[0]
      } else if (customizedPostComponent === POST_LINK) {
        if (link && link.picture_url) {
          story_media = { url: link.picture_url, id: link.id, width: link.width, height: link.height, type: IMAGE }
        }
      } else if (customizedPostComponent === POST_MIXED_MEDIA && postMixedMediasUpdated.length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        story_media = postMixedMediasUpdated[0]
      }

      // eslint-disable-next-line no-case-declarations
      const maxPreviewHeightStory = smallPreview ? 444.44 : 666
      // eslint-disable-next-line no-case-declarations
      let mediaComponentStory

      if (story_media) {
        ;({ mediaComponent: mediaComponentStory } = generateFacebookMediaComponent({
          media: story_media,
          network: FACEBOOK,
          post,
          profile,
          countMediaProportions: false,
          maxPreviewWidth: smallPreview ? 250 : 375,
          maxPreviewHeight: maxPreviewHeightStory,
          dynamicMediaIsSmall: smallPreview,
          imageStoryObjectFit:
            customizedPostComponent === POST_IMAGE ||
            customizedPostComponent === POST_LINK ||
            customizedPostComponent === POST_MIXED_MEDIA
              ? 'contain'
              : null,
          processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
        }))
      }

      previewComponent = (
        <Flex flexDirection="column" bg={COLOR_CONSTANTS.BLACK} position="relative">
          {storyHeaderWrapper}

          <Flex
            justifyContent="center"
            alignItems="center"
            maxHeight={`${maxPreviewHeightStory}px`}
            position="relative"
          >
            <SourceWrapper>{mediaComponentStory}</SourceWrapper>
          </Flex>
        </Flex>
      )

      break
    default:
      // eslint-disable-next-line no-case-declarations
      let sourceWrapperComponent = null

      if (
        customizedPostComponent === POST_IMAGE ||
        customizedPostComponent === POST_LINK ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          postMixedMediasUpdated.length !== 0 &&
          (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC))
      ) {
        let gridImages = []

        if (customizedPostComponent === POST_IMAGE) {
          gridImages = postImagesUpdated
        } else if (customizedPostComponent === POST_LINK) {
          gridImages = [
            { id: linkId, url: picture_url || '/assets/landscape.svg', type: IMAGE, description: title || '' },
          ]
        } else if (customizedPostComponent === POST_MIXED_MEDIA) {
          gridImages = postMixedMediasUpdated
        }

        sourceWrapperComponent = (
          <Fragment>
            <ImagesGridVertical
              images={gridImages}
              gridHeight="22.5em"
              network={network}
              socialPreviewProfiles={[profile]}
              post={post}
              dynamicMediaIsSmall={smallPreview}
            />
            {articleWrapper}
          </Fragment>
        )
      } else if (
        customizedPostComponent === POST_VIDEO ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          postMixedMediasUpdated.length !== 0 &&
          (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC))
      ) {
        let media_video = null

        if (customizedPostComponent === POST_VIDEO) {
          // eslint-disable-next-line prefer-destructuring
          media_video = postVideosUpdated[0]
        } else if (customizedPostComponent === POST_MIXED_MEDIA) {
          // eslint-disable-next-line prefer-destructuring
          media_video = postMixedMediasUpdated[0]
        }

        if (media_video) {
          const { mediaComponent } = generateFacebookMediaComponent({
            media: media_video,
            network: FACEBOOK,
            post,
            profile,
            countMediaProportions: false,
            maxPreviewWidth: MAX_PREVIEW_WIDTH,
            maxPreviewHeight: MAX_PREVIEW_HEIGHT,
            noVideoAttachment,
            dynamicMediaIsSmall: smallPreview,
            processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
          })
          sourceWrapperComponent = (
            <SourceWrapper
              justifyContent="center"
              alignItems="center"
              maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
              position="relative"
            >
              {mediaComponent}
            </SourceWrapper>
          )
        }
      }

      previewComponent = (
        <Flex
          flexDirection="column"
          bg={publish_as === PUBLISH_AS_STORY ? COLOR_CONSTANTS.BLACK : COLOR_CONSTANTS.WHITE}
          position="relative"
          borderRadius={radius.xxl}
        >
          {storyHeaderWrapper}
          {feedHeaderWrapper}
          {sourceWrapperComponent}
          {feedFooterWrapper}
        </Flex>
      )
      break
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px auto`
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
          isFacebookGroupProfilePage={isFacebookGroupProfilePage}
          mentionsFromPost={mentionsFromPost}
          notFoundMentions={notFoundMentions}
        />
      )}

      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL : COLOR_CONSTANTS.WHITE}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
              >
                {isPostFormDirty ? (
                  <Flex
                    flexDirection="column"
                    width="100%"
                    height={isPostFormDirty ? 'auto' : '100%'}
                    onClick={() => {
                      if (published_link) {
                        window.open(published_link)
                      }
                    }}
                  >
                    {previewComponent}
                  </Flex>
                ) : (
                  <Box mt="m" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={
                                  transformed_text_to_html_for_textarea
                                    ? COLOR_CONSTANTS.DENIM
                                    : COLOR_CONSTANTS.COSMIC_ENERGY
                                }
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter
                                counter={updatedPostTextWithMentions.length}
                                max={MAX_TEXT_LENGTH}
                                fontSize="xxs"
                              />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={FACEBOOK} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <Flex flexDirection="column">
                    {isFacebookGroupProfilePage ? (
                      <Fragment>
                        {/* {(groupsOptions.length !== 0 || groupsOptionsError) && ( */}
                        {/*  <Flex flexDirection="column"> */}
                        {/*    <Flex mt="m" alignItems="center" width="100%"> */}
                        {/*      <Flex flexDirection="column" width="100%"> */}
                        {/*        <DropDown */}
                        {/*          placeholder="Select publishing option" */}
                        {/*          value={ */}
                        {/*            publish_as_user */}
                        {/*              ? groupsOptions.find(({ value }) => publish_as_user.type === value) */}
                        {/*              : null */}
                        {/*          } */}
                        {/*          onChange={(option) => { */}
                        {/*            if (option) { */}
                        {/*              handleUpdateCustomization({ */}
                        {/*                type: 'publish_as_user', */}
                        {/*                value: { description: option.label, type: option.value }, */}
                        {/*              }) */}
                        {/*            } */}
                        {/*          }} */}
                        {/*          options={groupsOptions} */}
                        {/*          openMenuOnFocus */}
                        {/*          isSearchable={false} */}
                        {/*        /> */}
                        {/*      </Flex> */}
                        {/*      <TooltipPromptComponent prompt_key="publishing_page_options" network={FACEBOOK} /> */}
                        {/*    </Flex> */}
                        {/*    {groupsOptionsError && ( */}
                        {/*      <Flex flexDirection="column" width="100%" mt="m"> */}
                        {/*        <PromptComponent text={groupsOptionsError} isShown={isEditable} type="error" /> */}
                        {/*      </Flex> */}
                        {/*    )} */}
                        {/*  </Flex> */}
                        {/* )} */}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {((customizedPostComponent === POST_IMAGE && postImagesUpdated.length > 0) ||
                          (customizedPostComponent === POST_VIDEO && postVideosUpdated.length === 1) ||
                          (customizedPostComponent === POST_MIXED_MEDIA && postMixedMediasUpdated.length > 0) ||
                          (customizedPostComponent === POST_LINK && picture_url)) && (
                          <Flex flexDirection="column">
                            <Flex mt="m" alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <DropDown
                                  placeholder="Select publishing option"
                                  value={
                                    publish_as
                                      ? PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
                                      : PUBLISH_AS_DROPDOWN_VALUES[0]
                                  }
                                  onChange={(option) => {
                                    handleUpdateCustomization({
                                      type: 'publish_as',
                                      value: option.value,
                                    })
                                  }}
                                  options={PUBLISH_AS_DROPDOWN_VALUES}
                                  openMenuOnFocus
                                  isSearchable={false}
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="publishing_post_options" network={FACEBOOK} />
                            </Flex>
                            {publish_as === PUBLISH_AS_SLIDESHOW && (
                              <Flex mt="m" width="100%" justifyContent="space-between">
                                <Flex flexDirection="column" width="50%" pr="s">
                                  <Flex mb="xs" alignItems="center">
                                    <Text fontSize="xs" fontWeight="light">
                                      Duration
                                    </Text>
                                    <Box ml="s">
                                      <Tooltip
                                        width="230px"
                                        iconComp={QuestionMarkOutlined}
                                        message="The duration in seconds of each image. Maximum 10 seconds."
                                      />
                                    </Box>
                                  </Flex>
                                  <Input
                                    placeholder=""
                                    label=""
                                    value={slideshow_duration}
                                    name="slideshow_duration"
                                    id="slideshow_duration"
                                    onChange={(e) => {
                                      let { value } = e.target
                                      if (value) {
                                        value = Number(value)
                                      }
                                      if (value > 0 && value <= 10) {
                                        handleUpdateCustomization({
                                          type: 'slideshow_duration',
                                          value,
                                        })
                                      }
                                    }}
                                    type="number"
                                    width="100%"
                                    min={0}
                                    max={10}
                                    color={COLOR_CONSTANTS.DENIM}
                                  />
                                </Flex>
                                <Flex flexDirection="column" width="50%" pl="s">
                                  <Flex mb="xs" alignItems="center">
                                    <Text fontSize="xs" fontWeight="light">
                                      Transition
                                    </Text>
                                    <Box ml="s">
                                      <Tooltip
                                        width="230px"
                                        iconComp={QuestionMarkOutlined}
                                        message="The duration in seconds of the crossfade transition between images. Maximum 5 seconds."
                                      />
                                    </Box>
                                  </Flex>
                                  <Input
                                    placeholder=""
                                    label=""
                                    value={slideshow_transition}
                                    name="slideshow_transition"
                                    id="slideshow_transition"
                                    onChange={(e) => {
                                      let { value } = e.target
                                      if (value) {
                                        value = Number(value)
                                      }
                                      if (value > 0 && value <= 5) {
                                        handleUpdateCustomization({
                                          type: 'slideshow_transition',
                                          value,
                                        })
                                      }
                                    }}
                                    type="number"
                                    width="100%"
                                    min={0}
                                    max={5}
                                    color={COLOR_CONSTANTS.DENIM}
                                  />
                                </Flex>
                              </Flex>
                            )}
                          </Flex>
                        )}
                      </Fragment>
                    )}
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      checkChangeOptionFunction={() => {
                        if (profileType === PROFILE_TYPE_GROUP) {
                          Alert.error(
                            `Direct publishing is not available for Facebook groups. <a href="https://support.vistasocial.com/hc/en-us/articles/14736118034715-Auto-Publishing-vs-Notification-Reminders" target='_blank'>Learn more</a>`,
                            {
                              timeout: 5000,
                            }
                          )
                          return false
                        } else if (profileType === PROFILE_TYPE_PROFILE) {
                          Alert.error(
                            `Direct publishing is not available for personal and professional Facebook profiles.`,
                            {
                              timeout: 5000,
                            }
                          )
                          return false
                        }

                        return true
                      }}
                      notificationDeviceLengthLimit={deviceLengthLimit || notificationDeviceLengthLimit}
                    />
                  </Flex>

                  <Flex mt="m" minHeight="max-content" flexDirection="column">
                    {/* {customizedPostComponent === POST_LINK && picture_url && ( */}
                    {/*  <Flex mt="m" alignItems="center"> */}
                    {/*    <Switch */}
                    {/*      isOn={!!publish_as_image} */}
                    {/*      onClick={() => { */}
                    {/*        handleUpdateCustomization({ */}
                    {/*          type: 'publish_as_image', */}
                    {/*          value: !publish_as_image, */}
                    {/*        }) */}
                    {/*      }} */}
                    {/*      text="Publish as image" */}
                    {/*    /> */}
                    {/*  </Flex> */}
                    {/* )} */}

                    {publish_as !== PUBLISH_AS_STORY &&
                      (publishAsUserType !== PUBLISH_AS_USER_TYPE_PERSONAL ||
                        (!isFacebookGroupProfilePage &&
                          customizedPostComponent === POST_LINK &&
                          (picture_url || title || urlHost))) && (
                        <Flex flexDirection="column" position="relative">
                          {featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                            !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                              <CustomizationsBlockComponent
                                showUpgradeMessage={false}
                                showUpgradePlan={false}
                                message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                              />
                            )}

                          <Flex
                            flexDirection="column"
                            p={
                              featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                              !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                                ? 's'
                                : '0'
                            }
                          >
                            <Flex alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <CommentsComponent
                                  user={user}
                                  network={FACEBOOK}
                                  profileId={profileId}
                                  handleUpdateCustomization={handleUpdateCustomization}
                                  handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                                  commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                                  comments={comments}
                                  commentTextMaxLimit={commentTextMaxLimit}
                                  selectedEntities={selectedEntities}
                                  selectedProfiles={selectedProfiles}
                                  textVariantOffsets={textVariantOffsets}
                                  showMediaPicker={!blockCommentsMediaAttachment}
                                  showImageComponent
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="comments" />
                            </Flex>

                            {!isFacebookGroupProfilePage &&
                              customizedPostComponent === POST_LINK &&
                              (picture_url || title || urlHost) && (
                                <Flex mt="m" alignItems="center" width="100%">
                                  <Flex flexDirection="column" width="100%">
                                    <StyledSelect
                                      id="call_to_action"
                                      name="call_to_action"
                                      label="Call to action"
                                      placeholder="Call to action"
                                      onChange={({ target: { value } }) => {
                                        handleUpdateCustomization({
                                          type: 'call_to_action',
                                          value,
                                          remove_empty: true,
                                        })
                                      }}
                                      value={call_to_action}
                                      color={call_to_action ? COLOR_CONSTANTS.DENIM : COLOR_CONSTANTS.COSMIC_ENERGY}
                                      optionColor={COLOR_CONSTANTS.DENIM}
                                    >
                                      <option key="" value="">
                                        Call to action
                                      </option>
                                      {CALL_TO_ACTIONS.map(({ label, value }) => (
                                        <option key={value} value={value}>
                                          {label}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  </Flex>
                                  <TooltipPromptComponent prompt_key="call_to_action" network={FACEBOOK} />
                                </Flex>
                              )}
                          </Flex>
                        </Flex>
                      )}

                    {publish_as !== PUBLISH_AS_STORY && (showLocation || !isFacebookGroupProfilePage) && (
                      <Flex mt="m" flexDirection="column" position="relative">
                        {featuresEnabled[FEATURE_POST_TARGETING] &&
                          !featuresEnabled[FEATURE_POST_TARGETING].enabled && (
                            <CustomizationsBlockComponent
                              showUpgradeMessage={false}
                              showUpgradePlan={false}
                              message={featuresEnabled[FEATURE_POST_TARGETING].description}
                            />
                          )}

                        <Flex
                          flexDirection="column"
                          px={
                            featuresEnabled[FEATURE_POST_TARGETING] && !featuresEnabled[FEATURE_POST_TARGETING].enabled
                              ? 's'
                              : '0'
                          }
                          py={
                            featuresEnabled[FEATURE_POST_TARGETING] && !featuresEnabled[FEATURE_POST_TARGETING].enabled
                              ? `${isFacebookGroupProfilePage ? 'm' : 's'}`
                              : '0'
                          }
                        >
                          {showLocation && (
                            <Flex mb="m" flexDirection="column">
                              <Flex alignItems="center" width="100%">
                                <Flex flexDirection="column" width="100%">
                                  <Switch
                                    isOn={customizationsSchema.location}
                                    onClick={() => {
                                      const updatedValue = !customizationsSchema.location
                                      customizationsSchema.location = updatedValue
                                      setCustomizationsSchema({ ...customizationsSchema })
                                      if (!updatedValue) {
                                        handleUpdateCustomization({
                                          type: 'location',
                                          value: null,
                                        })
                                      }
                                    }}
                                    text="Location"
                                    color={COLOR_CONSTANTS.DENIM}
                                    fontWeight="normal"
                                  />
                                </Flex>
                                <TooltipPromptComponent prompt_key="location" network={FACEBOOK} />
                              </Flex>

                              {customizationsSchema.location && (
                                <Flex mt="m" flexDirection="column" width="100%">
                                  {location ? (
                                    <Flex width="100%" alignItems="center">
                                      <StyledTextWrapper flex="1" alignItems="center">
                                        <StyledLocationText
                                          as="a"
                                          href={`http://www.google.com/maps/place/${latitude},${longitude}`}
                                          color="primary"
                                          target="_blank"
                                        >
                                          {name}
                                        </StyledLocationText>
                                      </StyledTextWrapper>
                                      <Flex alignItems="center" ml="m">
                                        <StyledLocationIconWrapper
                                          alignItems="center"
                                          justifyContent="flex-end"
                                          ml="s"
                                          onClick={() => {
                                            handleUpdateCustomization({ type: 'location', value: null })
                                          }}
                                          width="16px"
                                        >
                                          <Icon.Trash width="16px" height="16px" />
                                        </StyledLocationIconWrapper>
                                      </Flex>
                                    </Flex>
                                  ) : (
                                    <DropDown
                                      placeholder="Search for location"
                                      value={null}
                                      onChange={(option) => {
                                        if (option) {
                                          const { value, location, label } = option
                                          const { latitude, longitude } = location || {}
                                          handleUpdateCustomization({
                                            type: 'location',
                                            value: { id: value, name: label, latitude, longitude },
                                          })

                                          setSavedSearches({
                                            type: SAVED_SEARCH_TYPE_FACEBOOK_LOCATION,
                                            id: profileId,
                                            result: option,
                                            dataForCustomizations,
                                            setDataForCustomizations,
                                          })
                                        } else {
                                          handleUpdateCustomization({
                                            type: 'location',
                                            value: null,
                                          })
                                        }
                                      }}
                                      loadOptions={(value, callback) =>
                                        loadSuggestedOptions(
                                          {
                                            value,
                                            network: FACEBOOK,
                                            id: profileId,
                                            getSuggestedOptionsErrors,
                                            setGetSuggestedOptionsErrors,
                                            filterAccountsWithPlace: true,
                                          },
                                          callback
                                        )
                                      }
                                      defaultValue={[]}
                                      isClearable
                                      defaultOptions={SAVED_LOCATION_OPTIONS}
                                      openMenuOnFocus
                                      dropDownType="AsyncSelect"
                                      showDropDownArrow={false}
                                      formatOptionLabel={({ ...props }) => {
                                        return formatOptionLabel({ ...props })
                                      }}
                                      noOptionsMessage={() => {
                                        return getSuggestedOptionsErrors[FACEBOOK] ? 'No results found' : null
                                      }}
                                    />
                                  )}
                                </Flex>
                              )}
                            </Flex>
                          )}

                          {!isFacebookGroupProfilePage && (
                            <Fragment>
                              <Flex alignItems="center" width="100%" position="relative">
                                <Flex flexDirection="column" width="100%">
                                  <DropDown
                                    isMulti
                                    placeholder="Country targeting"
                                    value={
                                      countries.length !== 0
                                        ? mapArrayToReactSelect(
                                            formDataCountries.filter(({ code }) => countries.includes(code))
                                          )
                                        : null
                                    }
                                    onChange={(options) => {
                                      handleUpdateCustomization({
                                        type: 'countries',
                                        value: options ? options.map(({ value }) => value) : [],
                                      })
                                    }}
                                    options={mapArrayToReactSelect(formDataCountries)}
                                    openMenuOnFocus
                                  />
                                </Flex>
                                <TooltipPromptComponent prompt_key="country_targeting" network={FACEBOOK} />
                              </Flex>

                              <Flex mt="m" alignItems="center" width="100%">
                                <Flex flexDirection="column" width="100%">
                                  <StyledSelect
                                    id="relationship_status"
                                    name="relationship_status"
                                    label="Relationship status"
                                    placeholder="Relationship status"
                                    onChange={({ target: { value } }) => {
                                      handleUpdateCustomization({
                                        type: 'relationship_status',
                                        value,
                                        remove_empty: true,
                                      })
                                    }}
                                    value={relationship_status}
                                    color={relationship_status ? COLOR_CONSTANTS.DENIM : COLOR_CONSTANTS.COSMIC_ENERGY}
                                    optionColor={COLOR_CONSTANTS.DENIM}
                                  >
                                    <option key="" value="">
                                      Relationship status
                                    </option>
                                    {RELATIONSHIP_STATUSES.map(({ label, value }) => (
                                      <option key={value} value={value}>
                                        {label}
                                      </option>
                                    ))}
                                  </StyledSelect>
                                </Flex>
                                <TooltipPromptComponent prompt_key="relationship_status" network={FACEBOOK} />
                              </Flex>

                              <Flex mt="m" alignItems="center" width="100%">
                                <Flex flexDirection="column" width="100%">
                                  <StyledSelect
                                    id="gender"
                                    name="gender"
                                    label="Gender"
                                    placeholder="Gender"
                                    onChange={({ target: { value } }) => {
                                      handleUpdateCustomization({
                                        type: 'gender',
                                        value,
                                        remove_empty: true,
                                      })
                                    }}
                                    value={gender}
                                    color={gender ? COLOR_CONSTANTS.DENIM : COLOR_CONSTANTS.COSMIC_ENERGY}
                                    optionColor={COLOR_CONSTANTS.DENIM}
                                  >
                                    <option key="" value="">
                                      Gender
                                    </option>
                                    {GENDERS.map(({ label, value }) => (
                                      <option key={value} value={value}>
                                        {label}
                                      </option>
                                    ))}
                                  </StyledSelect>
                                </Flex>
                                <TooltipPromptComponent prompt_key="gender" network={FACEBOOK} />
                              </Flex>

                              <Flex mt="m" alignItems="center" width="100%">
                                <Flex width="100%" justifyContent="space-between">
                                  <Flex flexDirection="column" width="49%">
                                    <Input
                                      placeholder="Min age"
                                      label=""
                                      value={age_min}
                                      name="age_min"
                                      id="age_min"
                                      onChange={(e) => {
                                        let { value } = e.target
                                        if (value) {
                                          value = Number(value)
                                        }
                                        if (value >= 0 && value <= 99) {
                                          handleUpdateCustomization({
                                            type: 'age_min',
                                            value,
                                          })
                                        }
                                      }}
                                      type="number"
                                      width="100%"
                                      min={0}
                                      max={99}
                                      color={COLOR_CONSTANTS.DENIM}
                                    />
                                  </Flex>
                                  <Flex flexDirection="column" width="49%">
                                    <Input
                                      placeholder="Max age"
                                      label=""
                                      value={age_max}
                                      name="age_max"
                                      id="age_max"
                                      onChange={(e) => {
                                        let { value } = e.target
                                        if (value) {
                                          value = Number(value)
                                        }
                                        if (value >= 0 && value <= 99) {
                                          handleUpdateCustomization({
                                            type: 'age_max',
                                            value,
                                          })
                                        }
                                      }}
                                      type="number"
                                      width="100%"
                                      min={0}
                                      max={99}
                                      color={COLOR_CONSTANTS.DENIM}
                                    />
                                  </Flex>
                                </Flex>
                                <TooltipPromptComponent prompt_key="age" network={FACEBOOK} />
                              </Flex>
                            </Fragment>
                          )}
                        </Flex>
                      </Flex>
                    )}

                    {!isAdvocacy && (
                      <BoostsDropdownComponent
                        boosts={boosts && boosts.length !== 0 ? boosts : null}
                        handleUpdateCustomization={handleUpdateCustomization}
                        boostId={boost_gid}
                        user={user}
                        profile={profile}
                      />
                    )}
                  </Flex>
                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

FacebookPost.defaultProps = {
  user: {},
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isSmallPreview: false,
  isAdvocacy: false,
}

FacebookPost.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object,
  profile: PropTypes.object,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isPostFormDirty: PropTypes.bool,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

FacebookPost.displayName = 'FacebookPost'

export default FacebookPost
