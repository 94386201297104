import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, fontSizes, radius, space } from 'theme'
import {
  IMAGE,
  VIDEO,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  PINTEREST,
  REDDIT,
  SNAPCHAT,
  THREADS,
  TIKTOK,
  TUMBLR,
  TWITTER,
  VIMEO,
  VISTAPAGE,
  YOUTUBE,
  GOOGLE,
} from 'consts'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import {
  POST_IMAGE,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  PUBLISH_AS_REELS,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_STORY,
} from 'routes/Calendar/consts'

const StyledAccordionWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
  padding: ${space.m} ${space.m} 0 ${space.m};

  .MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordion-root.Mui-expanded:before {
    opacity: 1 !important;
  }

  .MuiAccordion-root:before {
    top: 0px;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    position: absolute;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.12);
  }
`

const StyledAccordion = styled(Accordion)`
  padding: 0;

  background-color: ${({ $backgroundColor }) =>
    transparentize(0.9, $backgroundColor || COLOR_CONSTANTS.FLUORESCENT_RED)} !important;
  border-radius: ${radius.l} !important;

  .MuiAccordionDetails-root {
    padding: 0;
    color: ${colors.primaryText};
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 ${space.m} !important;
  min-height: 48px !important;

  .MuiAccordionSummary-content {
    margin: ${space.m} 0 !important;
    color: ${colors.primaryText} !important;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${COLOR_CONSTANTS.WHITE};
  align-items: center;
  justify-content: center;
`

const StyledComponentWrapper = styled.ol`
  flex-direction: column;
  width: 100%;
  margin-top: 0;
  margin-bottom: ${space.s};
`

const StyledListItem = styled.li`
  margin-bottom: ${space.s};
  &::marker {
    color: ${({ $color }) => $color};
    font-size: ${fontSizes.s};
    font-style: normal;
  }
`

const StyledText = styled(Text)`
  a {
    color: ${colors.primary};
    text-decoration: none;
  }
`

const { PANEL_ERRORS, PANEL_WARNINGS } = { PANEL_ERRORS: 'errors', PANEL_WARNINGS: 'warnings' }

const PreviewPromptComponent = ({
  networkLimits,
  hasErrors,
  flags,
  warning_flags,
  network,
  display,
  customizedPostComponent,
  customizedPostImages,
  customizedPostVideos,
  customizedPostDocuments,
  customizedPostMixedMedias,
  initialCustomizedPostMixedMedias,
  isFacebookGroupProfilePage,
  mentionsFromPost,
  notFoundMentions,

  video_processing,
  media_type,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(null)

  const handleClickChangeAccordion = (panel) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false)
  }

  const {
    postTextMaxLimit: MAX_TEXT_LENGTH,
    postTextMaxLimitPaid: MAX_TEXT_PAID_LENGTH,
    commentTextMaxLimit: MAX_COMMENT_LENGTH,
    commentTextMaxLimitPaid: MAX_COMMENT_PAID_LENGTH,

    imageExtensions: IMAGE_EXTENSIONS,
    maxImages: MAX_IMAGES,
    maxImageSizeString: MAX_IMAGE_SIZE_TEXT,
    minImageWidth: MIN_IMAGE_WIDTH,
    minImageHeight: MIN_IMAGE_HEIGHT,
    maxImageWidth: MAX_IMAGE_WIDTH,
    maxImageHeight: MAX_IMAGE_HEIGHT,
    minImageAspectRatio: MIN_IMAGE_ASPECT_RATIO,
    maxImageAspectRatio: MAX_IMAGE_ASPECT_RATIO,
    imagesTitleLimit: MAX_IMAGES_TITLE_LENGTH,

    videoExtensions: VIDEO_EXTENSIONS,
    maxVideos: MAX_VIDEOS,
    maxVideoSizeString: MAX_VIDEO_SIZE_TEXT,
    minVideoDuration: MIN_VIDEO_DURATION,
    maxVideoDuration: MAX_VIDEO_DURATION,
    minVideoAspectRatio: MIN_VIDEO_ASPECT_RATIO,
    maxVideoAspectRatio: MAX_VIDEO_ASPECT_RATIO,
    videoTitleLimit: MAX_VIDEO_TITLE_LENGTH,

    documentExtensions: DOCUMENT_EXTENSIONS,
    maxDocuments: MAX_DOCUMENTS,
    maxDocumentSizeString: MAX_DOCUMENT_SIZE_TEXT,

    minReelAspectRatio,
    maxReelAspectRatio,
    minReelAspectRatioVideoProcessingOff,
    maxReelAspectRatioVideoProcessingOff,

    slideshowMinImages: SLIDESHOW_MIN_IMAGES,
    slideshowMaxImages: SLIDESHOW_MAX_IMAGES,

    minMixedMediasInCarousel: MIN_MIXED_MEDIAS_IN_CAROUSEL,
    maxImagesInComments: MAX_IMAGES_IN_COMMENTS,

    titleLimit: MAX_TITLE_LENGTH,
    descriptionTextMaxLimit: MAX_DESCRIPTION_LENGTH,
    spotlightDescriptionTextMaxLimit: MAX_SPOTLIGHT_DESCRIPTION_TEXT_LENGTH,
    flairTextMaxLimit: MAX_FLAIR_LENGTH,
    eventTitleMaxLimit: MAX_EVENT_TITLE_LENGTH,
    maxOriginalAudioTitleLength: MAX_ORIGINAL_AUDIO_TITLE_LENGTH,

    maxTags: MAX_TAGS,
    maxHashTags: MAX_HASHTAGS,
    slugMaxLimit: MAX_SLUG_LENGTH,
    maxTagsLimit: MAX_TAGS_LIMIT,
    maxTagsLength: MAX_TAGS_LENGTH,
  } = networkLimits || {}

  const {
    noContent,
    postTextMaxLimit,
    commentTextMaxLimit,
    postTextMinLimit,

    postImagesLengthLimit,
    postImageAspectRatioLimit,
    postImageResolutionLimit,
    postImageSizeLimit,
    postImageExtensionError,

    postVideosLengthLimit,
    postVideoDurationLimit,
    postVideoAspectRatioLimit,
    postVideoExtensionError,
    postVideoSizeLimit,

    postMixedMediasLengthLimit,
    carouselLinkLengthLimit,
    postSlideshowLimit,

    postDocumentSizeLimit,
    postDocumentExtensionError,
    postDocumentDescriptionLengthLimit,
    postDocumentTitleLengthLimit,
    documentsNotSupported,

    postLinkPictureUrlLengthLimit,

    deviceLengthLimit,
    notificationDeviceLengthLimit,

    notFoundVariables,

    hashtagsTextLengthLimit,
    hashtagsCommentLengthLimit,

    titleLengthLimit,
    descriptionTextLengthLimit,
    videoTitleLength,
    videoTitleLengthLimit,
    slugLengthLimit,
    tagsMaxLimit,
    tagsTextMaxLimit,
    spotlightDescriptionTextLengthLimit,

    noBoardSelected,
    postPinLinkError,

    subredditLength,
    subredditLengthLimit,

    eventTitleMaxLimit,
    postEventTitle,
    postEventStart,
    postEventEnd,
    postOfferCoupon,
    postOfferTerms,
    invalidCTA,
  } = flags || {}

  const {
    postVideoAspectRatioLimit: postVideoAspectRatioLimitWarning,
    postMediaAspectRatioVariousWarning,
    postImageAspectRatioLimitWarning,
    postVideoDurationLimitWarning,
  } = warning_flags || {}

  const errors = []

  const warnings = []

  if (network === FACEBOOK) {
    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push('Post must have a message or at least one attached media.')
      }

      if (postVideoAspectRatioLimit) {
        errors.push(
          `${display} requires the video aspect ratio to be within ${MIN_VIDEO_ASPECT_RATIO} to ${MAX_VIDEO_ASPECT_RATIO}. Please edit or crop the video and re-upload it again.`
        )
      }
      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }

      if (postSlideshowLimit) {
        errors.push(`Slideshow must contain between ${SLIDESHOW_MIN_IMAGES} and ${SLIDESHOW_MAX_IMAGES} images.`)
      }

      if (carouselLinkLengthLimit) {
        errors.push(`${display} carousel must contain a destination link.`)
      }
    }

    if (isFacebookGroupProfilePage) {
      if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > 1) {
        warnings.push(`Due to limitations set by ${display} for groups, only the first image will be published.`)
      }
      if (mentionsFromPost.length !== 0 || notFoundMentions.length !== 0) {
        warnings.push(`Mentions are not available for ${display} groups. Any mention will be published as plain text.`)
      }
    }

    if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    }

    if (customizedPostComponent === POST_MIXED_MEDIA) {
      if (customizedPostMixedMedias[0]) {
        if (customizedPostMixedMedias[0].type === IMAGE || customizedPostMixedMedias[0].type === IMAGE_DYNAMIC) {
          if (isFacebookGroupProfilePage) {
            if (customizedPostMixedMedias.length > 1) {
              warnings.push(`Due to limitations set by ${display} for groups, only the first image will be published.`)
            }
          }
        } else if (customizedPostMixedMedias[0].type === VIDEO || customizedPostMixedMedias[0].type === VIDEO_DYNAMIC) {
          if (customizedPostMixedMedias.length > MAX_VIDEOS) {
            warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
          }
        }

        if (initialCustomizedPostMixedMedias.length !== customizedPostMixedMedias.length) {
          warnings.push(`Due to limitations set by ${display} mixed media carousels are not currently supported.`)
        }
      }
    }
  }

  if (network === INSTAGRAM) {
    let previewPromptMessageText_postVideoAspectRatioLimit = ''
    let MIN_VIDEO_ASPECT_RATIO_UPDATED = MIN_VIDEO_ASPECT_RATIO
    let MAX_VIDEO_ASPECT_RATIO_UPDATED = MAX_VIDEO_ASPECT_RATIO

    if (media_type === PUBLISH_AS_REELS || media_type === PUBLISH_AS_CAROUSEL) {
      if (minReelAspectRatio) {
        MIN_VIDEO_ASPECT_RATIO_UPDATED = minReelAspectRatio
      }
      if (maxReelAspectRatio) {
        MAX_VIDEO_ASPECT_RATIO_UPDATED = maxReelAspectRatio
      }

      if (!video_processing) {
        if (minReelAspectRatioVideoProcessingOff) {
          MIN_VIDEO_ASPECT_RATIO_UPDATED = minReelAspectRatioVideoProcessingOff
        }
        if (maxReelAspectRatioVideoProcessingOff) {
          MAX_VIDEO_ASPECT_RATIO_UPDATED = maxReelAspectRatioVideoProcessingOff
        }
      }
    }

    if (postVideoAspectRatioLimit || postVideoAspectRatioLimitWarning) {
      previewPromptMessageText_postVideoAspectRatioLimit = `Video is outside of ${display} required ${MIN_VIDEO_ASPECT_RATIO_UPDATED} to ${MAX_VIDEO_ASPECT_RATIO_UPDATED} aspect ratio for ${
        media_type === PUBLISH_AS_STORY ? 'stories' : 'feed posts'
      }.`

      if (media_type === PUBLISH_AS_REELS) {
        previewPromptMessageText_postVideoAspectRatioLimit = `Video is outside of Instagram required ${
          MIN_VIDEO_ASPECT_RATIO_UPDATED === MAX_VIDEO_ASPECT_RATIO_UPDATED
            ? MIN_VIDEO_ASPECT_RATIO_UPDATED
            : `${MIN_VIDEO_ASPECT_RATIO_UPDATED} to ${MAX_VIDEO_ASPECT_RATIO_UPDATED}`
        } aspect ratio for reels.`
      } else if (media_type === PUBLISH_AS_CAROUSEL) {
        previewPromptMessageText_postVideoAspectRatioLimit = `Carousel medias are all cropped based on the first media in the carousel, with the default being a 1:1 aspect ratio. Video is outside of Instagram required ${
          MIN_VIDEO_ASPECT_RATIO_UPDATED === MAX_VIDEO_ASPECT_RATIO_UPDATED
            ? MIN_VIDEO_ASPECT_RATIO_UPDATED
            : `${MIN_VIDEO_ASPECT_RATIO_UPDATED} to ${MAX_VIDEO_ASPECT_RATIO_UPDATED}`
        } aspect ratio for carousels.`
      }

      if (postVideoAspectRatioLimitWarning && video_processing) {
        previewPromptMessageText_postVideoAspectRatioLimit += ` You can still publish it but it will be padded. <a href="https://support.vistasocial.com/hc/en-us/articles/7835518092187" target="_blank" style="color: ${colors.warning};">Learn more</a>.`
      }
    }

    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || noContent) {
        errors.push(`${display} requires an image or a video.`)
      }

      if (hashtagsTextLengthLimit) {
        errors.push(`You can only include ${MAX_HASHTAGS} hashtags when publishing to ${display}.`)
      }

      if (hashtagsCommentLengthLimit) {
        errors.push(`You can only include ${MAX_HASHTAGS} hashtags when commenting on ${display}.`)
      }

      if (postVideoDurationLimit) {
        if (MAX_VIDEO_DURATION) {
          errors.push(
            `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
          )
        } else {
          errors.push(`The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds.`)
        }
      }

      if (postMixedMediasLengthLimit) {
        errors.push(`${display} requires at least ${MIN_MIXED_MEDIAS_IN_CAROUSEL} medias for carousel post.`)
      }

      if (postVideoAspectRatioLimit && !video_processing) {
        errors.push(previewPromptMessageText_postVideoAspectRatioLimit)
      }
    }

    if (customizedPostComponent === POST_IMAGE) {
      if (customizedPostImages.length > MAX_IMAGES) {
        warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
      }

      if (postImageAspectRatioLimitWarning) {
        warnings.push(`Due to limitations set by ${display}, aspect ratio must be within a 4:5 to 1.91:1 range.`)
      }
    } else if (customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) {
      if (customizedPostVideos.length > MAX_VIDEOS) {
        warnings.push(`Due to limitations set by ${display}, only the first ${MAX_VIDEOS} videos will be published.`)
      }

      if (postMediaAspectRatioVariousWarning) {
        warnings.push('Publishing media with different aspect ratios can result in publishing errors.')
      }

      if (postVideoAspectRatioLimitWarning) {
        if (video_processing) {
          warnings.push(previewPromptMessageText_postVideoAspectRatioLimit)
        } else {
          errors.push(previewPromptMessageText_postVideoAspectRatioLimit)
        }
      }
      // else if (media_type === MEDIA_TYPE_REEL) {
      // warnings.push(`Only reels with durations between 5 and 90 seconds and aspect ratio to be within ${MIN_VIDEO_ASPECT_RATIO_UPDATED} to ${MAX_VIDEO_ASPECT_RATIO_UPDATED} are eligible to appear in the Reels tab.`)
      // }
    }
  }

  if (network === LINKEDIN) {
    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`Post must have a message or at least one attached media.`)
      }

      if (postImageResolutionLimit) {
        errors.push(
          `Images must be at least ${MIN_IMAGE_WIDTH} x ${MIN_IMAGE_HEIGHT}${
            MAX_IMAGE_WIDTH && MAX_IMAGE_HEIGHT
              ? ` and no larger than ${MAX_IMAGE_WIDTH} x ${MAX_IMAGE_HEIGHT} pixels`
              : ''
          }.`
        )
      }

      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }

      if (postDocumentExtensionError) {
        let extension = ''
        const foundDocument = customizedPostDocuments.find(({ id }) => id === postDocumentExtensionError)
        if (foundDocument) {
          ;({ extension } = foundDocument)
        }

        const documentExtensionText = `${DOCUMENT_EXTENSIONS.slice(0, DOCUMENT_EXTENSIONS.length - 1).join(', ')} and ${
          DOCUMENT_EXTENSIONS[DOCUMENT_EXTENSIONS.length - 1]
        }`

        errors.push(
          `${
            extension ? `Current document is in .${extension} format.` : ''
          } LinkedIn only supports ${documentExtensionText} format.`
        )
      }

      if (postDocumentSizeLimit) {
        errors.push(`The size of the uploaded document file must be less than or equal to ${MAX_DOCUMENT_SIZE_TEXT}.`)
      }

      // if (postDocumentTitleLengthLimit) {
      //  errors.push(`${display} requires documents to have a title. Please update it in document settings.`)
      // }
      //
      // if (postDocumentDescriptionLengthLimit) {
      //  errors.push(`${display} requires documents to have a description. Please update it in document settings.`)
      // }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_DOCUMENT && customizedPostDocuments.length > MAX_DOCUMENTS) {
      warnings.push(`Due to limitations set by ${display}, only the first document will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      if (customizedPostMixedMedias[0]) {
        if (customizedPostMixedMedias[0].type === IMAGE || customizedPostMixedMedias[0].type === IMAGE_DYNAMIC) {
          if (customizedPostMixedMedias.length > MAX_IMAGES) {
            warnings.push(
              `Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`
            )
          }
        } else if (customizedPostMixedMedias[0].type === VIDEO || customizedPostMixedMedias[0].type === VIDEO_DYNAMIC) {
          if (customizedPostMixedMedias.length > MAX_VIDEOS) {
            warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
          }
        }

        if (initialCustomizedPostMixedMedias.length !== customizedPostMixedMedias.length) {
          warnings.push(`Due to limitations set by ${display} mixed media carousels are not currently supported.`)
        }
      }
    }
  }

  if (network === PINTEREST) {
    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`${display} requires an image or a video.`)
      }

      if (noBoardSelected) {
        errors.push('Please select a board for posting your pin.')
      }

      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }

      if (postPinLinkError) {
        errors.push(`${display} pin link is not a valid URL.`)
      }

      if (descriptionTextLengthLimit) {
        errors.push(`${display} pin description is too long.`)
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first image will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`Due to limitations set by ${display}, only the first media will be published.`)
    }
  }

  if (network === REDDIT) {
    if (hasErrors) {
      if (subredditLength) {
        errors.push('Subreddit is required.')
      }
      if (subredditLengthLimit) {
        errors.push(`Subreddit must be not more than ${MAX_TITLE_LENGTH} symbols.`)
      }
      if (titleLengthLimit) {
        errors.push(`${display} title must be not more than ${MAX_TITLE_LENGTH} symbols.`)
      }
      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first image will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`Due to limitations set by ${display}, only the first media will be published.`)
    }
  }

  if (network === SNAPCHAT) {
    if (hasErrors) {
      if (postVideosLengthLimit || postMixedMediasLengthLimit) {
        errors.push(`${display} requires a video.`)
      }

      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }

      if (spotlightDescriptionTextLengthLimit) {
        errors.push(`${display} spotlight description is too long.`)
      }
    }

    if (postVideoDurationLimitWarning) {
      warnings.push(
        `The duration of the video file is larger than ${MAX_VIDEO_DURATION} seconds. You can still publish it but it will be trimmed.`
      )
    }

    if (customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    }
  }

  if (network === THREADS) {
    let previewPromptMessageText_postVideoAspectRatioLimit = ''

    if (postVideoAspectRatioLimit || postVideoAspectRatioLimitWarning) {
      previewPromptMessageText_postVideoAspectRatioLimit = `Video is outside of ${display} required ${MIN_VIDEO_ASPECT_RATIO} to ${MAX_VIDEO_ASPECT_RATIO} aspect ratio for this post.`

      if (postVideoAspectRatioLimitWarning && video_processing) {
        previewPromptMessageText_postVideoAspectRatioLimit += ` You can still publish it but it will be padded.`
      }
    }

    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`Post must have a message or at least one attached media.`)
      }

      if (postVideoDurationLimit) {
        if (MAX_VIDEO_DURATION) {
          errors.push(
            `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
          )
        } else {
          errors.push(`The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds.`)
        }
      }

      if (postMixedMediasLengthLimit) {
        errors.push(`${display} require at least ${MIN_MIXED_MEDIAS_IN_CAROUSEL} medias for carousel post.`)
      }

      if (postVideoAspectRatioLimit && !video_processing) {
        errors.push(previewPromptMessageText_postVideoAspectRatioLimit)
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
    } else if (customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) {
      if (customizedPostVideos.length > MAX_VIDEOS) {
        warnings.push(`Due to limitations set by ${display}, only the first ${MAX_VIDEOS} videos will be published.`)
      }
      if (postVideoAspectRatioLimitWarning) {
        if (video_processing) {
          // video_processing flag is just for information that it would be a warning
          warnings.push(previewPromptMessageText_postVideoAspectRatioLimit)
        } else {
          errors.push(previewPromptMessageText_postVideoAspectRatioLimit)
        }
      }
    }
  }

  if (network === TIKTOK) {
    if (hasErrors) {
      if (noContent || postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit) {
        errors.push(`${display} requires a video or an image.`)
      }
      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }

      if (postImageExtensionError) {
        let extension = ''
        const foundImage = customizedPostImages.find(({ id }) => id === postImageExtensionError)
        if (foundImage) {
          ;({ extension } = foundImage)
        }

        const imageExtensionText = `${IMAGE_EXTENSIONS.slice(0, IMAGE_EXTENSIONS.length - 1).join(', ')} and ${
          IMAGE_EXTENSIONS[IMAGE_EXTENSIONS.length - 1]
        }`

        errors.push(
          `${
            extension ? `Current image is in .${extension} format.` : ''
          } TikTok only supports ${imageExtensionText} format. If your image is in the right format, please ensure that its file name ends in .jpg, .jpeg or .webp!`
        )
      }

      if (postImageResolutionLimit) {
        errors.push(
          `The width and height of the uploaded image file must be less than or equal to ${MAX_IMAGE_WIDTH} x ${MAX_IMAGE_HEIGHT}.`
        )
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      if (customizedPostMixedMedias[0]) {
        let hasDifferentMediaType = null

        if (customizedPostMixedMedias[0].type === IMAGE || customizedPostMixedMedias[0].type === IMAGE_DYNAMIC) {
          if (customizedPostMixedMedias.length > MAX_IMAGES) {
            warnings.push(
              `Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`
            )
          }

          hasDifferentMediaType = customizedPostMixedMedias.find(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
        } else if (customizedPostMixedMedias[0].type === VIDEO || customizedPostMixedMedias[0].type === VIDEO_DYNAMIC) {
          if (customizedPostMixedMedias.length > MAX_VIDEOS) {
            warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
          }

          hasDifferentMediaType = customizedPostMixedMedias.find(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
        }

        if (hasDifferentMediaType) {
          warnings.push(`Due to limitations set by ${display} mixed media carousels are not currently supported.`)
        }
      }
    }
  }

  if (network === TUMBLR) {
    if (hasErrors) {
      if (noContent) {
        errors.push(`Post must have a message or at least one attached media.`)
      }

      if (titleLengthLimit) {
        errors.push(`${display} title must be not more than ${MAX_TITLE_LENGTH} symbols.`)
      }
      if (slugLengthLimit) {
        errors.push(`${display} slug must be not more than ${MAX_SLUG_LENGTH} symbols.`)
      }
      if (tagsMaxLimit) {
        errors.push(`${display} tags must be not more than ${MAX_TAGS_LIMIT} tags.`)
      }
      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    }
    // else if (customizedPostComponent === POST_MIXED_MEDIA) {
    // warnings.push(`Due to limitations set by ${display}, mixed media posts are not supported.`)
    // }
  }

  if (network === TWITTER) {
    if (hasErrors) {
      if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`Post must have a message or at least one attached media.`)
      }

      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first ${MAX_IMAGES} images will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (
      customizedPostComponent === POST_MIXED_MEDIA &&
      customizedPostVideos.length > 0 &&
      customizedPostImages.length > 0
    ) {
      warnings.push(`Due to limitations set by ${display}, only the first media will be published.`)
    }

    if (postVideoDurationLimitWarning) {
      warnings.push(
        `The duration of the video file is larger than ${MAX_VIDEO_DURATION} seconds. You can still publish it but it will be trimmed.`
      )
    }
  }

  if (network === VIMEO || network === YOUTUBE) {
    if (hasErrors) {
      if (postVideosLengthLimit || postMixedMediasLengthLimit) {
        errors.push(`${display} requires a video.`)
      }

      if (videoTitleLength) {
        errors.push('Video title is required.')
      }

      if (videoTitleLengthLimit) {
        errors.push(`Vimeo title must be not more than ${MAX_VIDEO_TITLE_LENGTH} symbols.`)
      }

      if (postVideoDurationLimit) {
        errors.push(`Vimeo video must be between ${MIN_VIDEO_DURATION} and ${MAX_VIDEO_DURATION} seconds.`)
      }

      if (tagsTextMaxLimit) {
        errors.push(`Your video tags are too long. Please limit them to ${MAX_TAGS_LENGTH} symbols.`)
      }

      if (postVideoDurationLimit) {
        errors.push(
          `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
        )
      }
    }

    if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`Due to limitations set by ${display}, only the first video will be published.`)
    }
  }

  if (network === VISTAPAGE) {
    if (hasErrors) {
      if (postImagesLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`Either message or attached image is required.`)
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`${display} only supports single image posts. Only the first image will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > 0) {
      warnings.push(`${display} does not support video posts at this time.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`${display} only supports single image posts. Only the first image will be published.`)
    }
  }

  if (network === GOOGLE) {
    if (hasErrors) {
      if (postImagesLengthLimit || postMixedMediasLengthLimit || noContent) {
        errors.push(`Either message or attached image is required.`)
      }

      if (invalidCTA) {
        errors.push(`${display} requires a link when posting call to action.`)
      }

      if (postEventTitle) {
        errors.push(`${display} event title is required.`)
      }

      if (postEventStart) {
        errors.push(`${display} event start date must be filled in.`)
      }

      if (postEventEnd) {
        errors.push(`${display} event end date must be filled in.`)
      }

      if (postOfferCoupon) {
        errors.push(`${display} offer code is required.`)
      }

      if (postOfferTerms) {
        errors.push(`${display} offer terms and conditionals is required.`)
      }

      if (eventTitleMaxLimit) {
        errors.push(`${display} event title must be not more than ${MAX_EVENT_TITLE_LENGTH} symbols.`)
      }
    }

    if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES) {
      warnings.push(`Due to limitations set by ${display}, only the first image will be published.`)
    } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > 0) {
      warnings.push(`Due to ${display} limitations, videos cannot be published to ${display} listings.`)
    } else if (customizedPostComponent === POST_MIXED_MEDIA) {
      warnings.push(`Due to ${display} limitations, only the first image will be published.`)
    }
  }

  if (hasErrors) {
    if (postTextMinLimit) {
      errors.push(
        `${display} requires post text. You can customize it for this network by clicking on the post preview.`
      )
    }

    if (postTextMaxLimit) {
      errors.push('Your post text is too long. You can customize it for this network by clicking on the post preview.')
    }

    if (commentTextMaxLimit) {
      errors.push('Your post comment is too long.')
    }

    if (postVideoExtensionError) {
      let extension = ''
      const foundVideo = customizedPostVideos.find(({ id }) => id === postVideoExtensionError)
      if (foundVideo) {
        ;({ extension } = foundVideo)
      }

      const videoExtensionText = `${VIDEO_EXTENSIONS.slice(0, VIDEO_EXTENSIONS.length - 1).join(', ')} and ${
        VIDEO_EXTENSIONS[VIDEO_EXTENSIONS.length - 1]
      }`

      errors.push(
        `${
          extension ? `Current video is in .${extension} format.` : ''
        } ${display} only supports ${videoExtensionText} format.`
      )
    }

    if (postVideoSizeLimit) {
      errors.push(`The size of the uploaded video file must be less than or equal to ${MAX_VIDEO_SIZE_TEXT}.`)
    }

    if (postImageSizeLimit) {
      errors.push(`The size of the uploaded image file must be less than or equal to ${MAX_IMAGE_SIZE_TEXT}.`)
    }

    if (postImageAspectRatioLimit) {
      errors.push(
        `${display} requires the image aspect ratio to be within ${MIN_IMAGE_ASPECT_RATIO} to ${MAX_IMAGE_ASPECT_RATIO}. Please edit or crop the image and re-upload it again.`
      )
    }

    if (postLinkPictureUrlLengthLimit) {
      errors.push(`${display} requires an image when posting a link.`)
    }

    if (deviceLengthLimit) {
      errors.push(
        `${display} requires a mobile device when posting via reminder notification. Please download Vista Social mobile app, get logged in and try again.`
      )
    }

    if (notificationDeviceLengthLimit) {
      errors.push(
        `${display} requires a mobile device for notifications when posting. Please download Vista Social mobile app, get logged in and try again.`
      )
    }

    if (notFoundVariables) {
      errors.push('Some variables are not defined for this profile.')
    }

    if (documentsNotSupported) {
      errors.push(`${display} doesn't support document posting.`)
    }
  }

  return (
    <Fragment>
      {(errors.length > 0 || warnings.length > 0) && (
        <StyledAccordionWrapper>
          {errors.length > 0 && (
            <Flex flexDirection="column" mb="m">
              <StyledAccordion
                expanded={accordionExpanded === PANEL_ERRORS}
                onChange={handleClickChangeAccordion(PANEL_ERRORS)}
                $backgroundColor={colors.error}
              >
                <StyledAccordionSummary
                  expandIcon={
                    errors.length === 1 ? null : (
                      <StyledArrowWrapper>
                        <Icon.VistaSocialChevronDown fill={colors.error} />
                      </StyledArrowWrapper>
                    )
                  }
                  aria-controls={`${PANEL_ERRORS}-content`}
                  id={`${PANEL_ERRORS}-header`}
                >
                  <StyledText
                    color={colors.error}
                    fontWeight={errors.length > 1 ? 'medium' : 'normal'}
                    dangerouslySetInnerHTML={{
                      __html:
                        errors.length === 1
                          ? errors[0]
                          : `You have ${errors.length} errors. Please check your content.`,
                    }}
                  />
                </StyledAccordionSummary>
                {errors.length > 1 && (
                  <AccordionDetails>
                    <StyledComponentWrapper>
                      {errors.map((error, index) => {
                        return (
                          <StyledListItem key={index} $color={colors.error}>
                            <StyledText color={colors.error} dangerouslySetInnerHTML={{ __html: error }} />
                          </StyledListItem>
                        )
                      })}
                    </StyledComponentWrapper>
                  </AccordionDetails>
                )}
              </StyledAccordion>
            </Flex>
          )}

          {warnings.length > 0 && (
            <Flex flexDirection="column" mb="m">
              <StyledAccordion
                expanded={accordionExpanded === PANEL_WARNINGS}
                onChange={handleClickChangeAccordion(PANEL_WARNINGS)}
                $backgroundColor={colors.warning}
              >
                <StyledAccordionSummary
                  expandIcon={
                    warnings.length === 1 ? null : (
                      <StyledArrowWrapper>
                        <Icon.VistaSocialChevronDown fill={colors.warning} />
                      </StyledArrowWrapper>
                    )
                  }
                  aria-controls={`${PANEL_WARNINGS}-content`}
                  id={`${PANEL_WARNINGS}-header`}
                >
                  <StyledText
                    color={colors.warning}
                    fontWeight={warnings.length > 1 ? 'medium' : 'normal'}
                    dangerouslySetInnerHTML={{
                      __html: warnings.length === 1 ? warnings[0] : `You have ${warnings.length} warnings.`,
                    }}
                  />
                </StyledAccordionSummary>
                {warnings.length > 1 && (
                  <AccordionDetails>
                    <StyledComponentWrapper>
                      {warnings.map((warning, index) => {
                        return (
                          <StyledListItem key={index} $color={colors.warning}>
                            <StyledText color={colors.warning} dangerouslySetInnerHTML={{ __html: warning }} />
                          </StyledListItem>
                        )
                      })}
                    </StyledComponentWrapper>
                  </AccordionDetails>
                )}
              </StyledAccordion>
            </Flex>
          )}
        </StyledAccordionWrapper>
      )}
    </Fragment>
  )
}

PreviewPromptComponent.defaultProps = {
  hasErrors: false,
  flags: {},
  warning_flags: {},
  customizedPostComponent: null,
  customizedPostImages: [],
  customizedPostVideos: [],
  customizedPostDocuments: [],
  customizedPostMixedMedias: [],
  initialCustomizedPostMixedMedias: [],
  isFacebookGroupProfilePage: false,
  mentionsFromPost: [],
  notFoundMentions: [],
  video_processing: false,
  media_type: '',
}

PreviewPromptComponent.propTypes = {
  networkLimits: PropTypes.object.isRequired,
  hasErrors: PropTypes.bool,
  flags: PropTypes.object,
  warning_flags: PropTypes.object,
  network: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  customizedPostComponent: PropTypes.string,
  customizedPostImages: PropTypes.array,
  customizedPostVideos: PropTypes.array,
  customizedPostDocuments: PropTypes.array,
  customizedPostMixedMedias: PropTypes.array,
  initialCustomizedPostMixedMedias: PropTypes.array,
  isFacebookGroupProfilePage: PropTypes.bool,
  mentionsFromPost: PropTypes.array,
  notFoundMentions: PropTypes.array,
  video_processing: PropTypes.bool,
  media_type: PropTypes.string,
}

export default PreviewPromptComponent
