import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { transparentize } from 'polished'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { COLOR_CONSTANTS, colors, fontSizes, radius, space } from 'theme'
import {
  FEATURE_FIND_CONTENT,
  FEATURE_CANVA,
  FEATURE_DRIVES,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO,
  VIDEO_DYNAMIC,
  DOCUMENT,
} from 'consts'
import { getFileIconByExtension } from 'helpers'
import request from 'utils/request'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
// eslint-disable-next-line import/no-named-default
import { default as ImageComponent } from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import DropdownMenu from 'components/molecules/DropdownMenu'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Divider from 'components/atoms/Divider'
import { Text } from 'components/atoms/Typography'
import MetaInformationModal from 'routes/Media/components/MetaInformationModal'
import VideoPickSoundModal from 'routes/Media/components/VideoPickSoundModal'
import MediaDetailModal from 'routes/Media/components/MediaDetailModal'
import ImageEditModal from '../../ImageEditModal'
import VideoThumbnailModal from '../../VideoThumbnailModal'
import AttachmentsDropdownMenu from '../../AttachmentsDropdownMenu'
// eslint-disable-next-line import/no-cycle
import DynamicMediaModal from '../../DynamicMediaModal'
import CalendarItemTooltip from '../../CalendarItemTooltip'
import {
  POST_IMAGE,
  POST_MEDIA_CLASS_NAME,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  ROUTE_MEDIA,
  IMAGE_EXTENSIONS,
} from '../../../consts'
// eslint-disable-next-line import/no-cycle
import { usePostCreationContext, usePostCreationDispatch } from '../../../contexts/PostCreationContext'

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -9px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  z-index: 2;
`

const StyledHiddenIconWrapper = styled(Flex)`
  display: none;
  position: absolute;
  background: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
  border-radius: ${radius.pill};
  padding: ${space.xs};
  z-index: 1;
`

const PostMediaWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  position: relative;
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  flex-direction: column;
  text-align: center;
  &:hover {
    ${StyledHiddenIconWrapper} {
      display: flex;
    }
    box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 5%);
  }
`

const SelectedMediaWrapper = styled(Flex)`
  overflow-x: hidden;
  padding: 0 ${space.m};
  flex-direction: column;
`

const SelectWrapper = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  height: 20px;
  width: 16px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : COLOR_CONSTANTS.WHITE)};
  border-bottom-right-radius: ${radius.l};
`

const StyledVideoProcessingErrorIconWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
  border-radius: ${radius.pill};
  z-index: 1;
  bottom: ${space.xxs};
  left: ${space.xxs};
  width: 18px;
  height: 18px;
`

const StyledUploadProgressIndicatorText = styled(Text)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ $isSmall }) => ($isSmall ? fontSizes.xxs : fontSizes.xs)};
  color: ${colors.primary};
`

const IMAGE_EXTENSIONS_JOINED = IMAGE_EXTENSIONS.join(',')

const MEDIA_META_INFORMATION_MODAL_OPTIONS = {
  isOpen: false,
  media: null,
}

const DYNAMIC_MEDIA_MODAL_OPTIONS = {
  isOpen: false,
  media: null,
}

const DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS = { isOpen: false, data: null }

const UploadedMediasComponent = ({
  post,
  specialPostKey,
  network,
  profileId,
  selectedEntities,
  selectedProfiles,
  user,
  uploadMediaComponentRef,
  isSmall,
}) => {
  const tooltipRef = useRef(null)

  const { mediaProcessingIntervals } = usePostCreationContext()
  const { mediaProcessingIntervalsDispatch, postDispatch } = usePostCreationDispatch()

  const [selectedImage, setSelectedImage] = useState({})
  const [selectedVideo, setSelectedVideo] = useState({})
  const [isOpenVideoThumbnailModal, setIsOpenVideoThumbnailModal] = useState(false)
  const [isOpenVideoPickSoundModal, setIsOpenVideoPickSoundModal] = useState(false)
  const [isThumbnailUpdating, setIsThumbnailUpdating] = useState(false)
  const [isOpenImageEditModal, setIsOpenImageEditModal] = useState(false)
  const [isMediaUpdating, setIsMediaUpdating] = useState(false)
  const [mediaMetaInformationOptions, setMediaMetaInformationOptions] = useState({
    ...MEDIA_META_INFORMATION_MODAL_OPTIONS,
  })
  const [dynamicMediaOptions, setDynamicMediaOptions] = useState({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })
  const [mediaDetailModalOptions, setMediaDetailModalOptions] = useState({ ...DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS })
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_FIND_CONTENT]: { enabled: true },
    [FEATURE_CANVA]: { enabled: true },
    [FEATURE_DRIVES]: { enabled: true },
  })

  const { postImages = [], postVideos = [], postDocuments = [], postMixedMedias = [], postComponent } = post

  const onChangeDispatchPost = ({ type, data }) => {
    const postDispatchData = { type, data, network, profileId, specialPostKey }

    data.selectedProfiles = selectedProfiles

    postDispatch(postDispatchData)
  }

  useEffect(() => {
    setFeaturesEnabled({
      ...checkIfPlanHasFeatureEnabled({
        user,
        features: [FEATURE_FIND_CONTENT, FEATURE_CANVA, FEATURE_DRIVES],
      }),
    })
  }, [])

  const handleClickRemovePostMedia = ({ id, key }) => {
    onChangeDispatchPost({
      type: 'remove_existing_media',
      data: { post_media_key: key, media: { media_gid: id } },
    })
  }

  const handleClickOpenImageEditModal = ({ ...props }) => {
    setSelectedImage({ ...props })
    setIsOpenImageEditModal(true)
  }

  const handleClickCloseImageEditModal = () => {
    setSelectedImage({})
    setIsOpenImageEditModal(false)
  }

  const handleClickOpenMediaMetaInformationModal = ({ media }) => {
    media.needThumbnail = true
    setMediaMetaInformationOptions({ ...{ isOpen: true, media, postComponent } })
  }

  const handleClickCloseMediaMetaInformationModal = () => {
    setMediaMetaInformationOptions({ ...MEDIA_META_INFORMATION_MODAL_OPTIONS })
  }

  const handleClickOpenMediaDynamicModal = ({ media }) => {
    setDynamicMediaOptions({ ...{ isOpen: true, media } })
  }

  const handleClickCloseMediaDynamicModal = () => {
    setDynamicMediaOptions({ ...DYNAMIC_MEDIA_MODAL_OPTIONS })
  }

  const handleClickOpenMediaDetailModal = ({ media }) => {
    media.index = -1
    setMediaDetailModalOptions({ isOpen: true, data: media })
  }

  const handleClickCloseMediaDetailModal = () => {
    setMediaDetailModalOptions({ ...DEFAULT_MEDIA_DETAIL_MODAL_OPTIONS })
  }

  const handleUpdateMediaInMediaDetailModal = ({ data }) => {
    mediaDetailModalOptions.updatedData = data
    setMediaDetailModalOptions({ ...mediaDetailModalOptions })
  }

  const handleUpdateMedia = async ({ id, data, type }) => {
    try {
      setIsMediaUpdating(true)
      const response = await request({
        method: 'PATCH',
        body: data,
        path: `${ROUTE_MEDIA}/${id}`,
      })
      if (response) {
        const { error, id: media_gid, data } = response
        if (error) {
          Alert.error(error, { timeout: 5000 })
        } else {
          Alert.success(`Media has been updated.`, { timeout: 5000 })

          const keys = ['postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

          keys.forEach((key) => {
            if (post[key]) {
              for (let i = 0; i < post[key].length; i++) {
                if (post[key][i].id === media_gid) {
                  onChangeDispatchPost({
                    type: 'update_existing_media',
                    data: {
                      post_media_key: key,
                      media: { ...post[key][i], ...data, hasAspectRatioError: null },
                      selectedProfiles,
                    },
                  })
                  break
                }
              }
            }
          })

          handleClickCloseMediaMetaInformationModal()
          handleClickCloseImageEditModal()
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: UploadedMediasComponent.displayName, functionName: 'handleUpdateMedia' })
    } finally {
      setIsMediaUpdating(false)
    }
  }

  const handleClickOpenVideoThumbnailModal = ({ ...props }) => {
    setSelectedVideo({ ...props })
    setIsOpenVideoThumbnailModal(true)
  }

  const handleClickCloseVideoThumbnailModal = () => {
    setSelectedVideo({})
    setIsOpenVideoThumbnailModal(false)
  }

  const handleClickOpenVideoPickSoundModal = ({ ...props }) => {
    setSelectedVideo({ ...props })
    setIsOpenVideoPickSoundModal(true)
  }

  const handleClickCloseVideoPickSoundModal = () => {
    setSelectedVideo({})
    setIsOpenVideoPickSoundModal(false)
  }

  const handleUpdateVideoThumbnail = async ({ id, base64url, thumb_offset }) => {
    if (base64url) {
      try {
        setIsThumbnailUpdating(true)
        const response = await request({
          method: 'PATCH',
          body: { base64url, thumb_offset },
          path: `${ROUTE_MEDIA}/${id}/thumbnail`,
        })
        if (response) {
          const { error, id: media_gid, thumbnail_url, thumb_offset, url, updated_at_time } = response
          if (error) {
            Alert.error(error, { timeout: 5000 })
          } else {
            const keys = ['postVideos', 'postMixedMedias']

            keys.forEach((key) => {
              if (post[key]) {
                for (let i = 0; i < post[key].length; i++) {
                  if (post[key][i].id === media_gid) {
                    mediaProcessingIntervalsDispatch({ type: 'remove', id: media_gid })

                    onChangeDispatchPost({
                      type: 'update_existing_media',
                      data: {
                        post_media_key: key,
                        media: {
                          ...post[key][i],
                          thumbnail_url,
                          thumbnail_url_small: thumbnail_url,
                          thumb_offset,
                          url,
                          updated_at_time,
                        },
                        selectedProfiles,
                      },
                    })

                    break
                  }
                }
              }
            })

            handleClickCloseVideoThumbnailModal()
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: UploadedMediasComponent.displayName,
          functionName: 'handleUpdateVideoThumbnail',
        })
      } finally {
        setIsThumbnailUpdating(false)
      }
    } else {
      handleClickCloseVideoThumbnailModal()
    }
  }

  const handleUpdateVideoPickSound = ({ id, url, updated_at_time }) => {
    const keys = ['postVideos', 'postMixedMedias']

    keys.forEach((key) => {
      if (post[key]) {
        for (let i = 0; i < post[key].length; i++) {
          if (post[key][i].id === id) {
            mediaProcessingIntervalsDispatch({ type: 'remove', id })

            onChangeDispatchPost({
              type: 'update_existing_media',
              data: {
                post_media_key: key,
                media: {
                  ...post[key][i],
                  url,
                  updated_at_time,
                },
                selectedProfiles,
              },
            })
            break
          }
        }
      }
    })
  }

  const handleUpdateDynamicMedia = ({ media }) => {
    const keys = ['postImages', 'postVideos', 'postMixedMedias']

    let foundMedia = false

    keys.forEach((key) => {
      const foundIndex = post[key] ? post[key].findIndex((postMedia) => postMedia.media_gid === media.media_gid) : -1
      if (foundIndex > -1) {
        onChangeDispatchPost({
          type: 'update_existing_media',
          data: { post_media_key: key, media, selectedProfiles },
        })

        foundMedia = true
      }
    })

    if (!foundMedia) {
      let postAttachmentType
      if (postComponent === POST_IMAGE) {
        postAttachmentType = 'postImages'
      } else if (postComponent === POST_VIDEO) {
        postAttachmentType = 'postVideos'
      } else if (postComponent === POST_MIXED_MEDIA) {
        postAttachmentType = 'postMixedMedias'
      }

      onChangeDispatchPost({ type: 'add_media', data: { post_media_key: postAttachmentType, medias: [media] } })
    }

    handleClickCloseMediaDynamicModal()
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    let medias = []
    let post_media_key = ''

    if (postComponent === POST_IMAGE) {
      medias = postImages
      post_media_key = 'postImages'
    } else if (postComponent === POST_VIDEO) {
      medias = postVideos
      post_media_key = 'postVideos'
    } else if (postComponent === POST_DOCUMENT) {
      medias = postDocuments
      post_media_key = 'postDocuments'
    } else if (postComponent === POST_MIXED_MEDIA) {
      medias = postMixedMedias
      post_media_key = 'postMixedMedias'
    }

    const reordered_items = reorder(medias, result.source.index, result.destination.index)

    if (post_media_key) {
      onChangeDispatchPost({ type: 'rewrite_medias', data: { post_media_key, medias: reordered_items } })
    }
  }

  const handleClickCopyMediaLink = async ({ url }) => {
    await navigator.clipboard.writeText(url)

    Alert.success(`Media link has been copied`, { timeout: 5000 })
  }

  const handleClickDownloadMedia = async ({ id }) => {
    document.location = `/api/${ROUTE_MEDIA}/download/${id}`
  }

  const keys = [
    { key: 'postImages', postComponent: POST_IMAGE, uploadingTo: 'postMediaImage', title: 'Attached images' },
    { key: 'postVideos', postComponent: POST_VIDEO, uploadingTo: 'postMediaVideo', title: 'Attached videos' },
    {
      key: 'postDocuments',
      postComponent: POST_DOCUMENT,
      uploadingTo: 'postMediaDocument',
      title: 'Attached documents',
    },
    {
      key: 'postMixedMedias',
      postComponent: POST_MIXED_MEDIA,
      uploadingTo: 'postMediaMixedMedia',
      title: 'Mixed attachments',
      // warning: 'Please only use this option when attaching mixed types: images and videos.',
    },
  ]

  return (
    <Fragment>
      <Flex flexDirection="column" position="relative">
        {keys.map(({ key, warning, postComponent: keyPostComponent, uploadingTo, title }) => {
          if (!post[key] || post[key].length === 0) {
            return null
          }

          return (
            <SelectedMediaWrapper
              key={key}
              height={isSmall ? '114px' : '174px'}
              display={postComponent === keyPostComponent ? 'flex' : 'none'}
            >
              {!isSmall && (
                <Flex height="40px">
                  <Text mt="m">{title}</Text>
                </Flex>
              )}
              {warning && (
                <Flex pb="0px">
                  <Text fontSize="xs">{warning}</Text>
                </Flex>
              )}

              <Scrollbars
                universal
                renderTrackVertical={(props) => (
                  <div {...props} className="track-horizontal" style={{ display: 'none' }} />
                )}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />
                )}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={`droppable-${key}`} direction="horizontal">
                    {(provided) => (
                      <Flex {...provided.droppableProps} ref={provided.innerRef}>
                        {post[key].map((media, index) => {
                          const {
                            url,
                            thumbnail_url,
                            id,
                            tempId,
                            uploadingId,
                            isNew,
                            size,
                            type,
                            aspect_ratio,
                            duration,
                            thumb_offset,
                            width,
                            height,
                            extension,
                          } = media

                          let showProcessingClipLoader = false
                          let hasBeenProcessedWithoutErrors = true

                          if (mediaProcessingIntervals[id]) {
                            const { processed } = mediaProcessingIntervals[id]

                            if (typeof processed === 'undefined') {
                              showProcessingClipLoader = true
                            } else if (!processed) {
                              hasBeenProcessedWithoutErrors = false
                            }
                          }

                          return (
                            <Draggable key={id || tempId} draggableId={`draggable-${id || tempId}`} index={index}>
                              {(provided) => (
                                <PostMediaWrapper
                                  mt="m"
                                  mr="m"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  width={isSmall ? '80px' : '100px'}
                                  height={isSmall ? '80px' : '100px'}
                                >
                                  {isNew ? (
                                    <Flex
                                      alignItems="center"
                                      justifyContent="center"
                                      width={isSmall ? '80px' : '100px'}
                                      height={isSmall ? '80px' : '100px'}
                                      position="relative"
                                    >
                                      <ClipLoader size="50" color={colors.primary} />
                                      <StyledUploadProgressIndicatorText
                                        $isSmall={isSmall}
                                        id={`media-upload-progress-${tempId}`}
                                      />
                                    </Flex>
                                  ) : (
                                    <Fragment>
                                      <CloseIconWrapper
                                        onClick={() => {
                                          handleClickRemovePostMedia({ id, key })
                                        }}
                                      >
                                        <ImageComponent width="10px" height="10px" src="/assets/clear.svg" />
                                      </CloseIconWrapper>

                                      {showProcessingClipLoader && (
                                        <Flex
                                          alignItems="center"
                                          justifyContent="center"
                                          width={isSmall ? '80px' : '100px'}
                                          height={isSmall ? '80px' : '100px'}
                                          position="absolute"
                                          zIndex="1"
                                          bg={transparentize(0.7, COLOR_CONSTANTS.WHITE)}
                                        >
                                          <ClipLoader size={isSmall ? '40' : '50'} color={colors.primary} />
                                        </Flex>
                                      )}

                                      {type === IMAGE && (
                                        <ImageWithFallback
                                          source={thumbnail_url || url}
                                          fallbackSource={url}
                                          width={isSmall ? '80px' : '100px'}
                                          height={isSmall ? '80px' : '100px'}
                                          id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                          borderRadius={radius.l}
                                        />
                                      )}

                                      {type === IMAGE_DYNAMIC && (
                                        <Flex width={isSmall ? '80px' : '100px'} height={isSmall ? '80px' : '100px'}>
                                          <DynamicMedia media={media} post={post} profiles={selectedProfiles} isSmall />
                                        </Flex>
                                      )}

                                      {type === VIDEO && (
                                        <VideoWithFallback
                                          id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                          url={url}
                                          controls
                                          width={isSmall ? '80px' : '100px'}
                                          style={{
                                            position: 'relative',
                                            borderRadius: radius.l,
                                          }}
                                          height={isSmall ? '80px' : '100px'}
                                          fallbackSourceImage={thumbnail_url}
                                          config={{
                                            file: {
                                              attributes: {
                                                poster:
                                                  typeof isNew === 'undefined' && aspect_ratio && duration
                                                    ? thumbnail_url || ''
                                                    : '',
                                              },
                                            },
                                          }}
                                        />
                                      )}

                                      {type === VIDEO_DYNAMIC && (
                                        <Flex width={isSmall ? '80px' : '100px'} height={isSmall ? '80px' : '100px'}>
                                          <DynamicMedia media={media} post={post} profiles={selectedProfiles} isSmall />
                                        </Flex>
                                      )}

                                      {type === DOCUMENT && (
                                        <ImageWithFallback
                                          source={getFileIconByExtension({ extension })}
                                          fallbackSource="/assets/file.svg"
                                          width={isSmall ? '80px' : '100px'}
                                          height={isSmall ? '80px' : '100px'}
                                          id={`${POST_MEDIA_CLASS_NAME}-${id}`}
                                          borderRadius={radius.l}
                                        />
                                      )}

                                      <SelectWrapper alignItems="center" justifyContent="flex-end">
                                        <AttachmentsDropdownMenu
                                          WrapperComp={
                                            <ImageWithFallback
                                              width="100%"
                                              height="100%"
                                              source="/assets/vistasocial/more.svg"
                                            />
                                          }
                                        >
                                          <DropdownMenu.Item
                                            label="Settings"
                                            iconName="Gear"
                                            iconWidth="14px"
                                            onClick={() => {
                                              if (uploadingId) {
                                                post[key].forEach((media) => {
                                                  if (uploadingId) {
                                                    const { url, uploadingId } = media
                                                    media.tempId = uploadingId
                                                    uploadMediaComponentRef.current.uploadMediaToServerByStringData({
                                                      mediaURL: url,
                                                      uploadingId,
                                                      uploadingTo,
                                                      network,
                                                    })
                                                  }
                                                })

                                                onChangeDispatchPost({
                                                  type: 'rewrite_medias',
                                                  data: { post_media_key: key, medias: post[key] },
                                                })
                                              } else {
                                                handleClickOpenMediaMetaInformationModal({ media })
                                              }
                                            }}
                                            isCursorPointer
                                          />

                                          {(type === IMAGE || type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                                            <DropdownMenu.Item
                                              label="Edit media"
                                              iconName="VistaSocialPicture"
                                              onClick={() => {
                                                if (uploadingId) {
                                                  post[key].forEach((media) => {
                                                    if (uploadingId) {
                                                      const { url, uploadingId } = media
                                                      media.tempId = uploadingId
                                                      uploadMediaComponentRef.current.uploadMediaToServerByStringData({
                                                        mediaURL: url,
                                                        uploadingId,
                                                        uploadingTo,
                                                        network,
                                                      })
                                                    }
                                                  })

                                                  onChangeDispatchPost({
                                                    type: 'rewrite_medias',
                                                    data: { post_media_key: key, medias: post[key] },
                                                  })
                                                } else if (type === IMAGE) {
                                                  handleClickOpenImageEditModal({ id, url, size })
                                                } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
                                                  handleClickOpenMediaDynamicModal({ media })
                                                }
                                              }}
                                              isCursorPointer
                                            />
                                          )}

                                          {type === VIDEO && (
                                            <Fragment>
                                              <DropdownMenu.Item
                                                id="publish-modal-pick-thumbnail"
                                                label="Pick thumbnail"
                                                iconName="VistaSocialPicture"
                                                onClick={() => {
                                                  if (uploadingId) {
                                                    post[key].forEach((media) => {
                                                      if (uploadingId) {
                                                        const { url, uploadingId } = media
                                                        media.tempId = uploadingId

                                                        uploadMediaComponentRef.current.uploadMediaToServerByStringData(
                                                          {
                                                            mediaURL: url,
                                                            uploadingId,
                                                            uploadingTo,
                                                            network,
                                                          }
                                                        )
                                                      }
                                                    })

                                                    onChangeDispatchPost({
                                                      type: 'rewrite_medias',
                                                      data: { post_media_key: key, medias: post[key] },
                                                    })
                                                  } else {
                                                    handleClickOpenVideoThumbnailModal({
                                                      id,
                                                      url,
                                                      thumbnail_url,
                                                      thumb_offset,
                                                      width,
                                                      height,
                                                    })
                                                  }
                                                }}
                                                isCursorPointer
                                              />

                                              <DropdownMenu.Item
                                                id="publish-modal-pick-sound"
                                                label="Pick sound"
                                                iconWidth="14px"
                                                iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                                                iconName="Clavis"
                                                onClick={() => {
                                                  if (uploadingId) {
                                                    post[key].forEach((media) => {
                                                      if (uploadingId) {
                                                        const { url, uploadingId } = media
                                                        media.tempId = uploadingId

                                                        uploadMediaComponentRef.current.uploadMediaToServerByStringData(
                                                          {
                                                            mediaURL: url,
                                                            uploadingId,
                                                            uploadingTo,
                                                            network,
                                                          }
                                                        )
                                                      }
                                                    })

                                                    onChangeDispatchPost({
                                                      type: 'rewrite_medias',
                                                      data: { post_media_key: key, medias: post[key] },
                                                    })
                                                  } else {
                                                    handleClickOpenVideoPickSoundModal(media)
                                                  }
                                                }}
                                                isCursorPointer
                                              />
                                            </Fragment>
                                          )}

                                          {!uploadingId && (type === IMAGE || type === VIDEO || type === DOCUMENT) && (
                                            <Fragment>
                                              <Divider height="1px" />

                                              <DropdownMenu.Item
                                                label="Copy direct link"
                                                iconName="Reply"
                                                iconWidth="16px"
                                                iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                                                onClick={() => {
                                                  handleClickCopyMediaLink({ url })
                                                }}
                                                isCursorPointer
                                              />

                                              {!uploadingId && (
                                                <DropdownMenu.Item
                                                  label="Download"
                                                  iconName="Download"
                                                  onClick={() => {
                                                    handleClickDownloadMedia({ id })
                                                  }}
                                                  isCursorPointer
                                                />
                                              )}

                                              <DropdownMenu.Item
                                                label="View media"
                                                iconName="VistaSocialLandscape"
                                                iconWidth="16px"
                                                iconStroke={COLOR_CONSTANTS.COSMIC_ENERGY}
                                                onClick={() => {
                                                  handleClickOpenMediaDetailModal({ media })
                                                }}
                                                isCursorPointer
                                              />
                                            </Fragment>
                                          )}
                                        </AttachmentsDropdownMenu>
                                      </SelectWrapper>

                                      {!hasBeenProcessedWithoutErrors && (
                                        <StyledVideoProcessingErrorIconWrapper>
                                          <Flex alignItems="center">
                                            <Flex
                                              alignItems="center"
                                              onMouseEnter={(e) => {
                                                tooltipRef.current.handleShowTooltip({
                                                  contentComp: (
                                                    <Flex p="s" maxWidth="300px">
                                                      <Text fontSize="s" textAlign="center">
                                                        This video can't be processed. Click to learn more about video
                                                        processing.
                                                      </Text>
                                                    </Flex>
                                                  ),
                                                  wrapperComp: e.currentTarget,
                                                })
                                              }}
                                              onMouseLeave={() => {
                                                tooltipRef.current.handleHideTooltip()
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                window.open(
                                                  'https://support.vistasocial.com/hc/en-us/articles/10208587806363-Video-processing',
                                                  '_blank'
                                                )
                                              }}
                                            >
                                              <Icon.Warning fill={colors.error} width="10px" />
                                            </Flex>
                                          </Flex>
                                        </StyledVideoProcessingErrorIconWrapper>
                                      )}

                                      <StyledHiddenIconWrapper bottom={space.xxs} right={space.xxs}>
                                        <Icon.Drag fill={COLOR_CONSTANTS.WHITE} width="10px" height="10px" />
                                      </StyledHiddenIconWrapper>
                                    </Fragment>
                                  )}
                                </PostMediaWrapper>
                              )}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </DragDropContext>
              </Scrollbars>
            </SelectedMediaWrapper>
          )
        })}

        <CalendarItemTooltip ref={tooltipRef} />
      </Flex>

      {isOpenImageEditModal && (
        <ImageEditModal
          handleDismiss={handleClickCloseImageEditModal}
          handleUpdate={handleUpdateMedia}
          isOpen={isOpenImageEditModal}
          selectedImage={selectedImage}
          isImageUpdating={isMediaUpdating}
        />
      )}

      {isOpenVideoThumbnailModal && (
        <VideoThumbnailModal
          user={user}
          handleDismiss={handleClickCloseVideoThumbnailModal}
          handleUpdate={handleUpdateVideoThumbnail}
          isOpen={isOpenVideoThumbnailModal}
          selectedVideo={selectedVideo}
          isThumbnailUpdating={isThumbnailUpdating}
        />
      )}

      {isOpenVideoPickSoundModal && (
        <VideoPickSoundModal
          user={user}
          handleDismiss={handleClickCloseVideoPickSoundModal}
          isOpen={isOpenVideoPickSoundModal}
          selectedVideo={selectedVideo}
          handleUpdateMedia={handleUpdateVideoPickSound}
          featuresEnabled={featuresEnabled}
        />
      )}

      {mediaMetaInformationOptions.isOpen && (
        <MetaInformationModal
          selectedMedia={mediaMetaInformationOptions.media}
          isOpen={mediaMetaInformationOptions.isOpen}
          handleDismiss={handleClickCloseMediaMetaInformationModal}
          handleUpdate={handleUpdateMedia}
          entities={user.entities}
          isMediaUpdating={isMediaUpdating}
          postComponent={mediaMetaInformationOptions.postComponent}
        />
      )}

      {dynamicMediaOptions.isOpen && (
        <DynamicMediaModal
          user={user}
          isOpen={dynamicMediaOptions.isOpen}
          handleDismiss={handleClickCloseMediaDynamicModal}
          media={dynamicMediaOptions.media}
          post={post}
          handleUpdateDynamicMedia={handleUpdateDynamicMedia}
          selectedEntities={selectedEntities}
          selectedProfiles={selectedProfiles}
        />
      )}

      {mediaDetailModalOptions.isOpen && (
        <MediaDetailModal
          data={mediaDetailModalOptions.data}
          medias={[]}
          handleDismiss={handleClickCloseMediaDetailModal}
          isOpen={mediaDetailModalOptions.isOpen}
          confirm={() => {}}
          handleClickOpenImageEditModal={handleClickOpenImageEditModal}
          handleClickOpenVideoThumbnailModal={handleClickOpenVideoThumbnailModal}
          handleClickOpenVideoPickSoundModal={handleClickOpenVideoPickSoundModal}
          handleClickOpenMediaMetaInformationModal={handleClickOpenMediaMetaInformationModal}
          handleUpdateAudioCover={() => {}}
          imageExtensions={IMAGE_EXTENSIONS_JOINED}
          updatedData={mediaDetailModalOptions.updatedData}
          handleUpdateMediaInMediaDetailModal={handleUpdateMediaInMediaDetailModal}
          handleClickOpenMediaDynamicModal={handleClickOpenMediaDynamicModal}
        />
      )}
    </Fragment>
  )
}

UploadedMediasComponent.defaultProps = {
  specialPostKey: '',
  network: '',
  profileId: null,
  selectedProfiles: [],
  selectedEntities: [],
  isSmall: false,
}

UploadedMediasComponent.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  specialPostKey: PropTypes.string,
  network: PropTypes.string,
  profileId: PropTypes.number,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  uploadMediaComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  isSmall: PropTypes.bool,
}

UploadedMediasComponent.displayName = 'UploadedMediasComponent'

export default UploadedMediasComponent
