import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Box, Flex } from 'components/atoms/Layout'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { AUDIO, IMAGE, IMAGE_DYNAMIC, VIDEO, VIDEO_DYNAMIC, DOCUMENT } from 'consts'
import { getFileIconByExtension } from 'helpers'
import withConfirm from 'utils/withConfirm'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import AudioWithFallback from 'components/atoms/AudioWithFallback'
import { Text } from 'components/atoms/Typography'
import DynamicMedia from 'components/molecules/DynamicMedia'

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? colors.primary : transparentize(0.2, COLOR_CONSTANTS.SALT))};
  border: 2px solid ${({ selected }) => (selected ? colors.primary : COLOR_CONSTANTS.COSMIC_ENERGY)};
  &:hover {
    border-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const MediaWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) => selected && `transform: scale(0.8);`}
`

const StyledWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
  position: relative;
  border-radius: ${radius.l};
  cursor: pointer;
  position: relative;
  ${({ selected }) => selected && `box-shadow: 0px 0px 5px 0px rgb(145 147 169 / 50%);`}
`

const StyledImageWithFallback = styled(ImageWithFallback)`
  border-radius: ${radius.l};
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected }) => (selected ? colors.white : COLOR_CONSTANTS.COSMIC_ENERGY)};
`

const StyledPublicationsCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: ${space.xs};
  right: ${space.xs};
  z-index: 2;
  border-radius: ${radius.l};
  padding: ${space.xs} ${space.s};
  cursor: pointer;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const FavoriteItem = ({
  media,
  handleClickOpenMediaDetailModal,
  handleClickRemoverFromFavorite,
  handleClickSelectMedia,
  isSelectable,
  handleClickOpenPosts,
  tooltipRef,
}) => {
  const { type, url, thumbnail_url, thumbnail_url_small, selected, id, publications = [], extension } = media

  const publicationsCounter = publications ? publications.length : 0

  let mediaComponent

  if (type === IMAGE) {
    mediaComponent = (
      <StyledImageWithFallback
        name={id}
        width="100%"
        height="100%"
        source={thumbnail_url_small || thumbnail_url || url}
        fallbackSource={extension === 'gif' ? url : '/assets/landscape.svg'}
        fallbackSourceWidth={extension === 'gif' ? null : '50%'}
        fallbackSourceHeight={extension === 'gif' ? null : '50%'}
      />
    )
  } else if (type === VIDEO) {
    mediaComponent = (
      <VideoWithFallback
        url={thumbnail_url_small || thumbnail_url ? null : url}
        name={id}
        controls
        width="100%"
        style={{ position: 'relative', borderRadius: radius.l }}
        height="100%"
        config={{
          file: {
            attributes: {
              poster: thumbnail_url_small || thumbnail_url || '',
            },
          },
        }}
        fallbackSourceImage={thumbnail_url_small || thumbnail_url}
        playIcon={
          <Box position="absolute" top="0" right={space.s}>
            <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
          </Box>
        }
      />
    )
  } else if (type === AUDIO) {
    mediaComponent = (
      <AudioWithFallback
        url={null}
        controls
        width="100%"
        style={{ position: 'relative', borderTopLeftRadius: radius.l, borderTopRightRadius: radius.l }}
        height="100%"
        config={{
          file: {
            attributes: {
              poster: thumbnail_url_small || thumbnail_url || '',
            },
          },
        }}
        name={id}
        fallbackSourceImage={thumbnail_url_small || thumbnail_url}
        playIcon={
          <Box position="absolute" top="0" right={space.s}>
            <StyledPlayImage src="/assets/vistasocial/musical_note.svg" width="18px" height="18px" />
          </Box>
        }
      />
    )
  } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
    mediaComponent = <DynamicMedia media={media} isSmall showDynamicIcon />
  } else if (type === DOCUMENT) {
    mediaComponent = (
      <StyledImageWithFallback name={id} width="50%" height="50%" source={getFileIconByExtension({ extension })} />
    )
  }

  return (
    <StyledWrapper width="100%" height="100%" flexDirection="column">
      <MediaWrapper selected={selected} onClick={handleClickOpenMediaDetailModal}>
        {mediaComponent}
      </MediaWrapper>

      {isSelectable && (
        <Box
          position="absolute"
          top={space.xs}
          left={space.xs}
          zIndex="2"
          onClick={(event) => {
            event.stopPropagation()
            handleClickSelectMedia()
          }}
        >
          <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
            <StyledIconCheckmark selected={selected} width="10px" height="10px" />
          </SelectWrapper>
        </Box>
      )}

      {type !== AUDIO && publicationsCounter > 0 && (
        <StyledPublicationsCounterWrapper
          onClick={(event) => {
            event.stopPropagation()
            handleClickOpenPosts({ publications })
          }}
        >
          <Box>
            <Box
              onMouseEnter={(e) => {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Text p="s">
                      {publicationsCounter} {publicationsCounter === 1 ? 'post has' : 'posts have'} been created using
                      this media
                    </Text>
                  ),
                  wrapperComp: e.currentTarget,
                })
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
            >
              <Text fontSize="xxs" color={COLOR_CONSTANTS.WHITE}>
                {publicationsCounter > 99 ? '99+' : publicationsCounter}
              </Text>
            </Box>
          </Box>
        </StyledPublicationsCounterWrapper>
      )}

      {handleClickRemoverFromFavorite && (
        <CloseIconWrapper
          onClick={(event) => {
            event.stopPropagation()
            handleClickRemoverFromFavorite()
          }}
        >
          <Image width="8px" height="8px" src="/assets/clear.svg" />
        </CloseIconWrapper>
      )}
    </StyledWrapper>
  )
}

FavoriteItem.defaultProps = {
  handleClickRemoverFromFavorite: null,
}

FavoriteItem.propTypes = {
  media: PropTypes.object.isRequired,
  handleClickOpenMediaDetailModal: PropTypes.func.isRequired,
  handleClickRemoverFromFavorite: PropTypes.func,
  handleClickSelectMedia: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool.isRequired,
  handleClickOpenPosts: PropTypes.func.isRequired,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
}

export default withConfirm(FavoriteItem)
