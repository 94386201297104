import React, { Fragment } from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { DEFAULT_VIDEO_THUMBNAIL, IMAGE, IMAGE_DYNAMIC, VIDEO, VIDEO_DYNAMIC, FEATURE_POST_TAGGING } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import { PREVIEW_MEDIA_CLASS_NAME, PUBLISH_AS_STORY, PUBLISH_AS_REELS, PUBLISH_AS_CAROUSEL } from '../../consts'
// eslint-disable-next-line import/no-cycle
import { getInstagramMediaProportions, generateSyncMediaKeys } from '../../helpers'
import InstagramTagDropdown from './components/InstagramTagDropdown'

const StyledImageMentionWrapper = styled(Flex)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  color: ${COLOR_CONSTANTS.WHITE};
  box-sizing: border-box;
  border-radius: ${radius.m};
  height: 2.25em;
  transform: translate(-50%, 0%);
`

const StyledImageMention = styled(Text)`
  width: auto;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
`

const StyledImageMentionArrow = styled(Box)`
  border-color: transparent transparent rgba(0, 0, 0, 0.85);
  border-width: 0 0.375em 0.375em;
  top: -0.3125em;
  left: 50%;
  border-style: solid;
  height: 0;
  margin-left: -0.375em;
  position: absolute;
  width: 0;
`

const StyledIconWrapper = styled(Box)`
  position: absolute;
  background: ${({ hasData }) =>
    hasData ? transparentize(0.2, colors.black) : transparentize(0.2, colors.ternaryText)};
  border: 0;
  border-radius: 1.25em;
  cursor: pointer;
  margin: 0.75em;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  z-index: 10;
`

const StyledPersonImageWrapper = styled(Flex)`
  padding: 0.5em;
`

const StyledVideoMentionWrapper = styled(Flex)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  color: ${COLOR_CONSTANTS.WHITE};
  box-sizing: border-box;
  border-radius: 0.25em;
  padding: 0.5em;
  flex-direction: column;
  z-index: 10;
`

const StyledAddProductWrapper = styled(Text)`
  cursor: pointer;
  border-top: 1px solid ${COLOR_CONSTANTS.WHITE};
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const StyledFirstImage = styled(ImageWithFallback)`
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  max-width: 100%;
  max-height: 100%;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledDynamicMediaWrapper = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: ${radius.l};
`

const generateInstagramUserTags = ({
  user_tags,
  id: media_gid,
  imageWrapperRef,
  isEditable,
  handleDragStart,
  handleDragOverUserMention,
  setUserMentionModalOptions,
  handleClickDeleteUserTag,
}) => {
  return user_tags
    .filter((tag) => tag.media_gid === media_gid)
    .map(({ x, y, username }, index) => {
      if (imageWrapperRef && imageWrapperRef.current) {
        const { clientWidth, clientHeight } = imageWrapperRef.current
        const updatedX = clientWidth * x
        const updatedY = clientHeight * y

        return (
          <StyledImageMentionWrapper
            key={index}
            top={updatedY}
            left={updatedX}
            draggable="true"
            onDragStart={(target) => {
              handleDragStart({ target, media_gid, index })
            }}
            onDragEnd={(target) => {
              handleDragOverUserMention({ media_gid, index, target })
            }}
          >
            <Flex alignItems="center" justifyContent="space-between" position="relative" width="100%">
              <StyledImageMentionArrow />
              <StyledImageMention
                as="span"
                mx="0.5em"
                fontSize="0.875em"
                onClick={(e) => {
                  if (isEditable) {
                    e.stopPropagation()
                    setUserMentionModalOptions({
                      isOpen: true,
                      data: { x, y, username, media_gid, isForEdit: { username } },
                    })
                  }
                }}
              >
                {username}
              </StyledImageMention>

              {isEditable && (
                <Flex
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClickDeleteUserTag({ x, y, media_gid })
                  }}
                >
                  <Icon.Dismiss />
                </Flex>
              )}
            </Flex>
          </StyledImageMentionWrapper>
        )
      }
      return undefined
    })
}

const generateInstagramProductTags = ({
  product_tags,
  id: media_gid,
  imageWrapperRef,
  isEditable,
  handleDragStart,
  handleDragOverProductTag,
  setProductTagModalOptions,
  handleClickDeleteProductTag,
}) => {
  return product_tags
    .filter((tag) => tag.media_gid === media_gid)
    .map(({ x, y, product_id, product_name }, index) => {
      if (imageWrapperRef && imageWrapperRef.current) {
        const { clientWidth, clientHeight } = imageWrapperRef.current
        const updatedX = clientWidth * x
        const updatedY = clientHeight * y

        return (
          <StyledImageMentionWrapper
            key={index}
            top={updatedY}
            left={updatedX}
            draggable="true"
            onDragStart={(target) => {
              handleDragStart({ target, media_gid, index })
            }}
            onDragEnd={(target) => {
              handleDragOverProductTag({ media_gid, index, target })
            }}
          >
            <Flex alignItems="center" justifyContent="space-between" position="relative" width="100%">
              <StyledImageMentionArrow />
              <Flex alignItems="center">
                <StyledImageMention
                  as="span"
                  mx="0.5em"
                  fontSize="0.875em"
                  onClick={(e) => {
                    if (isEditable) {
                      e.stopPropagation()
                      setProductTagModalOptions({
                        isOpen: true,
                        data: {
                          x,
                          y,
                          product_id,
                          product_name,
                          media_gid,
                          isForEdit: { product_name, product_id },
                        },
                      })
                    }
                  }}
                >
                  {product_name}
                </StyledImageMention>
                <Image src="/assets/instagram_tagged_product.png" mr="0.25em" width="1em" height="1em" />
              </Flex>
              {isEditable && (
                <Flex
                  onClick={(e) => {
                    e.stopPropagation()
                    handleClickDeleteProductTag({ x, y, media_gid })
                  }}
                >
                  <Icon.Dismiss />
                </Flex>
              )}
            </Flex>
          </StyledImageMentionWrapper>
        )
      }
      return undefined
    })
}

const generateInstagramProductTagsVideo = ({
  tooltipRef,
  product_tags,
  id: media_gid,
  isEditable,
  showProductTags,
  setShowProductTags,
  setProductTagModalOptions,
  handleClickDeleteProductTag,
  handleClickAddProductTag,
  isProductTaggingAllowed,
}) => {
  return (
    <Fragment>
      {showProductTags && (
        <StyledVideoMentionWrapper bottom="3em" left="2.75em">
          {product_tags
            .filter((tag) => tag.media_gid === media_gid)
            .map(({ x, y, product_name, product_id }, index) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                position="relative"
                width="100%"
                mb="0.25em"
                key={index}
              >
                <Flex alignItems="center">
                  <StyledImageMention
                    as="span"
                    mr="0.25em"
                    fontSize="0.875em"
                    onClick={(e) => {
                      if (isEditable) {
                        e.stopPropagation()
                        setProductTagModalOptions({
                          isOpen: true,
                          data: {
                            x,
                            y,
                            product_name,
                            product_id,
                            media_gid,
                            isForEdit: { product_name, product_id },
                          },
                        })
                      }
                    }}
                  >
                    {product_name}
                  </StyledImageMention>
                  <Image src="/assets/instagram_tagged_product.png" mr="0.25em" width="1em" height="1em" />
                </Flex>
                {isEditable && (
                  <Flex
                    mt="-0.125em"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClickDeleteProductTag({ x, y, media_gid })
                    }}
                  >
                    <Icon.Dismiss />
                  </Flex>
                )}
              </Flex>
            ))}
          {isEditable && (
            <StyledAddProductWrapper
              pt="0.25em"
              fontSize="0.875em"
              onClick={(event) => {
                event.stopPropagation()
                handleClickAddProductTag({
                  x: 0,
                  y: 1,
                  mouseClickPositionX: 0,
                  mouseClickPositionY: 1,
                  media_gid,
                })
              }}
            >
              Tag a product
            </StyledAddProductWrapper>
          )}
        </StyledVideoMentionWrapper>
      )}
      <StyledIconWrapper hasData={product_tags.length !== 0} bottom="0" left="2.25em">
        {isEditable ? (
          <Flex alignItems="center">
            <StyledPersonImageWrapper
              alignItems="center"
              justifyContent="center"
              onClick={(e) => {
                e.stopPropagation()
                if (isProductTaggingAllowed) {
                  setShowProductTags(!showProductTags)
                } else {
                  Alert.warning(`Product tagging is only available for feed posts.`, { timeout: 5000 })
                  const el = document.getElementById('media-type-dropdown')
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }
                }
              }}
              onMouseEnter={(e) => {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Flex p="s">
                      <Text>
                        {isProductTaggingAllowed
                          ? 'Click here to tag a product'
                          : 'Product tagging is only available for feed posts.'}
                      </Text>
                    </Flex>
                  ),
                  wrapperComp: e.currentTarget,
                  defaultXPosition: 'right',
                })
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
            >
              <Image
                src="/assets/instagram_shop.svg"
                width="0.95em"
                height="0.95em"
                opacity={product_tags.length !== 0 ? 1 : 0.8}
              />
            </StyledPersonImageWrapper>
          </Flex>
        ) : (
          <StyledPersonImageWrapper
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              e.stopPropagation()
              setShowProductTags(!showProductTags)
            }}
          >
            <Image
              src="/assets/instagram_shop.svg"
              width="0.95em"
              height="0.95em"
              opacity={product_tags.length !== 0 ? 1 : 0.8}
            />
          </StyledPersonImageWrapper>
        )}
      </StyledIconWrapper>
    </Fragment>
  )
}

const generateInstagramUserTagsVideo = ({
  tooltipRef,
  user_tags,
  collaborator_tags,
  id: media_gid,
  isEditable,
  showUserTags,
  setShowUserTags,
  setUserTagModalOptions,
  handleClickDeleteUserTag,
  handleClickAddUserTag,
  isUserTaggingAllowed,
}) => {
  return (
    <Fragment>
      {showUserTags && (
        <StyledVideoMentionWrapper bottom="3em" left="0.5em">
          {user_tags
            .filter((tag) => tag.media_gid === media_gid)
            .map(({ x, y, username }, index) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                position="relative"
                width="100%"
                mb="xs"
                key={index}
              >
                <Flex alignItems="center">
                  <StyledImageMention
                    as="span"
                    mr="0.25em"
                    fontSize="0.875em"
                    onClick={(e) => {
                      if (isEditable) {
                        e.stopPropagation()
                        setUserTagModalOptions({
                          isOpen: true,
                          data: {
                            x,
                            y,
                            username,
                            media_gid,
                          },
                        })
                      }
                    }}
                  >
                    {username}
                  </StyledImageMention>
                  <Image src="/assets/instagram_person.svg" mr="0.25em" width="1em" height="1em" />
                </Flex>
                {isEditable && (
                  <Flex
                    mt="-0.125em"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClickDeleteUserTag({ x, y, media_gid })
                    }}
                  >
                    <Icon.Dismiss />
                  </Flex>
                )}
              </Flex>
            ))}
          {isEditable && (
            <StyledAddProductWrapper
              pt="0.25em"
              fontSize="0.875em"
              onClick={(event) => {
                event.stopPropagation()
                handleClickAddUserTag({
                  x: 0,
                  y: 1,
                  mouseClickPositionX: 0,
                  mouseClickPositionY: 1,
                  media_gid,
                })
              }}
            >
              Tag a user
            </StyledAddProductWrapper>
          )}
        </StyledVideoMentionWrapper>
      )}
      <StyledIconWrapper hasData={user_tags.length !== 0 || collaborator_tags.length !== 0} bottom="0" left="0">
        {isEditable ? (
          <Flex alignItems="center">
            <StyledPersonImageWrapper
              alignItems="center"
              justifyContent="center"
              onClick={(e) => {
                e.stopPropagation()
                if (isUserTaggingAllowed) {
                  setShowUserTags(!showUserTags)
                } else {
                  Alert.warning(`User tagging is only available for reels.`, { timeout: 5000 })
                  const el = document.getElementById('media-type-dropdown')
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                  }
                }
              }}
              onMouseEnter={(e) => {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Flex p="s">
                      <Text>
                        {isUserTaggingAllowed
                          ? 'Click here to tag a user'
                          : 'User tagging is only available for reels.'}
                      </Text>
                    </Flex>
                  ),
                  wrapperComp: e.currentTarget,
                  defaultXPosition: 'right',
                })
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
            >
              <Image
                src="/assets/instagram_person.svg"
                width="0.95em"
                height="0.95em"
                opacity={user_tags.length !== 0 ? 1 : 0.8}
              />
            </StyledPersonImageWrapper>
          </Flex>
        ) : (
          <StyledPersonImageWrapper
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              e.stopPropagation()
              setShowUserTags(!showUserTags)
            }}
          >
            <Image
              src="/assets/instagram_person.svg"
              width="0.95em"
              height="0.95em"
              opacity={user_tags.length !== 0 || collaborator_tags.length !== 0 ? 1 : 0.8}
            />
          </StyledPersonImageWrapper>
        )}
      </StyledIconWrapper>
    </Fragment>
  )
}

const generateInstagramCollaboratorTagsVideo = ({
  tooltipRef,
  collaborator_tags,
  id: media_gid,
  isEditable,
  handleClickAddCollaboratorTags,
}) => {
  return (
    <Fragment>
      <StyledIconWrapper hasData={collaborator_tags.length !== 0} bottom="0" left="4.5em">
        {isEditable && (
          <Flex alignItems="center">
            <StyledPersonImageWrapper
              alignItems="center"
              justifyContent="center"
              onClick={(e) => {
                e.stopPropagation()

                handleClickAddCollaboratorTags()
              }}
              onMouseEnter={(e) => {
                tooltipRef.current.handleShowTooltip({
                  contentComp: (
                    <Flex p="s">
                      <Text>Click here to invite collaborators</Text>
                    </Flex>
                  ),
                  wrapperComp: e.currentTarget,
                  defaultXPosition: 'right',
                })
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
            >
              <Image
                src="/assets/instagram_collaborators.svg"
                width="0.95em"
                height="0.95em"
                opacity={collaborator_tags.length !== 0 ? 1 : 0.8}
              />
            </StyledPersonImageWrapper>
          </Flex>
        )}
      </StyledIconWrapper>
    </Fragment>
  )
}

const generateInstagramUserTagIcon = ({
  tooltipRef,
  user_tags,
  collaborator_tags,
  isEditable,
  showImageMentions,
  setShowImageMentions,
}) => {
  return (
    <StyledIconWrapper hasData={user_tags.length !== 0 || collaborator_tags.length !== 0} bottom="0" left="0">
      {isEditable ? (
        <Flex alignItems="center">
          <StyledPersonImageWrapper
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              e.stopPropagation()
              setShowImageMentions(!showImageMentions)
            }}
            onMouseEnter={(e) => {
              tooltipRef.current.handleShowTooltip({
                contentComp: (
                  <Flex p="s">
                    <Text>Click anywhere on the photo to tag users</Text>
                  </Flex>
                ),
                wrapperComp: e.currentTarget,
                defaultXPosition: 'right',
              })
            }}
            onMouseLeave={() => {
              tooltipRef.current.handleHideTooltip()
            }}
          >
            <Image
              src="/assets/instagram_person.svg"
              width="0.95em"
              height="0.95em"
              opacity={user_tags.length !== 0 ? 1 : 0.8}
            />
          </StyledPersonImageWrapper>
        </Flex>
      ) : (
        <StyledPersonImageWrapper
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            e.stopPropagation()
            setShowImageMentions(!showImageMentions)
          }}
        >
          <Image
            src="/assets/instagram_person.svg"
            width="0.95em"
            height="0.95em"
            opacity={user_tags.length || collaborator_tags.length !== 0 ? 1 : 0.8}
          />
        </StyledPersonImageWrapper>
      )}
    </StyledIconWrapper>
  )
}

const generateInstagramProductTagIcon = ({
  tooltipRef,
  product_tags,
  isEditable,
  showProductTags,
  setShowProductTags,
}) => {
  return (
    <StyledIconWrapper hasData={product_tags.length !== 0} bottom="0" left="2.25em">
      {isEditable ? (
        <Flex alignItems="center">
          <StyledPersonImageWrapper
            alignItems="center"
            justifyContent="center"
            onClick={(e) => {
              e.stopPropagation()
              setShowProductTags(!showProductTags)
            }}
            onMouseEnter={(e) => {
              tooltipRef.current.handleShowTooltip({
                contentComp: (
                  <Flex p="s">
                    <Text>Click anywhere on the photo to tag a product</Text>
                  </Flex>
                ),
                wrapperComp: e.currentTarget,
                defaultXPosition: 'right',
              })
            }}
            onMouseLeave={() => {
              tooltipRef.current.handleHideTooltip()
            }}
          >
            <Image
              src="/assets/instagram_shop.svg"
              width="0.95em"
              height="0.95em"
              opacity={product_tags.length !== 0 ? 1 : 0.8}
            />
          </StyledPersonImageWrapper>
        </Flex>
      ) : (
        <StyledPersonImageWrapper
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            e.stopPropagation()
            setShowProductTags(!showProductTags)
          }}
        >
          <Image
            src="/assets/instagram_shop.svg"
            width="0.95em"
            height="0.95em"
            opacity={product_tags.length !== 0 ? 1 : 0.8}
          />
        </StyledPersonImageWrapper>
      )}
    </StyledIconWrapper>
  )
}

const generateInstagramCollaboratorsTagsIcon = ({ tooltipRef, collaborator_tags, isEditable }) => {
  return (
    <StyledIconWrapper hasData={collaborator_tags.length !== 0} bottom="0" left="4.5em">
      {isEditable ? (
        <Flex alignItems="center">
          <StyledPersonImageWrapper
            alignItems="center"
            justifyContent="center"
            onMouseEnter={(e) => {
              tooltipRef.current.handleShowTooltip({
                contentComp: (
                  <Flex p="s">
                    <Text>Click anywhere on the photo to invite collaborators</Text>
                  </Flex>
                ),
                wrapperComp: e.currentTarget,
                defaultXPosition: 'right',
              })
            }}
            onMouseLeave={() => {
              tooltipRef.current.handleHideTooltip()
            }}
          >
            <Image
              src="/assets/instagram_collaborators.svg"
              width="0.95em"
              height="0.95em"
              opacity={collaborator_tags.length !== 0 ? 1 : 0.8}
            />
          </StyledPersonImageWrapper>
        </Flex>
      ) : (
        <StyledPersonImageWrapper alignItems="center" justifyContent="center">
          <Image
            src="/assets/instagram_collaborators.svg"
            width="0.95em"
            height="0.95em"
            opacity={collaborator_tags.length !== 0 ? 1 : 0.8}
          />
        </StyledPersonImageWrapper>
      )}
    </StyledIconWrapper>
  )
}

export const generateInstagramMediaComponent = ({
  media,
  network,
  tooltipRef,
  post,
  profile,
  media_type,
  maxPreviewWidth: MAX_PREVIEW_WIDTH,
  maxPreviewHeight: MAX_PREVIEW_HEIGHT,
  maxAspectRatioNumber,
  minAspectRatioNumber,
  mediaProportionsType,
  isEditable,
  getMouseClickPosition,
  setMousePositionClick,
  noVideoAttachment,
  hasAutoChangedPostComponent,
  imageWrapperRef,
  showImageMentions,
  setShowImageMentions,
  showProductTags,
  setShowProductTags,
  user_tags,
  product_tags,
  collaborator_tags,
  handleDragStart,
  handleDragOverUserMention,
  setUserMentionModalOptions,
  handleClickDeleteUserTag,
  handleDragOverProductTag,
  setProductTagModalOptions,
  handleClickDeleteProductTag,
  mousePositionClick,
  handleClickAddProductTag,
  handleClickAddUserTag,
  handleClickAddCollaboratorTags,
  featuresEnabled,
  isProductTaggingAllowed,
  isUserTaggingAllowed,
  dynamicMediaIsSmall,
  carouselMediasAmount,
  videoProcessing,
  processedVideoEnding,
}) => {
  let mediaComponent = null
  let new_width
  let new_height
  let box_height = MAX_PREVIEW_HEIGHT
  let box_width = MAX_PREVIEW_WIDTH

  if (media) {
    const { url, id, [network]: networkErrors = {}, isNew, width, height, thumbnail_url, type } = media
    const { hasAspectRatioError, hasSizeError, hasDurationError, hasExtensionError } = networkErrors || {}

    const hasError = hasAspectRatioError || hasSizeError || hasDurationError || hasExtensionError

    ;({ new_width, new_height, box_height, box_width } = getInstagramMediaProportions({
      width,
      height,
      maxWidth: MAX_PREVIEW_WIDTH,
      maxHeight: MAX_PREVIEW_HEIGHT,
      maxAspectRatio: maxAspectRatioNumber,
      minAspectRatio: minAspectRatioNumber,
      type: mediaProportionsType,
      media_gid: id,
      post,
    }))

    if (media_type === PUBLISH_AS_STORY && width && height && width / height < 1) {
      box_height = MAX_PREVIEW_HEIGHT
      new_height = 0
    }

    if (media_type === PUBLISH_AS_REELS && width && height && width / height >= 1) {
      if (new_height > new_width) {
        new_height = new_width
      } else if (!videoProcessing) {
        box_height = MAX_PREVIEW_HEIGHT
      }
    }

    if (media_type === PUBLISH_AS_CAROUSEL && carouselMediasAmount > 1) {
      new_width = MAX_PREVIEW_WIDTH
      new_height = MAX_PREVIEW_HEIGHT
      box_height = MAX_PREVIEW_HEIGHT
      box_width = MAX_PREVIEW_WIDTH
    }

    const MAX_PREVIEW_DIMENSIONS_DYNAMIC = width && height && width >= height ? MAX_PREVIEW_WIDTH : MAX_PREVIEW_HEIGHT

    mediaComponent = (
      <StyledBox
        position="relative"
        ref={imageWrapperRef}
        width="100%"
        height="100%"
        justifyContent="center"
        hasError={hasError}
      >
        {isNew ? (
          <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
            <ClipLoader size="50" color={colors.primary} />
          </Flex>
        ) : (
          <Fragment>
            <Flex
              width={box_width ? `${box_width}px` : '100%'}
              height={box_height ? `${box_height}px` : '100%'}
              alignItems="center"
              justifyContent="center"
              position="relative"
              maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
              maxWidth={`${MAX_PREVIEW_WIDTH}px`}
            >
              {type === IMAGE && (
                <StyledFirstImage
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  src={url}
                  width={new_width ? `${new_width}px` : '100%'}
                  height={new_height ? `${new_height}px` : '100%'}
                  maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                  onClick={(event) => {
                    if (isEditable) {
                      const data = getMouseClickPosition({ event })

                      setMousePositionClick({ ...data, media_gid: id })
                    }
                  }}
                  name={id}
                />
              )}
              {type === IMAGE_DYNAMIC && (
                <StyledDynamicMediaWrapper
                  height={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                  width={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                  onClick={(event) => {
                    if (isEditable) {
                      const data = getMouseClickPosition({ event })

                      setMousePositionClick({ ...data, media_gid: id })
                    }
                  }}
                >
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={dynamicMediaIsSmall} />
                </StyledDynamicMediaWrapper>
              )}

              {type === VIDEO && (
                <Fragment>
                  {noVideoAttachment ? (
                    <Fragment>
                      <Box position="absolute" top={space.xs} right={space.xs}>
                        <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                      </Box>
                      <StyledVideoThumbnailImage
                        width={new_width ? `${new_width}px` : '100%'}
                        height={new_height ? `${new_height}px` : '100%'}
                        source={thumbnail_url || DEFAULT_VIDEO_THUMBNAIL}
                        fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                        name={id}
                        id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      {hasAutoChangedPostComponent ? (
                        <StyledFirstImage
                          id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                          src={url}
                          width={new_width ? `${new_width}px` : '100%'}
                          height={new_height ? `${new_height}px` : '100%'}
                          maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                          name={id}
                        />
                      ) : (
                        <VideoWithFallback
                          id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                          url={url}
                          controls
                          width={new_width ? `${new_width}px` : '100%'}
                          height={new_height ? `${new_height}px` : '100%'}
                          style={{
                            maxHeight: `${MAX_PREVIEW_HEIGHT - (hasError ? 6 : 0)}px`,
                            background: COLOR_CONSTANTS.BLACK,
                          }}
                          config={{
                            file: {
                              attributes: {
                                poster: typeof isNew === 'undefined' && thumbnail_url ? thumbnail_url : '',
                              },
                            },
                          }}
                          name={id}
                          playOnClickOnPreview
                          processedVideoEnding={processedVideoEnding}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}

              {type === VIDEO_DYNAMIC && (
                <StyledDynamicMediaWrapper
                  height={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                  width={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                >
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={dynamicMediaIsSmall} />
                </StyledDynamicMediaWrapper>
              )}
            </Flex>

            {(type === IMAGE || type === IMAGE_DYNAMIC) && (
              <Fragment>
                {showImageMentions && (
                  <Fragment>
                    {generateInstagramUserTags({
                      user_tags,
                      id,
                      imageWrapperRef,
                      isEditable,
                      handleDragStart,
                      handleDragOverUserMention,
                      setUserMentionModalOptions,
                      handleClickDeleteUserTag,
                    })}
                  </Fragment>
                )}
                {showProductTags && (
                  <Fragment>
                    {generateInstagramProductTags({
                      product_tags,
                      id,
                      imageWrapperRef,
                      isEditable,
                      handleDragStart,
                      handleDragOverProductTag,
                      setProductTagModalOptions,
                      handleClickDeleteProductTag,
                    })}
                  </Fragment>
                )}
                {(isEditable || user_tags.length !== 0 || collaborator_tags.length !== 0) && (
                  <Fragment>
                    {generateInstagramUserTagIcon({
                      tooltipRef,
                      user_tags,
                      collaborator_tags,
                      isEditable,
                      showImageMentions,
                      setShowImageMentions,
                    })}
                  </Fragment>
                )}
                {(isEditable || product_tags.length !== 0) && (
                  <Fragment>
                    {generateInstagramProductTagIcon({
                      tooltipRef,
                      product_tags,
                      isEditable,
                      showProductTags,
                      setShowProductTags,
                    })}
                  </Fragment>
                )}
                {isEditable && media_type !== PUBLISH_AS_STORY && (
                  <Fragment>
                    {generateInstagramCollaboratorsTagsIcon({
                      tooltipRef,
                      collaborator_tags,
                      isEditable,
                    })}
                  </Fragment>
                )}
                <InstagramTagDropdown
                  mousePositionClick={mousePositionClick}
                  setMousePositionClick={setMousePositionClick}
                  handleClickAddProductTag={handleClickAddProductTag}
                  handleClickAddUserTag={handleClickAddUserTag}
                  handleClickAddCollaboratorTags={handleClickAddCollaboratorTags}
                  isBlocked={featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled}
                  media_type={media_type}
                />
              </Fragment>
            )}

            {(type === VIDEO || type === VIDEO_DYNAMIC) &&
              media_type !== PUBLISH_AS_CAROUSEL &&
              media_type !== PUBLISH_AS_STORY && (
                <Fragment>
                  {(isEditable || product_tags.length !== 0) && (
                    <Fragment>
                      {generateInstagramProductTagsVideo({
                        tooltipRef,
                        product_tags,
                        id,
                        isEditable,
                        showProductTags,
                        setShowProductTags: (data) => {
                          setShowImageMentions(false)
                          setShowProductTags(data)
                        },
                        setProductTagModalOptions,
                        handleClickDeleteProductTag,
                        handleClickAddProductTag,
                        isProductTaggingAllowed,
                      })}
                    </Fragment>
                  )}

                  {(isEditable || user_tags.length !== 0) && (
                    <Fragment>
                      {generateInstagramUserTagsVideo({
                        tooltipRef,
                        user_tags,
                        collaborator_tags,
                        id,
                        isEditable,
                        showUserTags: showImageMentions,
                        setShowUserTags: (data) => {
                          setShowProductTags(false)
                          setShowImageMentions(data)
                        },
                        setUserTagModalOptions: setUserMentionModalOptions,
                        handleClickDeleteUserTag,
                        handleClickAddUserTag,
                        isUserTaggingAllowed,
                      })}
                    </Fragment>
                  )}

                  {(isEditable || collaborator_tags.length !== 0) && (
                    <Fragment>
                      {generateInstagramCollaboratorTagsVideo({
                        tooltipRef,
                        collaborator_tags,
                        id,
                        isEditable,
                        handleClickAddCollaboratorTags,
                      })}
                    </Fragment>
                  )}
                </Fragment>
              )}
          </Fragment>
        )}
      </StyledBox>
    )
  }

  return { mediaComponent, new_width, new_height, box_width, box_height }
}

export const generateFacebookMediaComponent = ({
  media,
  network,
  post,
  profile,
  countMediaProportions,
  maxPreviewWidth: MAX_PREVIEW_WIDTH,
  maxPreviewHeight: MAX_PREVIEW_HEIGHT,
  maxAspectRatioNumber,
  minAspectRatioNumber,
  noVideoAttachment,
  showImageAsVideoPreview,
  dynamicMediaIsSmall,
  imageStoryObjectFit,
  processedVideoEnding,
}) => {
  let mediaComponent = null
  let new_width
  let new_height
  let box_width = MAX_PREVIEW_WIDTH
  let box_height = MAX_PREVIEW_HEIGHT

  if (media) {
    const { url, id, [network]: networkErrors = {}, isNew, width, height, thumbnail_url, type } = media
    const { hasAspectRatioError, hasDurationError, hasExtensionError, hasSizeError } = networkErrors || {}

    const hasError = hasAspectRatioError || hasDurationError || hasExtensionError || hasSizeError

    if (countMediaProportions) {
      ;({ new_width, new_height, box_height, box_width } = getInstagramMediaProportions({
        width,
        height,
        maxWidth: MAX_PREVIEW_WIDTH,
        maxHeight: MAX_PREVIEW_HEIGHT,
        maxAspectRatio: maxAspectRatioNumber,
        minAspectRatio: minAspectRatioNumber,
        type,
        media_gid: id,
        post,
      }))
    }

    const MAX_PREVIEW_DIMENSIONS_DYNAMIC = width && height && width >= height ? MAX_PREVIEW_WIDTH : MAX_PREVIEW_HEIGHT

    mediaComponent = (
      <StyledBox
        position="relative"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        hasError={hasError}
      >
        {isNew ? (
          <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
            <ClipLoader size="50" color={colors.primary} />
          </Flex>
        ) : (
          <Fragment>
            <Flex
              width={box_width ? `${box_width}px` : '100%'}
              height={box_height ? `${box_height}px` : '100%'}
              alignItems="center"
              justifyContent="center"
              position="relative"
              maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
              maxWidth={`${MAX_PREVIEW_WIDTH}px`}
            >
              {type === IMAGE && !showImageAsVideoPreview && (
                <StyledFirstImage
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                  src={url}
                  width={new_width ? `${new_width}px` : '100%'}
                  height={new_height ? `${new_height}px` : '100%'}
                  maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                  name={id}
                  objectFit={imageStoryObjectFit}
                />
              )}

              {(type === VIDEO || showImageAsVideoPreview) && (
                <Fragment>
                  {noVideoAttachment || showImageAsVideoPreview ? (
                    <Fragment>
                      <Box position="absolute" top={space.xs} right={space.xs}>
                        <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                      </Box>
                      <StyledVideoThumbnailImage
                        width={new_width ? `${new_width}px` : '100%'}
                        height={new_height ? `${new_height}px` : '100%'}
                        source={thumbnail_url || DEFAULT_VIDEO_THUMBNAIL}
                        fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                        name={id}
                        id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <VideoWithFallback
                        id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${id}`}
                        url={url}
                        controls
                        width={new_width ? `${new_width}px` : '100%'}
                        height={new_height ? `${new_height}px` : '100%'}
                        style={{
                          maxHeight: `${new_height - (hasError ? 6 : 0)}px`,
                          maxWidth: `${new_width - (hasError ? 6 : 0)}px`,
                          background: COLOR_CONSTANTS.BLACK,
                        }}
                        config={{
                          file: {
                            attributes: {
                              poster: typeof isNew === 'undefined' && thumbnail_url ? thumbnail_url : '',
                            },
                          },
                        }}
                        name={id}
                        processedVideoEnding={processedVideoEnding}
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}

              {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                <StyledDynamicMediaWrapper
                  height={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                  width={`${MAX_PREVIEW_DIMENSIONS_DYNAMIC}px`}
                >
                  <DynamicMedia media={media} post={post} profiles={[profile]} isSmall={dynamicMediaIsSmall} />
                </StyledDynamicMediaWrapper>
              )}
            </Flex>
          </Fragment>
        )}
      </StyledBox>
    )
  }

  return { mediaComponent, new_width, new_height, box_width, box_height }
}
