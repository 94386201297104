import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import { formatToNumberWithCommas, linkTransformer, truncate } from 'helpers'
import { radius, COLOR_CONSTANTS, colors, fontWeights, space } from 'theme'
import { DEFAULT_VIDEO_THUMBNAIL, YOUTUBE, VIDEO, VIDEO_DYNAMIC, FEATURE_POST_ENGAGEMENT } from 'consts'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import HtmlCollapse from 'components/molecules/HtmlCollapse'
import Input from 'components/atoms/Input'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import Counter from 'components/molecules/Counter'
import Switch from 'components/atoms/Switch'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import Divider from 'components/atoms/Divider'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  NETWORK_LIMITS,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  YOUTUBE_COLORS,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
  PUBLISHED,
  SAVED_SEARCH_TYPE_YOUTUBE_TAGS,
  YOUTUBE_CATEGORIES_AND_PLAYLISTS,
  POST_TYPE_SOCIAL_POST,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import {
  getNetworkSavedSearches,
  setSavedSearches,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
  getYouTubeCategoriesAndPlaylists,
  mentionsTransformer,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const StyledFlexChannelWrapper = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.WAYWARD_WIND};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  margin: 0 auto;
`

const StyledFlexShortsWrapper = styled(Flex)`
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  margin: 0 auto;
  background-color: ${({ isPostFormDirty }) =>
    isPostFormDirty ? COLOR_CONSTANTS.BLACK : COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  cursor: pointer;
  overflow: hidden;
  height: ${({ isPostFormDirty, boxHeight }) => (isPostFormDirty ? boxHeight : 'fit-content')};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
  background-color: #e5e5e5 !important;
`

const SourceWrapper = styled(Box)`
  width: 100%;
  border-color: rgb(204, 214, 221);
  background-color: ${COLOR_CONSTANTS.BLACK};
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledControlWrapper = styled(Flex)`
  width: 3em;
  height: 3em;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1.5em;
  font-size: 1.125em;
  line-height: 3em;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${colors.primary};
  }
`

const QuestionMarkOutlined = styled(Icon.QuestionMarkOutlined)`
  fill: ${colors.secondaryText};
  cursor: pointer;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: cover;
`

const StyledIconLink = styled(Icon.Link)`
  fill: ${({ fill }) => fill || colors.primaryText};
`

const StyledCommentAuthor = styled(Text)`
  padding: 0 0.375em;
  background-color: #888888;
  color: ${colors.white};
  height: 1.25em;
  font-size: 0.8125em;
  font-weight: ${fontWeights.medium};
  border-radius: 0.75em;
  text-decoration: none;
  margin-top: 0.5em;
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledCommentWrapper = styled(Flex)`
  width: 100%;
  white-space: pre-wrap;
`

const StyledBadge = styled(Flex)`
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
  padding: 0 1em;
  height: 2.25em;
  font-size: 0.875.em;
  line-height: 2.25em;
  border-radius: 1.125em;
  align-items: center;
  justify-content: center;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
`

const StyledShortsRightColumnProfileImageWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 2.25em;
  height: 2.25em;
  border: 1px solid ${COLOR_CONSTANTS.WHITE};
  border-radius: 0.1875em;
`

const StyledShortsBottomColumnProfileImageWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.pill};
`

const StyledSwitcherWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  height: 2em;
  align-items: center;
  justify-content: center;
  background: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
  cursor: pointer;
  z-index: 6;
  box-shadow: 0px 4px 15px rgba(39, 40, 49, 0.1);
`

const { VIEW_CHANNEL, VIEW_SHORTS } = { VIEW_CHANNEL: 'channel', VIEW_SHORTS: 'shorts' }

const mapArrayToReactSelect = (arr) => arr.map(({ id, title }) => ({ value: id, label: title }))

const transformToReactSelectData = (data) => {
  if (data) {
    return { value: data.id, label: data.title }
  } else {
    return null
  }
}

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === YOUTUBE)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  videoTitleLimit: MAX_VIDEO_TITLE_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const PROCESSED_VIDEO_ENDING_SHORTS = '_facebook_reel'
const PROCESSED_VIDEO_ENDING_CHANNEL = '_twitter'

const YoutubePost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)
  const tagsRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [agencyMentions, setAgencyMentions] = useState([])
  const [view, setView] = useState(VIEW_SHORTS)
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_WIDTH_SHORTS = smallPreview ? 250 : 375
  const MAX_PREVIEW_HEIGHT_SHORTS = smallPreview ? 444.66 : 667

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
    if (isEditable) {
      getYouTubeCategoriesAndPlaylists({
        id: profile.profileId,
        type: YOUTUBE_CATEGORIES_AND_PLAYLISTS,
        dataForCustomizations,
        setDataForCustomizations,
      })

      getNetworkSavedSearches({
        id: profile.profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_YOUTUBE_TAGS,
      })
    }
  }, [props.profile])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile
  const {
    publish_at,
    postText,
    postComponent,
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
    insights = {},
    status,
    type: postType,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { url } = link || {}
  const { published_link } = result || {}

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let video_title
  let privacyStatus = 'public'
  let embeddable = true
  let notifySubscribers = true
  let madeForKids = false
  let comments = []
  let playlist = ''
  let category = ''
  let like = false
  let tags = []
  let device_gid = null
  let publishing_option = null
  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      video_title,
      privacyStatus = 'public',
      embeddable = true,
      notifySubscribers = true,
      madeForKids = false,
      comments = [],
      playlist = '',
      category = '',
      like = false,
      tags = [],
      device_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      commentTextMaxLimit,
      notificationDeviceLengthLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { typeData = {} } = networkDataForCustomizations || {}

  const { privacy: PRIVACY_STATUSES = [] } = dataForCustomizations[network] || {}

  const {
    [SAVED_SEARCH_TYPE_YOUTUBE_TAGS]: SAVED_TAGS_OPTIONS = [],
    [YOUTUBE_CATEGORIES_AND_PLAYLISTS]: { playlists = [], categories = [] } = {},
  } = typeData || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }
    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const {
    transformed_text_to_html_for_textarea,
    updatedPostTextWithMentions = '',
    transformed_text_to_html_for_shorts_preview,
  } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: YOUTUBE_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea =
      linkTransformer({
        body: updatedText,
        color: YOUTUBE_COLORS.LAST_LIGHT_BLUE,
        transformHashstag: true,
        hashtagColor: YOUTUBE_COLORS.LAST_LIGHT_BLUE,
      }) || ''

    const transformed_text_to_html_for_shorts_preview =
      linkTransformer({
        body: updatedText,
        color: COLOR_CONSTANTS.WHITE,
        transformHashstag: true,
        hashtagColor: COLOR_CONSTANTS.WHITE,
      }) || ''

    return {
      transformed_text_to_html_for_textarea,
      transformed_text_to_html_for_shorts_preview,
      updatedPostTextWithMentions,
    }
  }, [updatedPostText, savedVariables, agencyMentions])

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_views = 0
  let c_likes = 0
  let c_dislikes = 0
  let c_comments = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ views: c_views = 0, likes: c_likes = 0, comments: c_comments = 0, dislikes: c_dislikes = 0 } = profileInsights)
  }

  c_likes = status !== PUBLISHED && like ? c_likes + 1 : c_likes
  c_comments = c_comments || comments.filter(({ message }) => message).length

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (error) {
      urlHost = url
      errorHelper({
        error,
        componentName: YoutubePost.displayName,
        functionName: 'url',
        showAlert: false,
        sendError: false,
      })
    }
  }

  let updatedPostMedia = null

  if (customizedPostComponent === POST_VIDEO) {
    if (customizedPostVideos.length !== 0) {
      // eslint-disable-next-line prefer-destructuring
      updatedPostMedia = customizedPostVideos[0]
    }
  } else if (customizedPostComponent === POST_MIXED_MEDIA) {
    if (customizedPostMixedMedias.length !== 0) {
      updatedPostMedia = customizedPostMixedMedias.find(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
    }
  }

  const { url: videoUrl, thumbnail_url, isNew, id: videoId, title = '', type } = updatedPostMedia || {}

  let videoThumbnailUrl = ''
  if (typeof isNew === 'undefined' && thumbnail_url) {
    videoThumbnailUrl = thumbnail_url
  }
  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  let previewComponent = null

  const mediaComponent = (
    <Fragment>
      {videoUrl || thumbnail_url || type === VIDEO_DYNAMIC ? (
        <SourceWrapper height="100%">
          {noVideoAttachment || postType === POST_TYPE_SOCIAL_POST ? (
            <Flex justifyContent="center" alignItems="center" position="relative" height="100%" width="100%">
              {type === VIDEO && (
                <Fragment>
                  <Box position="absolute" top={space.xs} right={space.xs}>
                    <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                  </Box>
                  <StyledVideoThumbnailImage
                    height="100%"
                    width="100%"
                    // maxHeight="17.5em"
                    source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                    fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                    name={videoId}
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                  />
                </Fragment>
              )}
              {type === VIDEO_DYNAMIC && (
                <Flex width="13.183em" height="13.183em" justifyContent="center" alignItems="center" mx="auto">
                  <DynamicMedia
                    media={updatedPostMedia}
                    post={post}
                    profiles={[profile]}
                    verticalPreview={view === VIEW_SHORTS}
                    isSmall={smallPreview}
                  />
                </Flex>
              )}
            </Flex>
          ) : (
            <StyledBox position="relative" height="100%" width="100%" justifyContent="center" hasError={postVideoError}>
              {isNew ? (
                <ClipLoader size="50" color={colors.primary} />
              ) : (
                <Fragment>
                  {type === VIDEO && (
                    <VideoWithFallback
                      id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                      url={videoUrl}
                      controls
                      width={view === VIEW_CHANNEL ? '100%' : `${MAX_PREVIEW_WIDTH_SHORTS}px`}
                      style={{ position: 'relative' }}
                      height={view === VIEW_CHANNEL ? '13.183em' : `${MAX_PREVIEW_HEIGHT_SHORTS}px`}
                      config={{
                        file: {
                          attributes: {
                            poster: videoThumbnailUrl,
                          },
                        },
                      }}
                      name={videoId}
                      processedVideoEnding={
                        view === VIEW_SHORTS ? PROCESSED_VIDEO_ENDING_SHORTS : PROCESSED_VIDEO_ENDING_CHANNEL
                      }
                    />
                  )}
                  {type === VIDEO_DYNAMIC && (
                    <Flex
                      width={view === VIEW_CHANNEL ? '13.183em' : `${MAX_PREVIEW_WIDTH_SHORTS}px`}
                      height={view === VIEW_CHANNEL ? '13.183em' : `${MAX_PREVIEW_HEIGHT_SHORTS}px`}
                    >
                      <DynamicMedia
                        media={updatedPostMedia}
                        post={post}
                        profiles={[profile]}
                        verticalPreview
                        isSmall={smallPreview}
                      />
                    </Flex>
                  )}
                </Fragment>
              )}
            </StyledBox>
          )}
        </SourceWrapper>
      ) : (
        <Box px="s" />
      )}
    </Fragment>
  )

  if (view === VIEW_CHANNEL) {
    previewComponent = (
      <StyledFlexChannelWrapper
        minHeight="max-content"
        flexDirection="column"
        width="100%"
        isEditable={smallPreview}
        isPostFormDirty={isPostFormDirty}
      >
        {isPostFormDirty ? (
          <Fragment>
            {mediaComponent}
            <Flex px="0.5em" flexDirection="column" width="100%">
              {video_title && video_title.length !== 0 && (
                <Text mt="1em" fontSize="1.25em" color={YOUTUBE_COLORS.GLUON_GREY}>
                  {truncate(video_title, MAX_VIDEO_TITLE_LENGTH)}
                </Text>
              )}
              <Flex mt="0.25em" pb="1em" flexDirection="column">
                <Flex alignItems="center">
                  <Text fontSize="0.75em" color={YOUTUBE_COLORS.TARNISHED_SILVER}>
                    {`${formatToNumberWithCommas(c_views)} ${c_views === 1 ? 'view' : 'views'}`}
                  </Text>
                  <Text px="0.25em" fontSize="0.75em" color={YOUTUBE_COLORS.TARNISHED_SILVER}>
                    •
                  </Text>
                  <StyledLink
                    color={YOUTUBE_COLORS.TARNISHED_SILVER}
                    fontSize="0.75em"
                    as={published_link ? 'a' : 'p'}
                    href={published_link || ''}
                    target="_blank"
                  >
                    <Box as="span" display={{ mobile: 'none', mobileLarge: 'initial' }}>
                      {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'}
                    </Box>
                  </StyledLink>
                </Flex>
                {updatedPostTextWithMentions && (
                  <Flex mt="0.25em">
                    <HtmlCollapse
                      html={transformed_text_to_html_for_textarea}
                      maxLength={30}
                      color={YOUTUBE_COLORS.GLUON_GREY}
                      fontSize="0.75em"
                      whiteSpace="pre-line"
                      expandText="more"
                      collapseText="less"
                    />
                  </Flex>
                )}
              </Flex>

              <Flex>
                {isEditable ? (
                  <StyledImg
                    source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                    fallbackSource={fallbackLogoImage}
                    width="2.125em"
                    height="2.125em"
                  />
                ) : (
                  <SocialProfileImage
                    code={network}
                    picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                    width="2.125em"
                    height="2.125em"
                    imageBorderRadius={radius.pill}
                  />
                )}
                <Flex ml="0.5em" pl="0.25em" flexDirection="column" justifyContent="center">
                  <Flex
                    alignItems="center"
                    onMouseEnter={(e) => {
                      if (!isMultipleProfilesPreview) {
                        tooltipRef.current.handleShowTooltip({
                          contentComp: (
                            <ProfileItemTooltip
                              profile={{
                                code: network,
                                picture_url: profilePictureUrl,
                                profile_url: profileUrl,
                                name: profileName,
                                username: profileUsername,
                                display,
                                profile_id,
                                type: profile_type,
                              }}
                              message=""
                            />
                          ),
                          wrapperComp: e.currentTarget,
                        })
                      }
                    }}
                    onMouseLeave={() => {
                      tooltipRef.current.handleHideTooltip()
                    }}
                  >
                    <StyledLink
                      as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                      href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                      target="_blank"
                      color={YOUTUBE_COLORS.GLUON_GREY}
                      fontWeight="normal"
                      fontSize="0.875em"
                    >
                      {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                    </StyledLink>
                  </Flex>
                </Flex>
              </Flex>

              <Flex my="1em">
                <StyledBadge>
                  <Text>
                    <Image
                      width="1.5em"
                      height="1.5em"
                      src={like ? '/assets/youtube_like_filled.svg' : '/assets/youtube_like.svg'}
                      alt="Likes"
                      mr="s"
                      mt="-0.1875em"
                    />
                    <Text fontSize="0.875em" fontWeight="normal" as="span" color={YOUTUBE_COLORS.GLUON_GREY}>
                      {formatToNumberWithCommas(c_likes)}
                    </Text>
                  </Text>
                  <Flex alignItems="center" justifyContent="center" px="m" height="1.5em">
                    <Divider height="100%" width="1px" bg="rgba(0,0,0,0.1)" />
                  </Flex>

                  <Image width="1.5em" height="1.5em" src="/assets/youtube_dislike.svg" alt="Dislike" />
                </StyledBadge>
                <StyledBadge ml="0.5em">
                  <Text>
                    <Image width="1.5em" height="1.5em" src="/assets/youtube_share.svg" alt="Share" mr="s" mt="-3px" />
                    <Text
                      fontSize="0.875em"
                      height="0.875em"
                      fontWeight="normal"
                      as="span"
                      color={YOUTUBE_COLORS.GLUON_GREY}
                    >
                      Share
                    </Text>
                  </Text>
                </StyledBadge>
              </Flex>
            </Flex>
            {/* <Flex mx="m" flexDirection="column" mt="1em"> */}
            {/*  <StyledFlexWithBorder color={YOUTUBE_COLORS.GLUON_GREY} justifyContent="space-between"> */}
            {/*    <Flex alignItems="center" pb="m"> */}
            {/*      <Text fontSize="14px" color={YOUTUBE_COLORS.TARNISHED_SILVER}> */}
            {/*        {`${formatToNumberWithCommas(c_views)} ${c_views === 1 ? 'view' : 'views'}`} */}
            {/*      </Text> */}
            {/*      /!* <Text px="xs" fontSize="14px" color={YOUTUBE_COLORS.TARNISHED_SILVER}> *!/ */}
            {/*      /!*  • *!/ */}
            {/*      /!* </Text> *!/ */}
            {/*      /!* <StyledLink *!/ */}
            {/*      /!*  color={YOUTUBE_COLORS.TARNISHED_SILVER} *!/ */}
            {/*      /!*  fontSize="14px" *!/ */}
            {/*      /!*  as={published_link ? 'a' : 'p'} *!/ */}
            {/*      /!*  href={published_link || ''} *!/ */}
            {/*      /!*  target="_blank" *!/ */}
            {/*      /!* > *!/ */}
            {/*      /!*  <Box as="span" display={{ mobile: 'none', mobileLarge: 'initial' }}> *!/ */}
            {/*      /!*    {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A'')} ${zoneShort || ''}` : 'Now'} *!/ */}
            {/*      /!*  </Box> *!/ */}
            {/*      /!* </StyledLink> *!/ */}
            {/*    </Flex> */}
            {/*    <Flex alignItems="center" pb="m"> */}
            {/*      <Text mr="s" pr="xs"> */}
            {/*        <Image */}
            {/*          width="24px" */}
            {/*          height="24px" */}
            {/*          src={like ? '/assets/youtube_like_filled.svg' : '/assets/youtube_like.svg'} */}
            {/*          alt="Likes" */}
            {/*          mr="xs" */}
            {/*          mt="-3px" */}
            {/*        /> */}
            {/*        <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}> */}
            {/*          {formatToNumberWithCommas(c_likes)} */}
            {/*        </Text> */}
            {/*      </Text> */}
            {/*      <Text mr="s" pr="xs"> */}
            {/*        <Image width="24px" height="24px" src="/assets/youtube_dislike.svg" alt="Dislike" mr="xs" /> */}
            {/*        <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}> */}
            {/*          DISLIKE */}
            {/*        </Text> */}
            {/*      </Text> */}
            {/*      <Text mr="s" pr="xs"> */}
            {/*        <Image width="24px" height="24px" src="/assets/youtube_share.svg" alt="Share" mr="xs" /> */}
            {/*        <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}> */}
            {/*          SHARE */}
            {/*        </Text> */}
            {/*      </Text> */}
            {/*      /!*<Text mr="s" pr="xs">*!/ */}
            {/*      /!*  <Image width="24px" height="24px" src="/assets/youtube_clip.svg" alt="Clip" mr="xs" />*!/ */}
            {/*      /!*  <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}>*!/ */}
            {/*      /!*    CLIP*!/ */}
            {/*      /!*  </Text>*!/ */}
            {/*      /!*</Text>*!/ */}
            {/*      /!*<Text>*!/ */}
            {/*      /!*  <Image width="24px" height="24px" src="/assets/youtube_save.svg" alt="Save" mr="xs" />*!/ */}
            {/*      /!*  <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}>*!/ */}
            {/*      /!*    SAVE*!/ */}
            {/*      /!*  </Text>*!/ */}
            {/*      /!*</Text>*!/ */}
            {/*    </Flex> */}
            {/*  </StyledFlexWithBorder> */}
            {/*  <StyledFlexWithBorder */}
            {/*    flexDirection="column" */}
            {/*    width="100%" */}
            {/*    onClick={() => { */}
            {/*      if (published_link) { */}
            {/*        window.open(published_link) */}
            {/*      } */}
            {/*    }} */}
            {/*    mt="m" */}
            {/*  > */}
            {/*    <Flex> */}
            {/*      {isEditable ? ( */}
            {/*        <StyledImg */}
            {/*          source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl} */}
            {/*          fallbackSource={fallbackLogoImage} */}
            {/*          width={{ mobile: '20px', tablet: '48px' }} */}
            {/*          height={{ mobile: '20px', tablet: '48px' }} */}
            {/*        /> */}
            {/*      ) : ( */}
            {/*        <SocialProfileImage */}
            {/*          code={network} */}
            {/*          picture_url={isMultipleProfilesPreview ? null : profilePictureUrl} */}
            {/*          width={{ mobile: '20px', tablet: '48px' }} */}
            {/*          height={{ mobile: '20px', tablet: '48px' }} */}
            {/*          imageBorderRadius={radius.pill} */}
            {/*        /> */}
            {/*      )} */}
            {/*      <Flex ml="s" pl="xs" flexDirection="column" justifyContent="center"> */}
            {/*        <Flex */}
            {/*          alignItems="center" */}
            {/*          onMouseEnter={(e) => { */}
            {/*            if (!isMultipleProfilesPreview) { */}
            {/*              tooltipRef.current.handleShowTooltip({ */}
            {/*                contentComp: ( */}
            {/*                  <ProfileItemTooltip */}
            {/*                    profile={{ */}
            {/*                      code: network, */}
            {/*                      picture_url: profilePictureUrl, */}
            {/*                      profile_url: profileUrl, */}
            {/*                      name: profileName, */}
            {/*                      username: profileUsername, */}
            {/*                      display, */}
            {/*                      profile_id, */}
            {/*                      type: profile_type, */}
            {/*                    }} */}
            {/*                    message="" */}
            {/*                  /> */}
            {/*                ), */}
            {/*                wrapperComp: e.currentTarget, */}
            {/*              }) */}
            {/*            } */}
            {/*          }} */}
            {/*          onMouseLeave={() => { */}
            {/*            tooltipRef.current.handleHideTooltip() */}
            {/*          }} */}
            {/*        > */}
            {/*          <StyledLink */}
            {/*            as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'} */}
            {/*            href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''} */}
            {/*            target="_blank" */}
            {/*            color={YOUTUBE_COLORS.GLUON_GREY} */}
            {/*            fontWeight="bold" */}
            {/*            fontSize="s" */}
            {/*          > */}
            {/*            {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName} */}
            {/*          </StyledLink> */}
            {/*        </Flex> */}
            {/*      </Flex> */}
            {/*    </Flex> */}
            {/*    <Box ml={{ mobile: '20px', tablet: '48px' }}> */}
            {/*      {isPostTextEditOpen ? ( */}
            {/*        <BodyWrapper> */}
            {/*          <Box position="absolute" right="0" top="-6px" zIndex="2"> */}
            {/*            <Button.Gray isSmall onClick={() => setIsPostTextEditOpen(false)}> */}
            {/*              Preview */}
            {/*            </Button.Gray> */}
            {/*          </Box> */}
            {/*          <Flex flexDirection="column"> */}
            {/*            <TextAreaComponentWithControls */}
            {/*              user={user} */}
            {/*              handleUpdateText={(text) => { */}
            {/*                handleUpdateCustomizationDebounce({ type: 'message', value: text }) */}
            {/*              }} */}
            {/*              text={updatedPostText} */}
            {/*              textMaxLimitNumber={MAX_TEXT_LENGTH} */}
            {/*              textVariantOffsets={textVariantOffsets} */}
            {/*              textareaPlaceholder="Write your content ..." */}
            {/*              errorText="" */}
            {/*              selectedEntities={selectedEntities} */}
            {/*selectedProfiles={selectedProfiles}*/}
            {/*              counterComp={<Counter counter={updatedPostTextWithMentions.length} max={MAX_TEXT_LENGTH} />} */}
            {/*            /> */}
            {/*          </Flex> */}
            {/*        </BodyWrapper> */}
            {/*      ) : ( */}
            {/*        <BodyWrapper */}
            {/*          isEditAllowed={isEditable} */}
            {/*          onClick={() => { */}
            {/*            if (isEditable) { */}
            {/*              setIsPostTextEditOpen(true) */}
            {/*            } */}
            {/*          }} */}
            {/*        > */}
            {/*          {isEditable && ( */}
            {/*            <StyledEditIconWrapper> */}
            {/*              <Icon.Edit width="14px" height="14px" fill={COLOR_CONSTANTS.GLACIER_PEARL} /> */}
            {/*            </StyledEditIconWrapper> */}
            {/*          )} */}
            {/*          <HtmlCollapse */}
            {/*            html={ */}
            {/*              linkTransformer({ */}
            {/*                body: hashtagTransformerWithoutLinking({ */}
            {/*                  body: updatedPostTextWithMentions, */}
            {/*                  color: YOUTUBE_COLORS.LAST_LIGHT_BLUE, */}
            {/*                }), */}
            {/*                color: YOUTUBE_COLORS.LAST_LIGHT_BLUE, */}
            {/*                isClickable: !isEditable, */}
            {/*              }) || `${isEditable ? 'Write your content ...' : ''}` */}
            {/*            } */}
            {/*            maxLength={150} */}
            {/*            color={YOUTUBE_COLORS.GLUON_GREY} */}
            {/*            mt="s" */}
            {/*            ml="s" */}
            {/*            pb="m" */}
            {/*            fontSize="s" */}
            {/*            whiteSpace="pre-line" */}
            {/*          /> */}
            {/*        </BodyWrapper> */}
            {/*      )} */}
            {/*      {isEditable && !isPostTextEditOpen && ( */}
            {/*        <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} /> */}
            {/*      )} */}
            {/*    </Box> */}

            {/*    {customizedPostComponent === POST_LINK && urlHost && ( */}
            {/*      <Flex mb="s" alignItems="center"> */}
            {/*        <StyledIconLink width="12px" height="12px" fill={YOUTUBE_COLORS.LAST_LIGHT_BLUE} /> */}
            {/*        <Link */}
            {/*          ml="xs" */}
            {/*          as="a" */}
            {/*          href={url} */}
            {/*          target="_blank" */}
            {/*          rel="noreferrer noopener" */}
            {/*          color={YOUTUBE_COLORS.LAST_LIGHT_BLUE} */}
            {/*        > */}
            {/*          {urlHost} */}
            {/*        </Link> */}
            {/*      </Flex> */}
            {/*    )} */}
            {/*  </StyledFlexWithBorder> */}
            {/*  <Flex alignItems="center" py="m" mt="s"> */}
            {/*    <Text fontSize="m" color={YOUTUBE_COLORS.GLUON_GREY}> */}
            {/*      {`${formatToNumberWithCommas(c_comments)} ${c_comments === 1 ? 'Comment' : 'Comments'}`} */}
            {/*    </Text> */}
            {/*    <Text ml="l" mr="s" pr="xs"> */}
            {/*      <Image width="24px" height="24px" src="/assets/youtube_sort.svg" alt="Sort" mr="s" /> */}
            {/*      <Text fontSize="s" fontWeight="medium" as="span" color={YOUTUBE_COLORS.GLUON_GREY}> */}
            {/*        SORT BY */}
            {/*      </Text> */}
            {/*    </Text> */}
            {/*  </Flex> */}

            <Flex flexDirection="column">
              {comments.map(({ message }, index) => {
                if (!message) {
                  return null
                }

                const { variableTransformedText } = variableTransformer({
                  str: message,
                  savedVariables,
                  post,
                  profileId,
                })
                return (
                  <StyledCommentWrapper mt="1em" px="0.5em" pb="0.5em" key={index}>
                    {isEditable ? (
                      <StyledImg
                        source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                        fallbackSource={fallbackLogoImage}
                        width={{ mobile: '1em', tablet: '2.5em' }}
                        height={{ mobile: '1em', tablet: '2.5em' }}
                      />
                    ) : (
                      <SocialProfileImage
                        code={network}
                        picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                        width={{ mobile: '1em', tablet: '2.5em' }}
                        height={{ mobile: '1em', tablet: '2.5em' }}
                        imageBorderRadius={radius.pill}
                      />
                    )}
                    <Flex ml="0.5em" pl="0.25em" flexDirection="column" justifyContent="center">
                      <Flex alignItems="center">
                        <Image width="0.75em" height="0.75em" src="/assets/youtube_pin.svg" alt="Pin" mr="0.25em" />
                        <Text fontSize="0.75em" color={YOUTUBE_COLORS.TARNISHED_SILVER}>
                          Pinned by {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                        </Text>
                      </Flex>
                      <Flex>
                        <StyledCommentAuthor
                          as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                          href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                          target="_blank"
                          color={YOUTUBE_COLORS.GLUON_GREY}
                          fontWeight="bold"
                          fontSize="0.875em"
                        >
                          {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                        </StyledCommentAuthor>
                      </Flex>
                      <Text
                        mt="0.25em"
                        color={YOUTUBE_COLORS.GLUON_GREY}
                        fontSize="0.875em"
                        dangerouslySetInnerHTML={{
                          __html: variableTransformedText,
                        }}
                      />
                      <Flex height="2.125em" mt="0.5em" alignItems="center">
                        <Image
                          width="1em"
                          height="1em"
                          src="/assets/youtube_like.svg"
                          alt="Likes"
                          mr="0.5em"
                          mt="-0.1875em"
                        />
                        <Text fontSize="0.875em">
                          <Image width="1em" height="1em" src="/assets/youtube_dislike.svg" alt="Reply" mr="1em" />
                          <Text fontSize="0.75em" fontWeight="medium" as="span" color={YOUTUBE_COLORS.TARNISHED_SILVER}>
                            REPLY
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>
                  </StyledCommentWrapper>
                )
              })}
            </Flex>
            {/* </Flex> */}
          </Fragment>
        ) : (
          <Box mt="m" px="s" mb="s" height="100%">
            <NoPost />
          </Box>
        )}
      </StyledFlexChannelWrapper>
    )
  } else if (view === VIEW_SHORTS) {
    previewComponent = (
      <StyledFlexShortsWrapper
        isEditable={smallPreview}
        width={`${MAX_PREVIEW_WIDTH_SHORTS}px`}
        boxHeight={`${MAX_PREVIEW_HEIGHT_SHORTS}px`}
        isPostFormDirty={isPostFormDirty}
      >
        <Flex pb="m" width="100%" justifyContent="center">
          {isPostFormDirty ? (
            <Flex flexDirection="column" height="100%">
              {mediaComponent}
              <Flex position="absolute" top="0.75em" left="0.75em" width="3em" zIndex="6" flexDirection="column">
                <Image src="/assets/youtube_icon_shorts.svg" width="5.5625em" height="1.25em" />
              </Flex>
              <Flex
                position="absolute"
                bottom="1.5em"
                right="0.5em"
                width="3em"
                zIndex="6"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Flex flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/youtube_like_shorts.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <Text my="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      {c_likes}
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/youtube_dislike_shorts.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <Text my="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      Dislike
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/youtube_comment_shorts.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <Text my="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      {c_comments}
                    </Text>
                  </Flex>
                  <Flex flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/youtube_share_shorts.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <Text my="0.25em" color={COLOR_CONSTANTS.WHITE} fontSize="0.75em">
                      Share
                    </Text>
                  </Flex>
                  <Flex mb="0.25em" flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/youtube_more_shorts.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                  </Flex>

                  <StyledShortsRightColumnProfileImageWrapper mt="1.5em">
                    <ImageWithFallback
                      source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage}
                      fallbackSource={fallbackLogoImage}
                      borderRadius="0.1875em"
                      width="2.25em"
                      height="2.25em"
                    />
                  </StyledShortsRightColumnProfileImageWrapper>
                </Flex>
              </Flex>
              <Flex position="absolute" bottom="1em" left="1em" zIndex="5" flexDirection="column" flex="1 1 0%;">
                <BodyWrapper>
                  <HtmlCollapse
                    html={video_title}
                    textColor={COLOR_CONSTANTS.WHITE}
                    fontSize="0.9375em"
                    expandText="more"
                    collapseText="less"
                    color={COLOR_CONSTANTS.WHITE}
                  />
                </BodyWrapper>
                <Flex alignItems="center">
                  <Flex alignItems="center">
                    <Flex
                      height="3em"
                      alignItems="center"
                      onMouseEnter={(e) => {
                        if (!isMultipleProfilesPreview) {
                          tooltipRef.current.handleShowTooltip({
                            contentComp: (
                              <ProfileItemTooltip
                                profile={{
                                  code: network,
                                  picture_url: profilePictureUrl,
                                  profile_url: profileUrl,
                                  name: profileName,
                                  username: profileUsername,
                                  display,
                                  profile_id,
                                  type: profile_type,
                                }}
                                message=""
                              />
                            ),
                            wrapperComp: e.currentTarget,
                          })
                        }
                      }}
                      onMouseLeave={() => {
                        tooltipRef.current.handleHideTooltip()
                      }}
                    >
                      <StyledShortsBottomColumnProfileImageWrapper>
                        <ImageWithFallback
                          source={
                            isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage
                          }
                          fallbackSource={fallbackLogoImage}
                          borderRadius={radius.pill}
                          width="1.5em"
                          height="1.5em"
                        />
                      </StyledShortsBottomColumnProfileImageWrapper>
                      {!isMultipleProfilesPreview && (
                        <StyledLink
                          ml="0.5em"
                          as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                          href={(!isMultipleProfilesPreview && profileUrl) || ''}
                          target="_blank"
                          color={COLOR_CONSTANTS.WHITE}
                          fontSize="0.75em"
                        >
                          {isMultipleProfilesPreview ? 'Multiple YouTube Profiles' : profileUsername || profileName}
                        </StyledLink>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <Box px="s" height="100%">
              <NoPost />
            </Box>
          )}
        </Flex>
      </StyledFlexShortsWrapper>
    )
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px auto`
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={customizedPostMixedMedias}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height={smallPreview ? '100%' : 'auto'}>
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL : COLOR_CONSTANTS.WHITE}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              {isEditable && isPostFormDirty && (
                <Tooltip
                  wrapperComp={
                    <StyledSwitcherWrapper
                      onClick={() => {
                        if (view === VIEW_CHANNEL) {
                          setView(VIEW_SHORTS)
                        } else {
                          setView(VIEW_CHANNEL)
                        }
                      }}
                    >
                      {view === VIEW_CHANNEL ? (
                        <Image src="/assets/youtube_disabled.svg" width="1.5em" height="1.5em" />
                      ) : (
                        <Image src="/assets/youtube_shorts_disabled.svg" width="1.5em" height="1.5em" />
                      )}
                    </StyledSwitcherWrapper>
                  }
                  defaultTopOffset={-75}
                  message={view === VIEW_SHORTS ? 'Preview as Video' : 'Preview as Short'}
                  isTriangleVisible={false}
                  left="initial"
                  right="0"
                />
              )}
              {previewComponent}
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  <Flex flexDirection="column">
                    {hasCustomCaption && (
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          {isPostTextEditOpen ? (
                            <BodyWrapper>
                              <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                                <Image width="8px" height="8px" src="/assets/clear.svg" />
                              </CloseIconWrapper>
                              <Flex flexDirection="column" width="100%">
                                <TextAreaComponentWithControls
                                  user={user}
                                  handleUpdateText={(text) => {
                                    if (text === postText) {
                                      handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                    } else {
                                      handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                    }
                                  }}
                                  text={updatedPostText}
                                  textMaxLimitNumber={MAX_TEXT_LENGTH}
                                  textVariantOffsets={textVariantOffsets}
                                  textareaPlaceholder="Write your content ..."
                                  errorText=""
                                  selectedEntities={selectedEntities}
                                  selectedProfiles={selectedProfiles}
                                  counterComp={
                                    <Counter
                                      counter={updatedPostTextWithMentions.length}
                                      max={MAX_TEXT_LENGTH}
                                      fontSize="xxs"
                                    />
                                  }
                                />
                              </Flex>
                            </BodyWrapper>
                          ) : (
                            <StyledCaptionWrapper
                              onClick={() => {
                                setIsPostTextEditOpen(true)
                              }}
                            >
                              <BodyWrapper>
                                <Text
                                  dangerouslySetInnerHTML={{
                                    __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                  }}
                                  maxLength={150}
                                  fontSize="xs"
                                  whiteSpace="pre-line"
                                  color={
                                    transformed_text_to_html_for_textarea
                                      ? COLOR_CONSTANTS.DENIM
                                      : COLOR_CONSTANTS.COSMIC_ENERGY
                                  }
                                />
                              </BodyWrapper>
                              <Box>
                                <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                              </Box>
                            </StyledCaptionWrapper>
                          )}
                        </Flex>
                        <TooltipPromptComponent prompt_key="caption" network={YOUTUBE} />
                      </Flex>
                    )}

                    {hasCustomMedias && (
                      <Fragment>
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <CustomizationsMediaComponent
                              uploadMediaComponentRef={uploadMediaComponentRef}
                              user={user}
                              network={network}
                              profileId={profileId}
                              selectedProfiles={selectedProfiles}
                              selectedEntities={selectedEntities}
                              customized_post_data={{
                                postComponent: customizedPostComponent,
                                postVideos: customizedPostVideos,
                                postMixedMedias: customizedPostMixedMedias,
                              }}
                              showLinkComponent={false}
                              showDocumentComponent={false}
                              showImageComponent={false}
                              mediasPreviewDisplay={
                                (customizedPostComponent === POST_VIDEO ||
                                  customizedPostComponent === POST_MIXED_MEDIA) &&
                                updatedPostMedia
                                  ? 'block'
                                  : 'none'
                              }
                              mediasPreviewSizeSmall
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="media" />
                        </Flex>
                      </Fragment>
                    )}

                    <Flex mt="m" alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        <Input
                          placeholder="Video title *"
                          label=""
                          defaultValue={video_title || ''}
                          name="video_title"
                          id="video_title"
                          onChange={(e) => {
                            const { value } = e.target
                            if (value.length <= MAX_VIDEO_TITLE_LENGTH) {
                              handleUpdateCustomizationDebounce({ type: 'video_title', value })
                            }
                          }}
                          error={!video_title || video_title.length === 0 ? 'Video title is required' : ''}
                          width="100%"
                          maxLength={MAX_VIDEO_TITLE_LENGTH}
                          color={COLOR_CONSTANTS.DENIM}
                        />
                      </Flex>
                      <TooltipPromptComponent
                        prompt_key="video_title"
                        network={YOUTUBE}
                        mt={!video_title || video_title.length === 0 ? '-18px' : '0'}
                      />
                    </Flex>
                  </Flex>

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CommentsComponent
                            user={user}
                            handleUpdateCustomization={handleUpdateCustomization}
                            handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                            network={YOUTUBE}
                            profileId={profileId}
                            commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                            comments={comments}
                            commentTextMaxLimit={commentTextMaxLimit}
                            selectedEntities={selectedEntities}
                            selectedProfiles={selectedProfiles}
                            textVariantOffsets={textVariantOffsets}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments" />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <DropDown
                            placeholder="Select privacy status"
                            value={privacyStatus ? PRIVACY_STATUSES.find(({ value }) => value === privacyStatus) : null}
                            onChange={(option) => {
                              handleUpdateCustomization({
                                type: 'privacyStatus',
                                value: option.value,
                              })
                            }}
                            options={PRIVACY_STATUSES}
                            openMenuOnFocus
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="privacyStatus" network={YOUTUBE} />
                      </Flex>

                      {categories.length !== 0 && (
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <DropDown
                              placeholder="Select category"
                              value={
                                category
                                  ? transformToReactSelectData(categories.find(({ id }) => category === id))
                                  : null
                              }
                              onChange={(option) => {
                                const { value = null, label = null } = option || {}
                                handleUpdateCustomization({
                                  type: 'category',
                                  value,
                                })
                                handleUpdateCustomization({
                                  type: 'category_name',
                                  value: value ? label : '',
                                })
                              }}
                              options={[{ value: '', label: 'Without category' }, ...mapArrayToReactSelect(categories)]}
                              openMenuOnFocus
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="category_name" network={YOUTUBE} />
                        </Flex>
                      )}

                      {playlists.length !== 0 && (
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <DropDown
                              placeholder="Select playlist"
                              value={
                                playlist
                                  ? transformToReactSelectData(playlists.find(({ id }) => playlist === id))
                                  : null
                              }
                              onChange={(option) => {
                                const { value = null, label = null } = option || {}
                                handleUpdateCustomization({
                                  type: 'playlist',
                                  value,
                                })
                                handleUpdateCustomization({
                                  type: 'playlist_name',
                                  value: value ? label : '',
                                })
                              }}
                              options={[{ value: '', label: 'Without playlist' }, ...mapArrayToReactSelect(playlists)]}
                              openMenuOnFocus
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="playlist" network={YOUTUBE} />
                        </Flex>
                      )}

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <DropDown
                            innerRef={tagsRef}
                            isMulti
                            label=""
                            placeholder="Tag your video"
                            value={tags ? tags.map((item) => ({ value: item, label: item })) : []}
                            onChange={(options) => {
                              if (options) {
                                const optionsTemp = {}
                                options.forEach(({ value }) => {
                                  const values = value.split(',')
                                  values.forEach((item) => {
                                    const updatedItem = item.toLowerCase().trim()
                                    if (updatedItem) {
                                      optionsTemp[updatedItem] = updatedItem
                                    }
                                  })
                                })

                                handleUpdateCustomization({
                                  type: 'tags',
                                  value: Object.keys(optionsTemp),
                                })

                                const foundNewOption = options.find(({ __isNew__ }) => __isNew__)

                                if (foundNewOption) {
                                  setSavedSearches({
                                    type: SAVED_SEARCH_TYPE_YOUTUBE_TAGS,
                                    id: profileId,
                                    result: foundNewOption,
                                    dataForCustomizations,
                                    setDataForCustomizations,
                                  })
                                }
                              } else {
                                handleUpdateCustomization({
                                  type: 'tags',
                                  value: [],
                                })
                              }
                            }}
                            options={SAVED_TAGS_OPTIONS}
                            openMenuOnFocus
                            dropDownType="CreatableSelect"
                            noOptionsMessage={() => {
                              return 'Tag your video by adding new tags'
                            }}
                            onMenuClose={() => {
                              if (tagsRef && tagsRef.current && tagsRef.current.state.inputValue) {
                                handleUpdateCustomization({
                                  type: 'tags',
                                  value: [
                                    ...new Set([
                                      ...tagsRef.current.props.value.map(({ value }) => value),
                                      tagsRef.current.state.inputValue,
                                    ]),
                                  ],
                                })
                              }
                            }}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="tags" network={YOUTUBE} />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={like}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'like',
                                value: !like,
                              })
                            }}
                            text="Create first like"
                            color={COLOR_CONSTANTS.DENIM}
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="like" network={YOUTUBE} />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={!!embeddable}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'embeddable',
                                value: !embeddable,
                              })
                            }}
                            text="Embeddable"
                            color={COLOR_CONSTANTS.DENIM}
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="embeddable" network={YOUTUBE} />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={!!notifySubscribers}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'notifySubscribers',
                                value: !notifySubscribers,
                              })
                            }}
                            text="Notify subscribers"
                            color={COLOR_CONSTANTS.DENIM}
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="notifySubscribers" network={YOUTUBE} />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <Switch
                            isOn={!!madeForKids}
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'madeForKids',
                                value: !madeForKids,
                              })
                            }}
                            text="Made for kids"
                            color={COLOR_CONSTANTS.DENIM}
                            fontWeight="normal"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="madeForKids" network={YOUTUBE} />
                      </Flex>
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

YoutubePost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  setDataForCustomizations: () => {},
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  selectedProfiles: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

YoutubePost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
}

YoutubePost.displayName = 'YoutubePost'

export default YoutubePost
